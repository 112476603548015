import styled from 'styled-components/macro';

import ArrowButtonComponent from '../../../components/ArrowButtonComponent';
import { testIds } from '../../../utils/testing';
import { desktopMediaQuery } from '../styles';
import { Text } from './Typography';

export type HomeScreenBriefProps = React.HTMLAttributes<HTMLDivElement> & {
	buttonText: string;
	buttonAriaLabel: string;
	onNext: () => void;
};

export const HomeScreenBrief: React.FC<HomeScreenBriefProps> = ({
	buttonText,
	children,
	onNext,
	...props
}) => (
	<Brief {...props}>
		{children}

		<svg
			viewBox="0 0 108 17"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
		>
			<path
				d="M2 12.744L12.008 5l10.69 7.305L32.024 5l10.546 7.43L52.642 5 62 13.308 72 5l12.272 8.308L94.216 4l11.268 10"
				stroke="#CAD400"
				strokeWidth={5}
				fill="none"
				fillRule="evenodd"
				aria-hidden="true"
			/>
		</svg>

		<NextButton
			data-testid={testIds.homeScreenBriefNext}
			onClick={onNext}
			aria-label={props.buttonAriaLabel}
		>
			{buttonText}
		</NextButton>
	</Brief>
);

const NextButton = styled(ArrowButtonComponent)`
	margin-top: auto;
`;

const Brief = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 20.25rem;
	padding-top: ${({ theme }) => theme.spacing[20]};

	${Text} {
		margin-bottom: ${({ theme }) => theme.spacing[9]};
	}

	> svg {
		width: 108px;
		height: auto;
		margin-bottom: ${({ theme }) => theme.spacing[8]};
	}

	@media ${desktopMediaQuery} {
		padding-top: 0;
	}

	@media ${desktopMediaQuery} and (min-height: 642px) {
		position: relative;
		top: -3.5rem;
	}
`;
