import React from 'react';
import { squiggleLime } from '../../assets';
// components
import { Col } from '../Containers';
import { SimpleTileHeading } from '../Typography';
import _Tile from '../TileComponent';
import TileTitle from '../TileTitleComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faTasks } from '@fortawesome/pro-solid-svg-icons';
// hooks
import { useReduceMotion } from '../../hooks/usePreferReduceMotion';
import { useA11yFocus } from '../../hooks/useA11yFocus';
// utils
import { stripUnit } from 'polished';
// spring
import { useSpring, animated } from 'react-spring';
// types
import { DeviceTypeId } from '../../types';
// styles
import styled, { useTheme } from 'styled-components/macro';
import { LanguageContext } from '../../contexts/LanguageContext';
import { testIds } from '../../utils/testing';

type AnimationStatusID = 'enlarge' | 'diminish';

export type SimpleTileComponentProps = {
	title: string;
	icon: 'Phone' | 'Email' | 'Tasks';
	handleClick?: () => void;
	hasIndicator: boolean;
	device: DeviceTypeId;
	'data-testid'?: string;
};

/** Messy b/c of the styled component 'as' prop
 * which requires the intersection type
 * (is originally a div,but rendered as button)
 */
type TileElement = HTMLDivElement & HTMLButtonElement;

const SimpleTileComponent: React.FC<SimpleTileComponentProps> = ({
	title,
	icon,
	handleClick,
	hasIndicator,
	children,
	device,
	...props
}) => {
	const { language } = React.useContext(LanguageContext);
	const prefersReducedMotion = useReduceMotion();
	const theme = useTheme();
	const a11yfocus = useA11yFocus<TileElement>([hasIndicator]);
	const _icon = (() => {
		if (icon === 'Email') return faEnvelope;
		if (icon === 'Phone') return faPhone;
		if (icon === 'Tasks') return faTasks;
		// default
		return faPhone;
	})();

	const hasDecoration = icon === 'Tasks';

	// indicator animation

	const [active, setActive] = React.useState<AnimationStatusID>('enlarge');

	const sizes = {
		max: '1.1',
		min: '0.5',
		rest: '1',
	};

	const handleChange = (size: string) => {
		// if the animation reaches its max, reverse it
		// we do this to make the animation continuous
		const current = stripUnit(size);
		const max = stripUnit(sizes.max);
		// only run on first animation
		if (active !== 'enlarge') return;
		// trigger render which passes new config to spring
		if (current >= max) setActive('diminish');
	};

	const base = {
		reset: true,
		config: { mass: 0.5, tension: 80, friction: 6 },
		delay: 0,
	};

	const enlarge = {
		...base,
		// if prefers reduced motion animation wont play
		// so set the initial to animation rest size
		from: { scale: prefersReducedMotion ? sizes.rest : sizes.min },
		to: [{ scale: sizes.max }],
		onChange: handleChange,
	};

	const diminish = {
		...base,
		from: { scale: sizes.max },
		to: [{ scale: sizes.rest }],
	};

	const activeAnimation = (() => {
		if (active === 'enlarge') return enlarge;
		if (active === 'diminish') return diminish;
		// default
		return diminish;
	})();

	const animation = useSpring(activeAnimation);

	return (
		<Tile
			as="button"
			onClick={handleClick}
			hasCallToAction={hasIndicator}
			disabled={!handleClick}
			device={device}
			ref={a11yfocus}
			{...props}
		>
			{/* We have overflowing and non-overflowing content, 
      so we need a NoOverflow wrapper to handle 
      content we don't want to overflow */}
			<NoOverflow>
				<InnerContent>
					<TileTitle title={title} />
					{children}
				</InnerContent>
				<CircleGradient layout={device} aria-hidden={true} />
				<Icon
					layout={device}
					icon={_icon}
					size={device === 'desktop' ? '6x' : '5x'}
					color={theme.colors.background.light}
				/>
			</NoOverflow>
			{hasIndicator && (
				<IndicatorContainer
					style={animation}
					data-testid={testIds.notification}
					role="alert"
					tabIndex={-1}
					aria-label="You have a new notification"
				>
					<Decagram xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 102">
						<path
							d="M82.917 83.624L74.47 98.128l-16.54-2.853-15.358 6.77-11.704-12.03-16.404-3.551-2.398-16.612L.882 57.337l7.824-14.849L7.014 25.79l15.058-7.414 8.446-14.504 16.54 2.853 15.358-6.77 11.705 12.03 16.404 3.551 2.398 16.612 11.184 12.515-7.824 14.849 1.692 16.698z"
							fill="rgb(202, 212, 0)"
							fillRule="evenodd"
						/>
					</Decagram>
					<TitleIndicator as="p" id="title-indicator">
						1
						<span style={{ fontSize: language === 'cy' ? '65%' : 'inherit' }}>
							{language === 'en' ? 'new' : 'newydd'}
						</span>
					</TitleIndicator>
				</IndicatorContainer>
			)}
			{hasDecoration && (
				<DecorationContainer layout={device}>
					<Image src={squiggleLime} alt="" />
					<Image src={squiggleLime} alt="" />
				</DecorationContainer>
			)}
		</Tile>
	);
};

type TileProps = Pick<SimpleTileComponentProps, 'data-testid'> & {
	hasCallToAction: boolean;
	device: DeviceTypeId;
};
const Tile = styled(_Tile)<TileProps>`
	--shadow-x: 0;
	--shadow-y: ${({ device }) => (device === 'mobile' ? '2px' : '4px')};
	--shadow-blur: ${({ device }) => (device === 'mobile' ? '10px' : '15px')};
	position: relative;
	padding: 0;
	border: 0;
	background: ${({ theme }) => theme.colors.background.light};
	box-shadow: ${({ theme, hasCallToAction }) =>
		hasCallToAction ? theme.shadows.tile.action : theme.shadows.tile.noAction};

	&[disabled] {
		cursor: initial;
	}
`;

const NoOverflow = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;
	border-radius: inherit;
`;

const TitleIndicator = styled(SimpleTileHeading)`
	position: relative;
	color: ${({ theme }) => theme.colors.primary};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 1;
	z-index: 4;
	text-align: center;

	span {
		display: block;
		margin-top: ${({ theme }) => theme.spacing.xs};
		padding-bottom: ${({ theme }) => theme.spacing.xs};
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		font-size: 1.5rem;
	}
`;

const Icon = styled(FontAwesomeIcon)<{ layout: DeviceTypeId }>`
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: ${({ layout }) => {
		if (layout === 'desktop') return '-0.25rem';
		if (layout === 'mobile') return '0.25rem';
	}};
	margin-bottom: ${({ layout }) => {
		if (layout === 'desktop') return '-0.25rem';
		if (layout === 'mobile') return '0';
	}};
	// fontawesome icon size 5x too small,
	// 6x too big, so we scale it down just a touch
	transform: ${({ layout }) => {
		if (layout === 'mobile') return 'scale(0.7)';
		if (layout === 'desktop') return 'scale(0.6)';
	}};
`;

const CircleGradient = styled.div<{ layout: DeviceTypeId }>`
	background: ${({ theme }) => theme.colors.gradient.blueToGreen};
	position: absolute;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 50%;
	height: ${({ layout }) => {
		if (layout === 'desktop') return '13.75rem';
		if (layout === 'mobile') return '11rem';
	}};
	width: ${({ layout }) => {
		if (layout === 'desktop') return '13.75rem';
		if (layout === 'mobile') return '11rem';
	}};
	bottom: ${({ layout }) => {
		if (layout === 'desktop') return '-6rem';
		if (layout === 'mobile') return '-5rem';
	}};
	right: ${({ layout }) => {
		if (layout === 'desktop') return '-5rem';
		if (layout === 'mobile') return '-4rem';
	}};
`;

const InnerContent = styled(Col)`
	justify-content: space-between;
	padding: ${({ theme }) => theme.spacing[5]};
`;

const AnimatedCol = animated(Col);

const IndicatorContainer = styled(AnimatedCol)`
	z-index: 1;
	border-radius: 3px;
	background: transparent;
	width: 6rem;
	height: 6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: -1rem;
	left: -1rem;
`;

const Image = styled.img`
	width: 100%;
	height: auto;
`;

const Decagram = styled.svg`
	position: absolute;
	width: 100%;
	height: auto;
`;

const DecorationContainer = styled.div<{ layout: DeviceTypeId }>`
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 1.25rem;
	right: ${({ layout }) => {
		if (layout === 'desktop') return '3.125rem';
		if (layout === 'mobile') return '1.125rem';
	}};
	width: ${({ layout }) => {
		if (layout === 'desktop') return '4rem';
		if (layout === 'mobile') return '3.25rem';
	}};
`;

export default SimpleTileComponent;
