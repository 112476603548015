import {
	blues,
	oranges,
	purples,
	greys,
	yellows,
	greens,
	reds,
} from '../constants/Colors';

export type Theme = typeof theme;

export const theme = {
	colors: {
		primary: blues[400],
		primary_dark: blues[500],
		primary_light: '#93C9DF',
		primary_ultralight: blues[200],
		secondary: purples[500],
		tertiary: oranges[500],
		whiteish: '#FAFAFA',
		accents: {
			pink: '#F06C9B',
			yellow: '#FECC2C',
			yellow_dark: '#CAD400',
			lime: '#CAD40E',
			orange: '#F95738',
		},
		success: greens[400],
    success_dark: '#32965D',
		info: yellows[400],
		danger: reds[400],
		text: {
			light: greys[100],
			dark: blues[500],
			dark_alt: blues[400],
      placeholder: '#959DAE'
		},
		background: {
			light: greys[100],
			dark: greys[800],
			progress_bar: '#EEF3F6',
		},
		focus: blues[400],
		gradient: {
			orangeToPink: `background: rgb(254,204,44);
            background: -moz-linear-gradient(320deg, rgba(254,204,44,1) 0%, rgba(246,117,146,1) 100%);
            background: -webkit-linear-gradient(320deg, rgba(254,204,44,1) 0%, rgba(246,117,146,1) 100%);
            background: linear-gradient(320deg, rgba(254,204,44,1) 0%, rgba(246,117,146,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fecc2c",endColorstr="#f67592",GradientType=1)`,
			blueToGreen: `background: rgba(147, 201, 223);
            background: -moz-linear-gradient(180deg, rgba(147, 201, 223, 1) 0%, rgba(202, 212, 0, 1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(147, 201, 223, 1) 0%, rgba(202, 212, 0, 1) 100%);
            background: linear-gradient(180deg, rgba(147, 201, 223, 1) 0%, rgba(202, 212, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#93C9DF",endColorstr="#CAD400",GradientType=1)`,
			progress_bar: {
				health:
					'linear-gradient(270deg, rgba(254,204,44,1), rgba(249, 133, 49, 1.0) 50%, rgba(249, 87, 56, 1.0))',
				wellbeing:
					'linear-gradient(270deg, rgba(147, 201, 223, 1.0), rgba(0, 0, 0, 1.0)',
			},
		},
		rgb: {
			primary: {
				red: 36,
				green: 47,
				blue: 96,
			},
		},
	},
	radii: {
		xs: '3px',
		sm: '4px',
		md: '15px',
		lg: '20px',
		xl: '25px',
		round: '50%',
	},
	font: {
		primary: 'quasimoda, sans-serif',
	},
	fontWeight: {
		light: 300,
		semiLight: 400,
		regular: 400,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
	},
	fontSize: {
		base: '16px',
    xxxxl: '3rem',
		xxxl: '1.5rem',
		xxl: '1.3125rem',
		xl: '1.25rem',
		lg: '1.125rem',
		md: '1rem',
		sm: '0.875rem',
		xs: '0.75rem',
		xxs: '0.625rem',
	},
	shadows: {
		tile: {
			action: 'var(--shadow-x, 0) var(--shadow-y, 4px) var(--shadow-blur, 15px) rgba(202, 212, 0, 0.8)',
			noAction: 'var(--shadow-x, 0) var(--shadow-y, 4px) var(--shadow-blur, 15px) rgba(147, 201, 223, 1)',
		},
		modal:
			'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
		sliderThumb:
			'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
	},
	spacing: {
		px: '1px',
		'1': '4px',
		'2': '8px',
		'3': '12px',
		'4': '16px',
		'5': '20px',
		'6': '24px',
		'7': '28px',
		'8': '32px',
		'9': '36px',
		'10': '40px',
		'11': '44px',
		'12': '48px',
		'13': '52px',
		'14': '56px',
		'15': '60px',
		'16': '64px',
		'17': '68px',
		'18': '72px',
		'19': '76px',
		'20': '80px',
		'24': '96px',
		get xs() {
			return this[1];
		},
		get sm() {
			return this[2];
		},
		get md() {
			return this[3];
		},
		get lg() {
			return this[4];
		},
		get xl() {
			return this[5];
		},
		letter: {
			sm: '0.05rem',
			md: '0.1rem',
			lg: '0.15rem',
		},
	},
	zIndexes: {
		gameScreen: 999,
		tooltip: 999,
		modal: 999,
		sentimentLayout: 998,
		tileTitle: 5,
		socialTile: {
			copy: 4,
			sentiment: 3,
			wave: 2,
			rotatedIcon: 1,
		},
	},
	breakPoints: {
		xxs: '320px',
		xs: '375px',
		sm: '425px',
		md: '768px',
		lg: '1024px',
		xl: '1440px',
		xxl: '2560px',
	},
} as const;

export default theme;
