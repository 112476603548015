import { assign, createMachine } from 'xstate';

import { Gender } from '../../types';
import logError from '../../utils/logError/logError';

// types
export type SetupContext = {
	age?: number;
	gender?: Gender;
	country?: string;
	postcode?: string;
	subject?: string;
};

type DataCaptureEvent = { type: 'NEXT' };
type UpdateValueEvent = {
	type: 'UPDATE_VALUE';
	key: keyof SetupContext;
	value: any;
};

type SetupEvent =
	| { type: 'SKIP_DATA_CAPTURE' }
	| { type: 'START_DATA_CAPTURE' }
	| { type: 'BACK' }
	| { type: 'START' }
	| DataCaptureEvent
	| UpdateValueEvent;

export type SetupState = {
	value:
		| 'intro'
		| 'settingUp'
		| 'settingUp.intro'
		| 'settingUp.age'
		| 'settingUp.location'
		| 'settingUp.gender'
		| 'settingUp.subjects'
		| 'settingUp.uploading'
		| 'settingUp.finished'
		| 'briefing'
		| 'briefed';
	context: SetupContext;
};

// Actions

/**
 * Updates a value in context
 */
const updateValue = assign<SetupContext, SetupEvent>((ctx, e) => {
	const { key, value } = e as UpdateValueEvent;

	return {
		...ctx,
		[key]: key === 'postcode' ? value.toUpperCase() : value,
	};
});

// Guards
const isOverSixteen = (ctx: SetupContext) => (ctx.age ?? 0) >= 16;

// Services

/**
 * DEPRECATED:: Send the captured data to a Lambda
 * @param ctx SetupContext
 */
const uploadData = async (ctx: SetupContext) => {
	// Do nothing as this is handled by the Analytics Context now the event if fired in the SetupScreen, search for the comment "Fire Analytics Event :: userDetails"
	// try {
	// 	const apiUrl = process.env.REACT_APP_API_URL;
	// 	if (!apiUrl) {
	// 		throw new Error('No api url found');
	// 	}
	// 	await fetch(apiUrl, {
	// 		method: 'POST',
	// 		mode: 'no-cors',
	// 		body: JSON.stringify({
	// 			age: ctx.age,
	// 			gender: ctx.gender,
	// 			country: ctx.country,
	// 			postCode: ctx.postcode ? ctx.postcode : undefined,
	// 			subject: ctx.subject,
	// 		}),
	// 	});
	// } catch (e) {
	// 	logError(e, {
	// 		noNotify: true,
	// 	});
	// }
};

export const SetupMachine = createMachine<SetupContext, SetupEvent, SetupState>(
	{
		id: 'root',
		initial: 'intro',
		context: {
			age: 16,
		},
		on: {
			SKIP_DATA_CAPTURE: 'briefing',
			UPDATE_VALUE: {
				actions: ['updateValue'],
			},
		},
		states: {
			intro: {
				on: {
					START_DATA_CAPTURE: 'settingUp',
				},
			},
			settingUp: {
				initial: 'intro',
				onDone: 'briefing',
				states: {
					intro: {
						on: {
							NEXT: {
								target: 'age',
							},
						},
					},
					age: {
						on: {
							BACK: 'intro',
							NEXT: [
								{
									target: 'gender',
									cond: isOverSixteen,
								},
								{
									target: 'finished',
								},
							],
						},
					},
					gender: {
						on: {
							BACK: 'age',
							NEXT: 'location',
						},
					},
					location: {
						on: {
							BACK: 'gender',
							NEXT: 'subjects',
						},
					},
					subjects: {
						on: {
							BACK: 'location',
							NEXT: 'uploading',
						},
					},
					uploading: {
						invoke: {
							id: 'uploadData',
							src: 'uploadData',
							onDone: 'finished',
							onError: 'finished',
						},
					},
					finished: {
						type: 'final',
					},
				},
			},
			briefing: {
				on: {
					START: 'briefed',
				},
			},
			briefed: {
				type: 'final',
			},
		},
	},
	{
		actions: {
			updateValue,
		},
		guards: {
			isOverSixteen,
		},
		services: {
			uploadData,
		},
	}
);
