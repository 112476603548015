import React from 'react';
import styled from "styled-components";

import { Container } from '../../components/Containers';
import LogoComponent from '../../components/LogoComponent';
import { Paragraph } from '../../components/Typography';
import VolumeControlComponent from '../../components/VolumeControlComponent';
import { swanseaUniLogoWhite } from '../../assets';
import { links } from '../../config';
import { LanguageContext } from '../../contexts/LanguageContext';

export type GameHeaderLayoutProps = {
	className?: string;
	withoutColor?: boolean;
	withoutLogo?: boolean;
};

const GameHeaderLayout: React.FC<GameHeaderLayoutProps> = ({ className, withoutColor, withoutLogo }) => {
	const lang = React.useContext(LanguageContext);

	return (
		<Wrapper className={className} hasColor={!withoutColor}>
			<Inner>
				{ !withoutLogo && (
					<LogoWrapper>
						<Logo onDark />
						<a href={links.swanseaUni} target="_blank" rel="noopener noreferrer">
							<SwanseaUniLogo src={swanseaUniLogoWhite} alt={lang.poweredBy} />
						</a>
					</LogoWrapper>
				) }
			</Inner>

			<VolumeControls isOnLightBg={!!withoutColor} />
		</Wrapper>
	);
};

const SwanseaUniLogo = styled.img`
	display: block;
	height: 3.25rem;
	margin-left: 1.35rem;
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Logo = styled(LogoComponent)`
	height: 2.125rem;
`;

type WrapperProps = {
	hasColor: boolean;
}
const Wrapper = styled.header<WrapperProps>`
	display: flex;
	z-index: 3;
	background: ${({ theme, hasColor }) => hasColor && theme.colors.primary};
`;

const Inner = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const VolumeControls = styled(VolumeControlComponent)`
	margin-left: auto;
	margin-right: ${({ theme }) => theme.spacing[6]};
`;

export default GameHeaderLayout;
