import React from 'react';
import Markdown from 'react-markdown';
import {
	MarkdownH1,
	MarkdownH2,
	MarkdownH3,
	MarkdownH4,
	MarkdownP,
} from '../Typography';

export type ContentComponentProps = {
	copy: string;
};

const ContentComponent: React.FC<ContentComponentProps> = ({ copy }) => {
	const mapping = {
		h1: MarkdownH1,
		h2: MarkdownH2,
		h3: MarkdownH3,
		h4: MarkdownH4,
		p: MarkdownP,
		ul: MarkdownP,
	};

	return <Markdown components={mapping}>{copy}</Markdown>;
};

export default ContentComponent;
