import React from 'react';
import styled from 'styled-components';

import SquiggleTitleComponent from '../../components/SquiggleTitleComponent';
import { mobileMediaQuery } from '../../components/ModalComponent';
import { Paragraph } from '../../components/Typography';

export type CentralTextLayoutProps = {
	canTitleWrap?: boolean;
	className?: string;
	title?: string;
	padBottom?: 'sm' | 'md';
}

// A way to standardise the styles of the 'central text' block that appears throughout the app. Could be nicer!
const CentralTextLayout: React.FC<CentralTextLayoutProps> = ({
	canTitleWrap,
	children,
	className,
	title,
	padBottom,
}) => {
	return (
		<Wrapper padBottom={padBottom} className={className}>
			{ title && (
				<SquiggleTitleComponent canWrap={canTitleWrap}>{ title }</SquiggleTitleComponent>
			) }
			{children}
		</Wrapper>
	);
};

type WrapperProps = Pick<CentralTextLayoutProps, 'padBottom'>;
const Wrapper = styled.div<WrapperProps>`
	--padding-bottom: ${({ theme, padBottom }) => {
		if(padBottom === 'sm') return theme.spacing[8];
		if(padBottom === 'md') return theme.spacing[16];
		return 0;
	}};
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 35rem;
	margin: 0 auto;
	padding-bottom: var(--padding-bottom);
	text-align: center;

	>p {
		margin-bottom: ${({ theme }) => theme.spacing[4]};

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media ${mobileMediaQuery} {
		--padding-bottom: ${({ theme, padBottom }) => {
			if(padBottom === 'sm') return theme.spacing[6];
			if(padBottom === 'md') return theme.spacing[16];
			return 0;
		}};

		${Paragraph} {
			max-width: 374px;
			padding: 0 1rem;
		}
	}
`;

export default CentralTextLayout;