import * as React from 'react';
import { useKey } from 'react-use';

/** Detects whether user is tabbing through document as opposed
 * to navigating with mouse. If user tabs, set to true. If user
 * mouse clicks, set to false. Returns current boolean tabbing value.
 * Internally sets document.body.dataset.tabbing to String(tabbingValue).
 */

const useTabDetection = () => {
	const [tabbing, setTabbing] = React.useState(false);
	useKey('Tab', () => setTabbing(true));

	/* If user clicks no longer tabbing b/c using mouse */
	React.useEffect(() => {
    const setTabbingFalse = () => {
      // guard against unnecessary re-render
      if (tabbing) {
      setTabbing(false);
      }
    };
		if (!document) return;
		document.addEventListener('mousedown', setTabbingFalse);
		return () => {
			document.removeEventListener('mousedown', setTabbingFalse);
		};
	}, [tabbing]);

	React.useEffect(() => {
		document.body.dataset.tabbing = String(tabbing);
	}, [tabbing]);

	return tabbing;
};
export default useTabDetection;
