import { createMachine, assign, send } from 'xstate';
import { random, groupBy, shuffle, sortBy } from 'lodash';

import { ExpertAdvice, ExpertAdviceType } from '../../types';
import { isE2ETest } from '../../utils/testing';
import { GameContext, GameEvent, LoadQuestionsEvent, AnswerQuestionEvent, GameState } from './types';

const delays = {
	WAITING: !isE2ETest ? 1000 : 0,
	FEEDBACK_PAUSE: !isE2ETest ? 1200 : 0,
	SENTIMENT_PAUSE: !isE2ETest ? 1800 : 0,
}

// Context that is restored on each playthrough
const defaultContext = {
	emails: [],
	voicemails: [],
	videos: [],
	hasNewVoicemail: false,
	hasNewEmail: false,
	hasNewTask: false,
	hasNewVideo: false,
	hasNewQuestion: false,

	isEmailOpen: false,
	isAudioVideoOpen: false,
	isQuestionOpen: false,
	isTaskOpen: false,

	adviceIndex: 0,
	roundIndex: 0,
	isHealthLocked: true,
	isWellbeingLocked: true,
	isEconomyLocked: true,
	isPublicTrustLocked: true,
}

// Actions
/**
 * Adds the fetched questions to context from a service LoadQuestionsEvent and sorts by order
 */
const addQuestions = assign<GameContext, GameEvent>({
	questions: (ctx, e: any) => sortBy((e as LoadQuestionsEvent).data, 'order'),
});

/**
 * Gets one piece of advice of each type for each question. Shuffles so the order of type isn't always the same
 */
const randomiseAdvice = assign<GameContext, GameEvent>({
	questions: (ctx) => {
		const _getRandomAdvice = (advice: ExpertAdvice[]) => {
			return advice[random(0, advice.length - 1)];
		}

		return ctx.questions.map(item => {
			const adviceByType = groupBy(item.advice.items, 'type') as Record<ExpertAdviceType, ExpertAdvice[]>;
			const advice = Object.values(adviceByType).map((values) => _getRandomAdvice(values));
			// Randomise the order, unless it's an E2E test. In which case order by type (Audio, Text, Video)
			const sortedAdvice = isE2ETest ? sortBy(advice, 'type') : shuffle(advice);
			return {
				...item,
				sortedAdvice,
			}
		});
	}
});

/**
 * Increments the adviceIndex in context
 */
const increaseAdviceIndex = assign<GameContext, GameEvent>({
	adviceIndex: (ctx) => ctx.adviceIndex + 1,
});

/**
 * Increments the roundIndex in context
 */
const increaseRoundIndex = assign<GameContext, GameEvent>({
	roundIndex: (ctx) => {
		return ctx.roundIndex + 1;
	}
});

/**
 * Resets the advice index at the end of a round
 */
const resetAdviceIndex = assign<GameContext, GameEvent>({
	adviceIndex: 0
});

/**
 * Resets the round index at the end of a rgame
 */
const resetRoundIndex = assign<GameContext, GameEvent>({
	roundIndex: 0
});

/**
 * Updates the currentQuestion in context, using the roundIndex
 */
const setupNextQuestion = assign<GameContext, GameEvent>({
	currentQuestion: (ctx) => ctx.questions[ctx.roundIndex]
});

const restoreDefaults = assign<GameContext, GameEvent>(defaultContext);

/**
 * Updates the currentAdvice in context, using the adviceIndex
 */
const setupNextAdvice = assign<GameContext, GameEvent>(ctx => {
	type AdviceListKeys = 'voicemails' | 'videos' | 'emails';
	type AdviceNotificationKeys = 'hasNewVoicemail' | 'hasNewVideo' | 'hasNewEmail';

	const advice = ctx.currentQuestion?.sortedAdvice?.[ctx.adviceIndex];

	const [listKey, notificationKey] = (() => {
		if(advice?.type === ExpertAdviceType.Audio) return ['voicemails', 'hasNewVoicemail'] as [AdviceListKeys, AdviceNotificationKeys];
		if(advice?.type === ExpertAdviceType.Video) return ['videos', 'hasNewVideo'] as [AdviceListKeys, AdviceNotificationKeys];
		if(advice?.type === ExpertAdviceType.Text) return ['emails', 'hasNewEmail'] as [AdviceListKeys, AdviceNotificationKeys];
		return [];
	})();

	if(!listKey || !notificationKey) return ctx;

	return {
		...ctx,
		[notificationKey]: true,
		[listKey]: [
			...ctx[listKey],
			advice,
		],
	}
});

const unlockHealth = assign<GameContext, GameEvent>({
	isHealthLocked: false
});

const unlockWellbeing = assign<GameContext, GameEvent>({
	isWellbeingLocked: false
});

const unlockEconomy = assign<GameContext, GameEvent>({
	isEconomyLocked: false
});

const unlockPublicTrust = assign<GameContext, GameEvent>({
	isPublicTrustLocked: false
});

const hearAdvice = assign<GameContext, GameEvent>({
	hasNewEmail: false,
	hasNewVideo: false,
	hasNewVoicemail: false,
});

/**
 * Stores the selected answer in context.
 * We can then send this elsewhere when we want.
 * e.g. the first round, things are updated/unlocked sequentially
 */
const storeAnswer = assign<GameContext, GameEvent>({
	currentAnswer: (ctx, e) => (e as AnswerQuestionEvent).answer
});

// Guards
/**
 * Returns true if there's advice at the current adviceIndex
 * @param ctx GameContext
 * @returns Boolean
 */
const hasAdvice = (ctx: GameContext) => {
	const { roundIndex, adviceIndex, questions } = ctx;
	return !!questions?.[roundIndex]?.['sortedAdvice']?.[adviceIndex];
}

/**
 * Returns true is there's a question at the current roundIndex
 * @param ctx GameContext
 * @returns Boolean
 */
const hasMoreRounds = (ctx: GameContext) => {
	return !!ctx.questions?.[ctx.roundIndex]
};

const isFirstRound = (ctx: GameContext) => {
	return ctx.roundIndex === 0;
}

const isNotSecondRound = (ctx: GameContext) => {
	return ctx.roundIndex !== 1;
}

export const GameMachine = createMachine<GameContext, GameEvent, GameState>({
	id: 'root',
	initial: 'idle',
	context: {
		...defaultContext,
		questions: [],
	},
	states: {
		idle: {
			on: {
				START: 'loading',
			},
		},
		loading: {
			// Load the questions
			// Add them to context
			invoke: {
				id: 'loadQuestions',
				src: 'loadQuestions',
				onDone: {
					target: 'playing',
					actions: ['addQuestions']
				},
				onError: {
					target: 'idle'
				}
			}
		},
		playing: {
			initial: 'readyForScenario',
			entry: ['randomiseAdvice'],
			onDone: 'finished',
			on: {
				UNLOCK_HEALTH: {
					actions: ['unlockHealth']
				},
				UNLOCK_WELLBEING: {
					actions: ['unlockWellbeing']
				},
				UNLOCK_ECONOMY: {
					actions: ['unlockEconomy']
				},
				UNLOCK_PUBLIC_TRUST: {
					actions: ['unlockPublicTrust']
				},
				CLOSE_EMAIL: {
					actions: ['closeEmail']
				},
				OPEN_EMAIL: {
					actions: ['openEmail']
				},
				CLOSE_AV: {
					actions: ['closeAudioVideo']
				},
				OPEN_AV: {
					actions: ['openAudioVideo']
				},
				OPEN_QUESTION: {
					actions: ['openQuestion']
				},
				CLOSE_QUESTION: {
					actions: ['closeQuestion']
				},
				OPEN_TASK: {
					actions: ['openTask']
				},
				CLOSE_TASK: {
					actions: ['closeTask']
				},
			},
			states: {
				readyForScenario: {
					entry: ['setupNextQuestion'],
					after: {
						WAITING: 'receivedScenario'
					},
				},
				receivedScenario: {
					on: {
						HEARD_SCENARIO: 'readyForAdvice'
					}
				},
				readyForAdvice: {
					after: {
						WAITING: [
							{ target: 'receivedAdvice', cond: 'hasAdvice' },
							{ target: 'receivedQuestion', actions: ['resetAdviceIndex'] },
						]
					}
				},
				receivedAdvice: {
					entry: ['setupNextAdvice'],
					exit: ['increaseAdviceIndex'],
					on: {
						HEARD_ADVICE: {
							target: 'readyForAdvice',
							actions: 'hearAdvice'
						}
					}
				},
				receivedQuestion: {
					on: {
						ANSWER_QUESTION: {
							target: 'receivingFeedback',
							actions: ['closeQuestion', 'storeAnswer', send({ type: 'RECEIVE_FEEDBACK' }, { delay: delays.FEEDBACK_PAUSE})]
						},
					}
				},
				receivingFeedback: {
					initial: 'waiting',
					onDone: 'receivedFeedback',
					states: {
						waiting: {
							after: {
								FEEDBACK_PAUSE: 'updatingHealth'
							}
						},
						updatingHealth: {
							entry: ['unlockHealth'],
							invoke: {
								id: 'updateHealth',
								src: 'updateHealth',
								onDone: {
									actions: send({ type: 'UPDATED_CHART' }, { delay: (ctx) => ctx.roundIndex === 0 ? delays.FEEDBACK_PAUSE : 0 })
								}
							},
							on: {
								UPDATED_CHART: {
									target: 'updatingWellbeing',
								}
							}
						},
						updatingWellbeing: {
							entry: ['unlockWellbeing'],
							invoke: {
								id: 'updateWellbeing',
								src: 'updateWellbeing',
								onDone: {
									actions: send({ type: 'UPDATED_CHART' }, { delay: (ctx) => ctx.roundIndex === 0 ? delays.FEEDBACK_PAUSE : 0 })
								}
							},
							on: {
								UPDATED_CHART: {
									target: 'updatingEconomy',
								}
							}
						},
						updatingEconomy: {
							entry: ['unlockEconomy'],
							invoke: {
								id: 'updateEconomy',
								src: 'updateEconomy',
								onDone: {
									actions: send({ type: 'UPDATED_CHART' }, { delay: (ctx) => ctx.roundIndex === 0 ? delays.FEEDBACK_PAUSE : 0 })
								}
							},
							on: {
								UPDATED_CHART: {
									target: 'updatingPublicTrust',
								}
							}
						},
						updatingPublicTrust: {
							entry: ['unlockPublicTrust'],
							invoke: {
								id: 'updatePublicTrust',
								src: 'updatePublicTrust',
								onDone: {
									actions: send({ type: 'UPDATED_CHART' }, { delay: delays.FEEDBACK_PAUSE })
								}
							},
							on: {
								UPDATED_CHART: {
									target: 'updatingSentiment',
								}
							}
						},
						updatingSentiment: {
							invoke: {
								id: 'updateSentiment',
								src: 'updateSentiment',
								onDone: {
									actions: send({ type: 'UPDATED_CHART' }, { delay: delays.SENTIMENT_PAUSE })
								}
							},
							on: {
								UPDATED_CHART: {
									target: 'finished',
								}
							}
						},
						finished: {
							type: 'final'
						}
					}
				},
				receivedFeedback: {
					id: 'receivedFeedback',
					entry: ['increaseRoundIndex'],
					always: [
						{ target: 'readyForScenario', cond: 'hasMoreRounds' },
						{ target: 'finishedGame' }
					]
				},
				finishedGame: {
					type: 'final',
				}
			},
		},
		finished: {
			initial: 'readyForDebrief',
			exit: ['restoreDefaults'],
			on: {
				RESTART: 'loading'
			},
			states: {
				readyForDebrief: {
					after: {
						WAITING: 'receivedDebrief'
					}
				},
				receivedDebrief: {
					on: {
						HEAR_DEBRIEF: 'hearingDebrief'
					}
				},
				hearingDebrief: {},
			}
		},
	},
}, {
	actions: {
		addQuestions,
		randomiseAdvice,
		restoreDefaults,
		hearAdvice,
		increaseAdviceIndex,
		increaseRoundIndex,
		resetAdviceIndex,
		resetRoundIndex,
		setupNextAdvice,
		setupNextQuestion,
		storeAnswer,
		unlockEconomy,
		unlockHealth,
		unlockWellbeing,
		unlockPublicTrust,
	},
	delays,
	guards: {
		hasAdvice,
		hasMoreRounds,
		isFirstRound,
		isNotSecondRound,
	}
});