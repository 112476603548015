import React from 'react';
import styled from 'styled-components';

import ButtonComponent from '../../components/ButtonComponent';
import ModalComponent, { ModalComponentProps } from '../../components/ModalComponent';
import SquiggleTitleComponent from '../../components/SquiggleTitleComponent';
import { Paragraph } from '../../components/Typography';
import { LanguageContext } from '../../contexts/LanguageContext';
import { testIds } from '../../utils/testing';
import CentralTextLayout from '../CentralTextLayout';

export type DebriefLayoutProps = Pick<ModalComponentProps, 'isOpen' | 'onClose'>;

const DebriefLayout: React.FC<DebriefLayoutProps> = ({
	isOpen,
	onClose
}) => {
	const lang = React.useContext(LanguageContext);

	return (
		<ModalComponent
			hasWaveBackground
			contentLabel={lang.howTimeFlies}
			isOpen={isOpen}
		>
			<CentralTextLayout padBottom="md">
				<SquiggleTitleComponent data-testid={testIds.debriefModalTitle}>{lang.howTimeFlies}</SquiggleTitleComponent>
				<Paragraph>{lang.finishedGameParagraphOne}</Paragraph>
				<Paragraph>{lang.finishedGameParagraphTwo}</Paragraph>
				<NextButton data-testid={testIds.debriefModalClose} copy={lang.letsSee} handlePress={onClose} />
			</CentralTextLayout>
		</ModalComponent>
	);
};

const NextButton = styled(ButtonComponent)`
	min-width: 12.5rem;
	margin-top: ${({ theme }) => theme.spacing[5]};
`;

export default DebriefLayout;