import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

// Layouts
import SceneLayout from '../../layouts/SceneLayout';
import GameHeaderLayout from '../../layouts/GameHeaderLayout';
// Components
import { Container } from '../../components/Containers';
import { CapsTitle } from '../../components/Typography';
import { HomeScreenLangSelect } from './components/HomeScreenLangSelect';
import { HomeScreenBrief, Text } from './components';
import SocialMediaLinksComponent from '../../components/SocialMediaLinksComponent';
// Context
import { LanguageContext } from '../../contexts/LanguageContext';
// Styles
import { edgeOfScreen } from '../../styles/common';
import { mediaQueries } from '../../styles/mediaQueries';
import { desktopMediaQuery } from './styles';
// Utils
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import useFadeOut from '../../hooks/useFadeOut';
import { testIds } from '../../utils/testing';

type HomeScreenProps = {
	children?: React.ReactNode;
};

const HomeScreen = (props: HomeScreenProps) => {
	useSetDocumentTitle('Home - Trust the Experts Game');
	const history = useHistory();
	const [page, setPage] = React.useState(0);
	const lang = React.useContext(LanguageContext);
	const { fadeOut, styles } = useFadeOut(() => history.push('/setup'));
	const a11yfocus = React.useRef<HTMLParagraphElement>(null);

	/**
	 * Either go to next or fade out the scene and navigate to /setup
	 */
	const handleNext = () => {
		if (page < 2) {
			setPage(page + 1);
		} else {
			fadeOut();
		}
	};

	/* Focus on main element, so screen readers don't get lost when content changes. */
	React.useEffect(() => {
		if (a11yfocus && a11yfocus.current) {
			a11yfocus.current.focus();
		}
	}, [page]);

	return (
		<Wrapper style={styles}>
			<Header withoutColor withoutLogo />
			<Inner>
				<Main>
					{page === 0 ? (
						<HomeScreenLangSelect onNext={handleNext} />
					) : (
						<HomeScreenBrief
							data-testid={testIds.homeScreenBrief}
							buttonAriaLabel={page === 1 ? lang.startGame : lang.goToMyOffice}
							buttonText={
								page === 1 ? lang.letsStopThisVirus : lang.goToMyOffice
							}
							onNext={handleNext}
						>
							{page === 1 && (
								<>
									<Text data-testid={testIds.homeScreenPageOneText} tabIndex={-1} ref={a11yfocus}>
										{lang.homeBriefPageOne}
									</Text>
									{lang.homeBriefPageOneStrong && (
										<Text isBold>{lang.homeBriefPageOneStrong}</Text>
									)}
								</>
							)}
							{page === 2 && (
								<>
									<Text data-testid={testIds.homeScreenPageTwoText} tabIndex={-1} ref={a11yfocus}>
										{lang.homeBriefPageTwo}
									</Text>
									{lang.homeBriefPageTwoStrong && (
										<Text isBold>{lang.homeBriefPageTwoStrong}</Text>
									)}
								</>
							)}
						</HomeScreenBrief>
					)}
				</Main>

				<PoweredBy>
					<CapsTitle as="p">{lang.poweredBy}</CapsTitle>
				</PoweredBy>

				<Footer>
					<SocialLinks size="sm" />
				</Footer>
			</Inner>
		</Wrapper>
	);
};

const PoweredBy = styled.div`
	color: ${({ theme }) => theme.colors.text.dark_alt};

	@media ${mediaQueries.isMobileScene} {
		${CapsTitle} {
			text-align: center;
			font-size: ${({ theme }) => theme.fontSize.sm};
		}
	}

	@media ${desktopMediaQuery} {
		${edgeOfScreen('start', true)}
		top: 50%;
		transform: translateY(-50%);
		white-space: nowrap;

		${CapsTitle} {
			transform-origin: 0 50%;
			transform: rotate(-90deg) translateX(-50%);
		}
	}
`;

const Main = styled.main`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 6.5rem;

	@media ${desktopMediaQuery} {
		padding-bottom: 0;
	}
`;

const SocialLinks = styled(SocialMediaLinksComponent)`
	margin-top: ${({ theme }) => theme.spacing[1]};

	@media ${desktopMediaQuery} {
		flex-direction: column;

		> * + * {
			margin-top: ${({ theme }) => theme.spacing[1]};
		}
	}
`;

const Footer = styled.footer`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: ${({ theme }) => theme.spacing[2]};
	padding-bottom: ${({ theme }) => theme.spacing[7]};

	@media ${desktopMediaQuery} {
		flex-direction: column;
		align-items: flex-end;
		${edgeOfScreen('end', true)}
		top: 50%;
		transform: translateY(-50%);
		width: auto;
	}
`;

const Inner = styled(Container)`
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	/* min-height: 820px; */
	min-height: 660px;

	@media ${desktopMediaQuery} {
		min-height: 0;
		flex-direction: row;
	}
`;

const Header = styled(GameHeaderLayout)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
`;

const Wrapper = styled(SceneLayout)`
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;

	@media (max-height: 427px) {
		min-height: 460px;
	}
`;

export default HomeScreen;
