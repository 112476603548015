import React from 'react';
import { ChangeHandler } from '../../types/utils';
import { InputBackground } from '../Containers';
import styled from 'styled-components';

export type InputComponentProps = {
	onChange: (val: string) => void;
	placeholder: string;
	validator?: (val: string) => boolean;
	parser?: (val: string) => string;
	value: string;
};

const InputComponent: React.FC<InputComponentProps> = ({
	placeholder,
	onChange,
	validator,
	parser,
	...props
}) => {
	const [value, setValue] = React.useState(props.value || '');
	const [hasError, setHasError] = React.useState(false);

	const handleChange: ChangeHandler = ({ target: { value } }) => {
		setValue(value);
		if (validator) {
			const hasError = !validator(value);
			setHasError(hasError);
			if (hasError) return;
		}
		if (parser) {
			const parsed = parser(value);
			onChange(parsed);
		} else {
			onChange(value);
		}
	};

	return (
		<Input
			{...props}
			placeholder={placeholder}
			onChange={handleChange}
			value={value}
			hasError={hasError}
		/>
	);
};

const Input = styled.input<{ hasError: boolean }>`
	${InputBackground}
	min-width: 14rem;
	text-indent: 0.75rem;
	padding-right: 0.5rem;
	border-radius: ${({ theme }) => theme.radii.sm};
	border: ${({ hasError, theme }) =>
		hasError ? `2px solid ${theme.colors.danger}` : 'none'};
`;

export default InputComponent;
