import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
	DialogOverlay,
	DialogContent,
	DialogContentProps,
} from '@reach/dialog';
import { animated, useTransition } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { rgba } from 'polished';
import '@reach/dialog/styles.css';

import { modalWave } from '../../assets';
import { Paragraph, ScenarioCarouselHeading } from '../Typography';
import { LanguageContext } from '../../contexts/LanguageContext';
import { testIds } from '../../utils/testing';

export type ModalComponentProps = {
	contentLabel: string;
	hasWaveBackground?: boolean;
	isColor?: boolean;
	isOpen: boolean;
	isTitleUpperCase?: boolean;
	onClose?: () => void;
	title?: string;
	titleIcon?: IconDefinition;
	titleIconColor?: string;
	header?: React.ReactNode;
	footer?: React.ReactNode;
	verticalText?: string;
};

const Overlay = animated(DialogOverlay);
const Content = animated(DialogContent);

const ModalComponent: React.FC<ModalComponentProps> = ({
	children,
	contentLabel,
	hasWaveBackground,
	isColor,
	isOpen,
	isTitleUpperCase = true,
	onClose,
	title,
	titleIcon,
	titleIconColor,
	header,
	footer,
	verticalText,
	...props
}) => {
	const lang = React.useContext(LanguageContext);
	const modalRef = React.useRef<HTMLDivElement>(null);
	const theme = useTheme();

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	const transitions = useTransition(isOpen, {
		from: { opacity: 0, y: 10 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, y: 10 },
	});

	const $modal = modalRef.current;

	React.useEffect(() => {
		if(!$modal) return;
		$modal.scrollTop = 0;
	}, [$modal]);

	return (
		<>
			{transitions(
				(styles, item) =>
					item && (
						<StyledOverlay
							onDismiss={handleClose}
							style={{
								opacity: styles.opacity,
							}}
							ref={modalRef}
							{...props}
						>
							{header}
							<ModalContent
								$hasWaveBackground={!!hasWaveBackground}
								style={{
									transform: styles.y.to((val) => `translateY(${val}px)`),
								}}
								aria-label={contentLabel}
							>
								{title && (
									<ModalHeader>
										{!!titleIcon && (
											<FontAwesomeIcon
												icon={titleIcon}
												color={titleIconColor}
											/>
										)}
										<Title data-testid={testIds.modalTitle} isTitleUpperCase={!!isTitleUpperCase}>{title}</Title>
									</ModalHeader>
								)}

								<ModalChildren 
									isColor={isColor}
								>
									{verticalText && <VerticalText>{verticalText}</VerticalText>}
									{children}
								</ModalChildren>

								{!!onClose && (
									<CloseButton 
										onClick={onClose} 
										aria-label={lang.closeModal}
										data-testid={'modal-close'}
									>
										<FontAwesomeIcon
											icon={faTimes}
											color={theme.colors.primary}
											size="1x"
										/>
									</CloseButton>
								)}
							</ModalContent>
							{footer}
						</StyledOverlay>
					)
			)}
		</>
	);
};

export const mobileMediaQuery = '(max-width: 38rem)';

type TitleProps = Pick<ModalComponentProps, 'isTitleUpperCase'>;
const Title = styled(Paragraph)<TitleProps>`
	margin-left: ${({ theme }) => theme.spacing[4]};
	font-size: ${({ theme }) => theme.fontSize.md};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 1;

	${({ theme, isTitleUpperCase }) => isTitleUpperCase && `
		text-transform: uppercase;
		letter-spacing: 0.1rem;
	`}
`;

export const ModalChildren = styled.div<Pick<ModalComponentProps, 'isColor'>>`
	--modal-top-padding: ${({ theme }) => theme.spacing[10]};
	--modal-bottom-padding: ${({ theme }) => theme.spacing[6]};
	--modal-horiz-padding: ${({ theme }) => theme.spacing[6]};
	padding: var(--modal-top-padding) var(--modal-horiz-padding) var(--modal-bottom-padding);
	background: ${({ theme, isColor }) => (isColor ? '#DDE6EE' : 'transparent')};

	@media ${mobileMediaQuery} {
		--modal-horiz-padding: ${({ theme }) => theme.spacing[2]};
	}
`;

const VerticalText = styled(ScenarioCarouselHeading)`
	transform: rotate(270deg);
	position: absolute;
	left: 0;
	top: 3rem;
`;

const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	min-height: 3.375rem;
	padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[5]}`};
	background: ${({ theme }) => theme.colors.whiteish};
`;

const CloseButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	display: grid;
	place-content: center;
	border: 0;
	background: none;
	cursor: pointer;

	svg {
		width: 0.625rem;
	}
`;

const StyledOverlay = styled(Overlay)`
	--modal-margin-vert: 8vh;

	&[data-reach-dialog-overlay] {
		z-index: ${({ theme }) => theme.zIndexes.modal};
		background: ${({ theme }) => rgba(theme.colors.primary_dark, 0.95)};
	}
`;

type ModalContentProps = DialogContentProps & {
	// https://styled-components.com/docs/api#transient-props
	$hasWaveBackground: boolean;
};

export const ModalContent = styled(Content)<ModalContentProps>`
	--wave-nudge: -3.75rem;

	&[data-reach-dialog-content] {
		width: 48.75rem;
		max-width: 95%;
		padding: 0;
		border-radius: ${({ theme }) => theme.radii.md};
		box-shadow: none;
		overflow: hidden;
		background-color: ${({ theme }) => theme.colors.background.light};
		background-image: ${({ $hasWaveBackground }) =>
			$hasWaveBackground ? `url(${modalWave})` : 'none'};
		background-repeat: no-repeat;
		background-position: center bottom var(--wave-nudge, 0);
		background-size: 100%;
		// reach modal uses 10vh,
		// bump down just a touch for ResultScreen
		margin: var(--modal-margin-vert) auto;

		@media (max-width: 36rem) {
			--wave-nudge: 0;
			background-size: 200%;
		}

		@media (max-width: 459px) {
			--modal-margin-vert: 20px;
		}
	}
`;

export const ModalButtons = styled.div`
	display: flex;
	justify-content: center;
	max-width: 42.5rem;
	margin: var(--modal-bottom-padding) auto 0;

	> * {
		flex: initial;
		margin: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};

		&:first-of-type:last-of-type {
			min-width: 12rem;
		}
	}

	@media ${mobileMediaQuery} {
		flex-wrap: wrap;

		> * {
			flex: initial;

			&:first-of-type:last-of-type {
				min-width: 0;
			}
		}
	}
`;

export default ModalComponent;
