import styled from "styled-components";

import { mediaQueries } from "../../styles/mediaQueries";

const SelectInputLayout = styled.div`
	--col: 244px;
	display: grid;
	grid-template-columns: var(--col) var(--col);
	grid-gap: ${({ theme }) => theme.spacing[3]};

	@media ${mediaQueries.isStackedMobileSetup} {
		grid-template-columns: var(--col);
	}
`;

export default SelectInputLayout;
