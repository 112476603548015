import React from 'react';
import styled from "styled-components";
import { hideVisually, rgba } from 'polished';

import { SentimentId } from '../../types';
import { LanguageContext } from '../../contexts/LanguageContext';
import { getSentimentIcon } from '../../utils/getSentimentIcon';

const faceCount = 15;

export type SentimentLayoutProps = {
	sentiment: SentimentId;
}

const SentimentLayout: React.FC<SentimentLayoutProps> = ({ sentiment }) => {
	const lang = React.useContext(LanguageContext);
	const icon = getSentimentIcon(sentiment);

	return (
		<Wrapper role="alert">
			<p>
				{`${lang.newSentimentText} ${sentiment}`}
			</p>

			<Desktop xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1598 952">
				<image transform="rotate(-31 387.5 255)" x="255" y="122.5" width="265" height="265" xlinkHref={icon} />
				<image transform="rotate(-31 1415.5 770)" x="1283" y="637.5" width="265" height="265" xlinkHref={icon} />
				<image transform="rotate(23 764.5 337)" x="708" y="280.5" width="113" height="113" xlinkHref={icon} />
				<image x="962" y="99.5" width="185" height="185" xlinkHref={icon} />
				<image x="622" y="586.5" width="185" height="185" xlinkHref={icon} />
				<image transform="rotate(-15 1133.5 499)" x="1078" y="443.5" width="111" height="111" xlinkHref={icon} />
				<image transform="rotate(13 440.5 588)" x="365" y="512.5" width="151" height="151" xlinkHref={icon} />
				<image transform="rotate(13 1011.5 614)" x="987.5" y="590" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 916.5 448)" x="892.5" y="424" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 628.5 132)" x="604.5" y="108" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 140.5 108)" x="116.5" y="84" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 1275.5 232)" x="1251.5" y="208" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 1344.5 412)" x="1320.5" y="388" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 134.5 471)" x="110.5" y="447" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 100.5 803)" x="76.5" y="779" width="48" height="48" xlinkHref={icon} />
			</Desktop>

			<Mobile xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 832">
				<image transform="rotate(-31 399.825 205)" x="267.325" y="122.5" width="205" height="205" xlinkHref={icon} />
				<image transform="rotate(-31 87.821 255.436)" x="23.821" y="191.436" width="128" height="128" xlinkHref={icon} />
				<image transform="rotate(13 452.825 588)" x="377.325" y="512.5" width="151" height="151" xlinkHref={icon} />
				<image transform="rotate(13 296.558 713.78)" x="250.058" y="667.28" width="93" height="93" xlinkHref={icon} />
				<image transform="rotate(13 152.825 108)" x="128.825" y="84" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 146.825 471)" x="122.825" y="447" width="48" height="48" xlinkHref={icon} />
				<image transform="rotate(13 112.825 803)" x="88.825" y="779" width="48" height="48" xlinkHref={icon} />
			</Mobile>
		</Wrapper>
	);
};

const SVG = styled.svg`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 110%;
	height: 110%;

	image {
		opacity: 0;
		animation-name: fadeIn;
		animation-fill-mode: forwards;
		animation-duration: .3s;

		${Array(faceCount).fill(null).map((styles, i) => {
			const baseDelay = 100;
			const nth = i + 1;

			// Example of what this produces &:nth-of-type(15n+1)
			// i.e. this applies to the 1st, 16th, 31st etc
			return `
				&:nth-of-type(${faceCount}n+${nth}) {
					animation-delay: ${nth * baseDelay}ms;
				}
			`;
		})}

		@media (prefers-reduced-motion) {
			animation: none;
			opacity: 1;
		}
	}
`;

const Desktop = styled(SVG)``;

const Mobile = styled(SVG)`
	display: none;
`;

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => rgba(theme.colors.primary_dark, 0.95)};
	opacity: 0;
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	z-index: ${({ theme }) => theme.zIndexes.sentimentLayout};

	p {
		${hideVisually};
	}

	@media (max-aspect-ratio: 4/5) {
		${Desktop} {
			display: none;
		}

		${Mobile} {
			display: block;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export default SentimentLayout;