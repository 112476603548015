import React from 'react';
import styled from "styled-components";

type ProgressProps = {
	value: number;
	colors?: {
		bar: string;
		background: string;
	}
}

export const ProgressBar: React.FC<ProgressProps> = ({ colors, value }) => {
	return(
		<ProgressWrapper 
			style={{ 
				'--value': `${100 - (value > 100 ? 100 : value)}%`,
				'--bar-color': colors?.bar,
				'--background-color': colors?.background,
			} as React.CSSProperties}
		/>
	)
}

const ProgressWrapper = styled.div`
	position: relative;
	height: 10px;
	border-radius: 5px;
	background: var(--background-color, ${({ theme }) => theme.colors.background.progress_bar});
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		transform: translateX(calc(var(--value) * -1));
		transform-origin: 0 50%;
		transition: transform .8s ease-in-out;
		transition-delay: 0.4s;
		background: var(--bar-color, ${({ theme }) => theme.colors.primary});
	}
`;