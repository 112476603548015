import { LanguageContextType } from '../../contexts/LanguageContext';
import { Results, Valences, SentimentId } from '../../types';

/** Generates a results description based on results + language for social media share .*/

export const generateResultsQuote = (
	results: Results,
	valences: Valences,
	lang: LanguageContextType
) => {
	if(lang.language === 'cy') {
		return [
			`Dwi newydd chwarae Trust the Experts! Yn ystod fy nghyfnod fel Prif Weinidog${valences.economy === 'positive' ? ' tyfodd yr economi' : ', fe wnaeth yr economi grebachu'} ${results.economy}%,`,
			`${valences.healthcare === 'positive' ? `gostyngodd capasiti gwelyau ysbyty ${results.healthcare}%` : `tyfodd capasiti gwelyau ysbyty ${results.healthcare}%`},`,
			`${valences.publicOpinion === 'positive' ? `cynyddodd barn y cyhoedd ${results.publicOpinion}%` : `gostyngodd barn y cyhoedd ${results.publicOpinion}%`},`,
			`${valences.wellbeing === 'positive' ? 'cynyddodd' : 'gostyngodd'} lles cenedlaethol ${results.wellbeing}%.`,
			`Beth am roi cynnig arni eich hun?`
		].join(' ');
	}

	return [
		`I just played Trust the Experts! During my time as Prime Minister the economy ${valences.economy === 'positive' ? 'grew' : 'shrank'} by ${results.economy}%,`,
		`hospital bed capacity ${valences.healthcare === 'positive' ? 'shrank' : 'grew'} by ${results.healthcare}%,`,
		`public opinion ${valences.publicOpinion === 'positive' ? 'increased' : 'decreased'} by ${results.publicOpinion}%,`,
		`national wellbeing ${valences.wellbeing === 'positive' ? 'increased' : 'decreased'} by ${results.wellbeing}%.`,
		`Why not try yourself?`,
	].join(' ');
}

/**
 * Passed an array of sentiments, returns the chance of reelection
 *  based on those sentiments. Each sentiment is assigned a value between
 * 0 and 100, indicating its effect on the overall likelihood to reelect.
 * These are summed, averaged, and rounded to obtain the chance
 * of reelection.
 */

// TODO: If the sentiments change these need to be updated

export const calculateChanceOfReelection = (items: SentimentId[]) => {
	let sum = 0;
	items.forEach((x) => {
		if (x === 'happy') {
			sum = sum + 100;
		}
		if (x === 'neutral') {
			sum = sum + 50;
		}
		if (x === 'unsure') {
			sum = sum + 40;
		}
		if (x === 'unhappy') {
			sum = sum + 20;
		}
		if (x === 'sad') {
			sum = sum + 10;
		}
		if (x === 'angry') {
			sum = sum + 0;
		}
	});

	const average = sum / items.length;
	const rounded = Math.round(average);
	// cap between 15 and 85
	const bounded = rounded > 85 ? 85 : rounded < 15 ? 15 : rounded;

	return bounded;
};
