import React from 'react';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';

import { 
	deskJpg,
	deskWebp,
	deskAvif,
	windowNoCloudsJpg,
	windowNoCloudsWebp,
	windowNoCloudsAvif,
	postersJpg,
	postersWebp,
	postersAvif,
} from '../../assets';
import { mediaQueries } from '../../styles/mediaQueries';
import { SetupText } from '../../components/Typography';
import GameHeaderLayout from '../GameHeaderLayout';
import _Clouds from '../../components/CloudsComponent';
import { fadeIn } from '../../styles/common';

export type DeskLayoutProps = {
	className?: string;
	style?: {};
}

const DeskLayout: React.FC<DeskLayoutProps> = ({ children, className, style }) => {
	const isDesktop = useMediaQuery({ query: mediaQueries.isDesktopScene });

	return (
		<>
			<Header withoutColor withoutLogo />
			<Wrapper className={className} style={style}>
				{isDesktop ? (
					<Desktop>
						<Desk />
						<Window />
						<Posters/>
					</Desktop>
				) : (
					<Mobile>
						<Desk />
						<Window />
						<Posters />
					</Mobile>
				)}

				<ContentWrapper>
					<Content>
						{children}
					</Content>
				</ContentWrapper>
			</Wrapper>
		</>
	);
};

const _Desk: React.FC = (props) => (
	<Picture {...props}>
		<source srcSet={deskAvif} type="image/avif" />
		<source srcSet={deskWebp} type="image/webp" />
		<img 
			srcSet={deskJpg}
			src={deskJpg} 
			alt="" 
		/>
	</Picture>
);

const _Window: React.FC = (props) => (
	<div {...props}>
		<Picture>
			<source srcSet={windowNoCloudsAvif} type="image/avif" />
			<source srcSet={windowNoCloudsWebp} type="image/webp" />
			<img 
				srcSet={windowNoCloudsJpg}
				src={windowNoCloudsJpg} 
				alt="" 
			/>
		</Picture>

		<CloudsWrapper>
			<Clouds isWhite />
		</CloudsWrapper>
	</div>
);

const _Posters: React.FC = (props) => (
	<Picture {...props}>
		<source srcSet={postersAvif} type="image/avif" />
		<source srcSet={postersWebp} type="image/webp" />
		<img 
			srcSet={postersJpg}
			src={postersJpg} 
			alt="" 
		/>
	</Picture>
);

const CloudsWrapper = styled.div`
	position: absolute;
	top: 23%;
	width: 100%;
	height: 0;
	padding-bottom: 42%;
	clip-path: inset(0 3.5% 0 3.5%);
	z-index: 1;
`;

const Clouds = styled(_Clouds)`
	--clouds-width: calc(var(--window-width) * 2);
	--clouds-height: 100%;
	position: absolute;
`;

const Picture = styled.picture`
	img {
		position: absolute;
	}
`;

const Desk = styled(_Desk)``;
const Window = styled(_Window)`
	position: absolute;
	overflow: hidden;

	img {
		z-index: 1;
		width: 100%;
	}
`;
const Posters = styled(_Posters)``;

const Header = styled(GameHeaderLayout)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

const Content = styled.div`
	max-width: 665px;

	${SetupText} {
		a {
			text-decoration: none;
			color: ${({ theme }) => theme.colors.accents.orange};
		}
	}
`;

const ContentWrapper = styled.main`
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 3rem 6rem 0;
	z-index: 2;

	@media (max-width: 767px) {
		padding: 3rem 20px 0;
	}

	@media ${mediaQueries.isMobileScene} {
		padding: 2rem 20px 0;
	}
`;

const Desktop = styled.div`
	${Desk} {
		img {
			bottom: 0;
			left: 0;
			height: 500px;
			z-index: 1;

			@media (max-height: 874px) {
				bottom: initial;
				top: 400px;
			}
		}
	}
	
	${Window} {
		--window-width: 352px;
		width: var(--window-width);
		height: calc(var(--window-width) * 1.39);
		top: 32px;
		right: 32px;

		@media (max-width: 1180px) {
			right: initial;
			left: max(68%, 800px);
			--window-width: 313px;
		}
	}

	${Posters} {
		img {
			width: 260px;
			top: 60px;
			right: calc(2rem + 325px + 70px);

			@media (max-width: 1450px) {
				display: none;
			}
		}
	}
`;

const Mobile = styled.div`
	${Desk} {
		img {
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			min-width: 400px;
		}
	}

	${Window} {
		--window-height: max(40vw, 162px);
		--window-width: calc(var(--window-height) * 0.78);
		height: var(--window-height);
		width: var(--window-width);
		bottom: max(42vw, 170px);
		right: 20px;

		@media (min-width: 647px) {
			display: none;
		}

		@media (max-width: 360px) {
			right: initial;
			left: 300px;
		}
	}

	${Posters} {
		img {
			bottom: max(49vw, 200px);
			right: 40vw;
			height: max(30vw, 114px);
		}

		@media (min-width: 647px) {
			display: none;
		}

		@media (max-width: 360px) {
			img {
				right: initial;
				left: 190px;
			}
		}
	}

	${Window},
	${Posters} {
		@media (min-aspect-ratio: 11 / 16) {
			display: none;
		}
	}
`;

const Wrapper = styled.div`
	position: relative;
	min-height: 720px;
	height: 100vh;
	overflow: hidden;
	${fadeIn}
`;

export default DeskLayout;