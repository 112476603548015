import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { CapsTitle } from '../Typography';

export type ArrowButtonComponentProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {}

const ArrowButtonComponent: React.FC<ArrowButtonComponentProps> = ({
	children,
	className,
	onClick,
	...props
}) => {
	return (
		<Button 
			onClick={onClick} 
			className={className}
			{...props}
		>
			<CapsTitle as="p">{children}</CapsTitle>
			<Icon icon={faArrowRight} />
		</Button>
	);
};


const Icon = styled(FontAwesomeIcon)`
	margin-left: ${({ theme }) => theme.spacing[2]};
	color: ${({ theme }) => theme.colors.accents.yellow_dark};
`;

const Button = styled.button`
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	background: none;
	color: ${({ theme }) => theme.colors.text.dark_alt};

	&[disabled] {
		opacity: 0.5;
		cursor: default;
	}
`;

export default ArrowButtonComponent;