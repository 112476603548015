import React from 'react';
import styled from "styled-components";

import { CapsTitle } from '../Typography';

export type SquiggleTitleComponentProps = {
	canWrap?: boolean;
	className?: string;
	withLongerSquiggle?: boolean;
	size?: 'md' | 'lg';
}

const SquiggleTitleComponent: React.FC<SquiggleTitleComponentProps> = ({ 
	canWrap,
	className, 
	children, 
	size, 
	withLongerSquiggle, 
	...props
}) => {
	return (
		<TitleWrapper 
			className={className}
			canWrap={canWrap}
			withLongerSquiggle={withLongerSquiggle}
			{...props}
		>
			<Title size={size}>{ children }</Title>
			{withLongerSquiggle ? (
				<Squiggle viewBox="0 0 142 18" xmlns="http://www.w3.org/2000/svg"><path d="M2 12.744L12.008 5l10.69 7.305L32.024 5l10.546 7.43L52.642 5 62 13.308 72 5l12.272 8.308L94.216 4l11.268 10L118 4l11.58 10 10.703-10" stroke="#CAD400" strokeWidth={5} fill="none" fillRule="evenodd" /></Squiggle>
			) : (
				<Squiggle viewBox="0 0 108 17" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 12.744L12.008 5l10.69 7.305L32.024 5l10.546 7.43L52.642 5 62 13.308 72 5l12.272 8.308L94.216 4l11.268 10" stroke="#CAD400" strokeWidth={5} fill="none" fillRule="evenodd" /></Squiggle>
			)}
		</TitleWrapper>
	);
};

export const cssVariables = {
	width: '--squiggle-width',
}

type TitleWrapperProps = Pick<SquiggleTitleComponentProps, 'withLongerSquiggle' | 'canWrap'>
const TitleWrapper = styled.div<TitleWrapperProps>`
	--squiggle-max-width: ${props => props.withLongerSquiggle ? 'none' : '8.125rem'};
	display: table;
	width: min-content;
	margin-bottom: ${({ theme }) => theme.spacing[5]};
	white-space: ${({ canWrap }) => !canWrap && 'nowrap'};
`;

type TitleProps = Pick<SquiggleTitleComponentProps, 'size'>;
const Title = styled(CapsTitle)<TitleProps>`
	color: ${({ theme }) => theme.colors.text.dark_alt};
	font-size: ${({ theme, size }) => {
		if(size === 'lg') return theme.fontSize.lg;
		return theme.fontSize.sm;
	}};
	letter-spacing: ${({ size }) => {
		if(size === 'lg') return '0.14rem';
		return '0.1rem';
	}};
`;

const Squiggle = styled.svg`
	width: var(--squiggle-width, 100%);
	max-width: var(--squiggle-max-width);
	height: auto;
	max-height: 1.125rem;
	margin: 0.1rem auto 0;
`;

export default SquiggleTitleComponent;