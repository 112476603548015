import { useSelector } from '@xstate/react';
import React from 'react';

import CalendarComponent from '../../components/CalendarComponent';
import { GameContext } from '../../contexts/GameContext';

export type SeasonTileViewProps = {}

const SeasonTileView: React.FC<SeasonTileViewProps> = () => {
	const { gameService } = React.useContext(GameContext);
	const currentQuestion = useSelector(gameService, state => state.context.currentQuestion);
	
	return (
		<CalendarComponent date={currentQuestion?.senarioDate} />
	);
};

export default SeasonTileView;