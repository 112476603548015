import React from 'react';
import styled from 'styled-components/macro';

import ArrowButtonComponent from '../../../components/ArrowButtonComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import LogoComponent from '../../../components/LogoComponent';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { LanguageId } from '../../../types';
import { testIds } from '../../../utils/testing';
import { desktopMediaQuery } from '../styles';
import { Text } from './Typography';

export type HomeScreenLangSelectProps = {
	onNext: () => void;
};

export const HomeScreenLangSelect: React.FC<HomeScreenLangSelectProps> = ({
	onNext,
}) => {
	const { language, set: setLanguage, ...lang } = React.useContext(
		LanguageContext
	);

	const handleChangeLanguage = (val: LanguageId) => {
		if (language === val) return;
		setLanguage(val);
	};

	return (
		<LangScreen>
			<Logo />

			<Text>
				<span id="choose_language" lang="en">
					Please choose a language
				</span>
				<span lang="cy">Dewiswch iaith</span>
			</Text>

			<LangSwitcher>
				<ButtonComponent
					aria-pressed={language === 'en'}
					isHollow={language !== 'en'}
					copy="English"
					handlePress={() => handleChangeLanguage('en')}
					lang={language}
					aria-label="Select language: English"
				/>
				<ButtonComponent
					aria-pressed={language === 'cy'}
					isHollow={language !== 'cy'}
					copy="Cymraeg"
					handlePress={() => handleChangeLanguage('cy')}
					lang={language}
					aria-label="Select language: Welsh"
				/>
			</LangSwitcher>

			<ArrowButtonComponent
				aria-label={lang.startGame}
				lang={language}
				data-testid={testIds.homeScreenStart}
				onClick={onNext}
			>
				{lang.startGame}
			</ArrowButtonComponent>
		</LangScreen>
	);
};

const LangScreen = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10vh;

	@media ${desktopMediaQuery} {
		margin-top: 0;
	}

	@media ${desktopMediaQuery} and (min-height: 540px) {
		margin-top: -6rem;
	}

	@media ${desktopMediaQuery} and (min-height: 760px) {
		margin-top: -18rem;
	}
`;

const LangSwitcher = styled.div`
	--btn-spacing: ${({ theme }) => theme.spacing[2]};
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing[10]};

	> * {
		flex: initial;
		width: 9.375rem;
		margin: var(--btn-spacing);
	}

	@media ${desktopMediaQuery} {
		--btn-spacing: ${({ theme }) => theme.spacing[3]};
	}
`;

const Logo = styled(LogoComponent)`
	height: max(80px, 16vw);
	margin-bottom: ${({ theme }) => theme.spacing[18]};

	@media ${desktopMediaQuery} {
		height: 132px;
	}
`;
