import React from 'react';

type VolumeContextType = [
	// @ts-ignore
	hasVolume: boolean,
	set: (hasVolume: boolean) => void
];

export const VolumeContext = React.createContext<VolumeContextType>([
	true,
	() => {},
]);

/** Volume context provider.
 *
 * Returns an array with a boolean at position 0 indicating whether volume is active
 * and a setter at position 1.
 *
 * @example
 * const [volumeIsActive, setVolumeIsActive] = React.useContext(VolumeContext)
 * volumeIsActive: boolean
 * set: (volumeIsActive: boolean) => void;
 *
 * */

export const VolumeProvider: React.FC = ({ children }) => {
	const [volumeIsActive, set] = React.useState(true);

	const context: VolumeContextType = [
		volumeIsActive,
		set
	];

	return (
		<VolumeContext.Provider value={context}>{children}</VolumeContext.Provider>
	);
};
