import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  max-width: 76.25rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing[5]};
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

export const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

export const CenterView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Screen = styled(CenterView)`
  min-height: 100vh;
  min-width: 100vw;
`

export const InputBackground = css`
		height: 42px;
		padding: 0 var(--pad-sides);
		border: 0;
		background: ${({ theme }) => theme.colors.primary_ultralight};
		appearance: none;
		font-family: ${({ theme }) => theme.font.primary};
		font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => '#182044'};
`

/** Square component. Matches height to its width. */

export const Square = styled(CenterView)`
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

/** Circle component. Matches height to its width. */

export const Circle = styled(Square)`
  border-radius: ${({ theme }) => theme.radii.round};
`;