import { createGlobalStyle } from 'styled-components/macro';
import { focus } from './common';
import { normalize } from 'polished';
import { reset } from './reset';

const globalStyles = createGlobalStyle`
	${reset};
  ${normalize()}
  -webkit-font-smoothing: antialiased;

	html {
		font-size: 16px;
	}

  body {
    min-height: 100vh;
    color: ${({ theme }) => theme.colors.text.dark};
    background: #F2F4F6;
  }

  * {
    ${focus}
  }

	*,*:before,*:after {
		box-sizing: border-box;
	}

  svg {
    display: block;
  }

  button {
    cursor: pointer;
  }
`;

export default globalStyles;
