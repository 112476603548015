import styled from "styled-components";

export const DualButtons = styled.div`
	--btn-spacing: ${({ theme }) => theme.spacing[2]};
	display: flex;
	flex-wrap: wrap;
	padding-top: ${({ theme }) => theme.spacing[4]};
	margin: calc(var(--btn-spacing) * -1);

	>* {
		flex: initial;
		min-width: 150px;
		margin: var(--btn-spacing);
	}
`;