import React, { useEffect } from 'react';
import { SentimentId } from '../types';

export type CurrentStats = {
	wellBeing: number;
	economy: number;
	health: number;
	publicTrust: number;
	sentiment: SentimentId;
	socialHashtags: string[];
};

type HistoricalStats = {
	historicalWellBeing: number[];
	historicalEconomy: number[];
	historicalHealth: number[];
	historicalPublicTrust: number[];
	historicalSentiment: SentimentId[];
	historicalSocialHashtags: string[];
};

type Stats = CurrentStats & HistoricalStats;

type Context = Stats & {
	resetStats: () => void;
	updateStats: (stats: Partial<CurrentStats>) => void;
};

type Defaults = {
	current: CurrentStats;
	historical: HistoricalStats;
};

const defaultCurrent: CurrentStats = {
	wellBeing: 50,
	economy: 50,
	health: 50,
	publicTrust: 10,
	sentiment: 'happy',
	socialHashtags: ['NewPM', 'NewPrimeMinister', 'FreshIdeasFreshFuture'],
}

const defaults: Defaults = {
	current: defaultCurrent,
	historical: {
		historicalWellBeing: [defaultCurrent.wellBeing],
		historicalEconomy: [defaultCurrent.economy],
		historicalHealth: [defaultCurrent.health],
		historicalPublicTrust: [defaultCurrent.publicTrust],
		historicalSentiment: [defaultCurrent.sentiment],
		historicalSocialHashtags: defaultCurrent.socialHashtags,
	},
};

export const StatsContext = React.createContext<Context>({
	...defaults.current,
	...defaults.historical,
	resetStats: () => null,
	updateStats: () => null,
});

export const StatsProvider: React.FC = ({ children }) => {
	const [currentStats, setCurrentStats] = React.useState<CurrentStats>(
		defaults.current
	);
	const [historicalStats, setHistoricalStats] = React.useState<HistoricalStats>(
		defaults.historical
	);

	/**
	 * Combines the current stats with incoming modifiers.
	 * Health shouldn't go below 0 because negative hospital capacity doesn't make sense.
	 * Manually updates history with new values
	 * @param stats Incoming stat modifiers
	 */
	const updateStats = (stats: Partial<CurrentStats>) => {
		const health = currentStats.health + (stats?.health ?? 0) > 0 ? currentStats.health + (stats?.health ?? 0) : 0;
		const economy = currentStats.economy + (stats?.economy ?? 0);
		const wellBeing = currentStats.wellBeing + (stats?.wellBeing ?? 0) > 0 ? currentStats.wellBeing + (stats?.wellBeing ?? 0) : 0;
		const publicTrust = currentStats.publicTrust + (stats?.publicTrust ?? 0);
		const sentiment = stats?.sentiment ?? currentStats.sentiment;
		const socialHashtags = stats?.socialHashtags?.length
			? stats.socialHashtags
			: currentStats.socialHashtags;

		setCurrentStats({
			health,
			economy,
			wellBeing,
			publicTrust,
			sentiment,
			socialHashtags,
		});
		
		setHistoricalStats(prev => {
			return {
				...prev,
				historicalHealth: stats?.health ? [ ...prev.historicalHealth, health ] : prev.historicalHealth,
				historicalEconomy: stats?.economy ? [ ...prev.historicalEconomy, economy ] : prev.historicalEconomy,
				historicalWellBeing: stats?.wellBeing ? [ ...prev.historicalWellBeing, wellBeing ] : prev.historicalWellBeing,
				historicalPublicTrust: stats?.publicTrust ? [ ...prev.historicalPublicTrust, publicTrust ] : prev.historicalPublicTrust,
				historicalSentiment: stats?.sentiment ? [ ...prev.historicalSentiment, sentiment ] : prev.historicalSentiment,
				historicalSocialHashtags: stats?.socialHashtags ? [ ...prev.historicalSocialHashtags, ...socialHashtags ] : prev.historicalSocialHashtags,
			}
		});
	};

	/**
	 * Resets all stats to the default values
	 */
	const resetStats = () => {
		setCurrentStats(defaults.current);
		setHistoricalStats(defaults.historical);
	}

	const context: Context = {
		...currentStats,
		...historicalStats,
		resetStats,
		updateStats,
	};

	return (
		<StatsContext.Provider value={context}>{children}</StatsContext.Provider>
	);
};
