import React from 'react';
import styled, { css } from 'styled-components';
import {
	faEllipsisH,
	faEnvelopeOpen,
	faReply,
	faReplyAll,
	faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonComponent from '../../components/ButtonComponent';
import ModalComponent, {
	ModalButtons,
	ModalChildren,
	ModalComponentProps,
} from '../../components/ModalComponent';
import { Paragraph } from '../../components/Typography';
import { LanguageContext } from '../../contexts/LanguageContext';
import ContentComponent from '../../components/ContentComponent';
import { mediaQueries } from '../../styles/mediaQueries';
import EmptyInboxComponent from '../../components/EmptyInboxComponent';
import { testIds } from '../../utils/testing';

export type EmailContentLayoutProps = Pick<
	ModalComponentProps,
	'isOpen' | 'onClose'
> & {
	emailBody: string;
	title: string;
	fromName: string;
	fromEmail: string;
	fromDescription: string;
};

const EmailContentLayout: React.FC<EmailContentLayoutProps> = ({
	isOpen,
	onClose,
	emailBody,
	title,
	fromName,
	fromEmail,
	fromDescription
}) => {
	const { okayText, newEmailA11yText } = React.useContext(LanguageContext);

	return (
		<Modal
			isColor
			contentLabel={newEmailA11yText}
			isOpen={isOpen}
			onClose={onClose}
			title={title || 'Email'}
			titleIcon={faEnvelopeOpen}
			isTitleUpperCase={false}
		>
			{title ? (
				<>
					<EmailContent>
						<EmailMeta>
							<FromInfo>
								<Avatar>
									<FontAwesomeIcon icon={faUser} />
								</Avatar>

								<div>
									<Paragraph data-testid={testIds.emailFromName}>{fromName}</Paragraph>
									<Paragraph data-testid={testIds.emailFromEmail}>{fromEmail}</Paragraph>
								</div>
							</FromInfo>

							<EmailControls>
								<FontAwesomeIcon icon={faReply} />
								<FontAwesomeIcon icon={faReplyAll} />
								<FontAwesomeIcon icon={faEllipsisH} />
							</EmailControls>
						</EmailMeta>

						<EmailBody 
							tabIndex={0}
							data-testid={testIds.emailBody}
						>
							<ContentComponent copy={emailBody} />
							{fromDescription && (
								<Description>
									<Paragraph>{ fromDescription }</Paragraph>
								</Description>
							)}
						</EmailBody>
					</EmailContent>

					<ModalButtons>
						<ButtonComponent data-testid={testIds.emailOkay} handlePress={onClose} copy={okayText} />
					</ModalButtons>
				</>
			) : (
				<EmptyInboxComponent type="email" />
			)}
		</Modal>
	);
};

const Description = styled.div`
	border-top: 1px solid rgba(50, 50, 50, 0.1);
	padding-top: 15px;

	${Paragraph} {
		font-style: italic;
	}
`;

const customScrollbar = css`
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.colors.primary_ultralight};
		border-radius: ${({ theme }) => theme.radii.sm};
	}
	&::-webkit-scrollbar-thumb {
		background: ${({ theme }) => theme.colors.primary};
		border-radius: ${({ theme }) => theme.radii.sm};
	}
	// firefox
	scrollbar-color: ${({ theme }) =>
		`${theme.colors.primary} ${theme.colors.primary_ultralight}`};
`;

const Modal = styled(ModalComponent)`
	${ModalChildren} {
		--modal-top-padding: ${({ theme }) => theme.spacing[2]};
		--modal-bottom-padding: ${({ theme }) => theme.spacing[2]};
	}
`;

const FromInfo = styled.div`
	display: flex;
	align-items: flex-end;
`;

const EmailControls = styled.div`
	display: flex;
	transform: translateY(-0.25rem);

	> * + * {
		margin-left: ${({ theme }) => theme.spacing[2]};
	}

	@media ${mediaQueries.isMobileDash} {
		display: none;
	}
`;

const EmailMeta = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const Avatar = styled.div`
	display: grid;
	place-content: center;
	width: 53px;
	height: 53px;
	margin-right: ${({ theme }) => theme.spacing[3]};
	border-radius: 50%;
	background: #d8d8d8;
`;
// TODO: don't overflow email UI at mobile

const EmailContent = styled.div`
	padding: ${({ theme }) => theme.spacing.lg};
	border-radius: ${({ theme }) => theme.radii.xs};
	background: ${({ theme }) => theme.colors.whiteish};
`;

const EmailBody = styled.div`
	max-height: 17.35rem;
	overflow: auto;
	${customScrollbar}
	${Paragraph} {
		+ * {
			margin-top: ${({ theme }) => theme.spacing[5]};
		}
	}

	@media (max-aspect-ratio: 1/1) {
		max-height: none;
	}
`;

export default EmailContentLayout;
