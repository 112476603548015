import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideVisually } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';

import { Paragraph } from '../Typography';

const letters = ['A','B','C','D','E'];

export type RadioSelectComponentProps = {
	options?: string[];
	value?: string;
	onChange: (val: string) => void;
	name: string;
}
// TODO: check on screen reader
const RadioSelectComponent: React.FC<RadioSelectComponentProps> = ({
	options,
	value,
	onChange,
	name,
}) => {
	if(!options?.length) return null;
	
	return (
		<Wrapper>
			{options?.map((opt, i) => (
				<Label key={opt}>
					<input 
						type="radio" 
						name={name} 
						tabIndex={0}
						checked={value === opt} 
						onChange={() => onChange(opt)} 
					/>
					<Option data-letter={letters[i]}>
						<Paragraph>{ opt }</Paragraph>
						<Check icon={faCheck} />
					</Option>
				</Label>
			))}
		</Wrapper>
	);
};

const Check = styled(FontAwesomeIcon)`
	position: absolute;
	top: 50%;
	right: var(--padding-sides);
	transform: translateY(-50%);
	display: none;
	color: ${({ theme }) => theme.colors.accents.yellow_dark};
`;

const Option = styled.div`
	--padding-sides: ${({ theme }) => theme.spacing[7]};
	position: relative;
	display: flex;
	padding-left: var(--padding-sides);
	// Prevent overlapping Check
	padding-right: calc(var(--padding-sides) + 26px);
	padding-top: ${({ theme }) => theme.spacing.lg};
	padding-bottom: ${({ theme }) => theme.spacing.lg};
	border-radius: ${({ theme }) => theme.radii.md};
	background: ${({ theme }) => theme.colors.primary_ultralight};

	&:before {
		content: attr(data-letter);
		position: relative;
		top: 4px;
		margin-right: ${({ theme }) => theme.spacing[8]};
		font-family: ${({ theme }) => theme.font.primary};
		font-size: ${({ theme }) => theme.fontSize.sm};
		font-weight: ${({ theme }) => theme.fontWeight.extraBold};
	}
`;

const Label = styled.label`
	cursor: pointer;

	input {
		${hideVisually}
	}

	input:checked +${Option} {
		background: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.text.light};

		${Check} {
			display: block;
		}
	}

	+* {
		margin-top: ${({ theme }) => theme.spacing.md};
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export default RadioSelectComponent;