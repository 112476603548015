const postcode = /^[A-Z]{1,2}[0-9][A-Z0-9]?$/;

/** Predicate function, returns whether or not the string is a valid
 * uk post area code. Doesn't match against a list, just checks if 
 * the number/letter combination is correct.
 * @example
 * 
 * isUkPostAreaCode('BS6') => true;
 * isUkPostAreaCode('BS65') => true;
 * isUkPostAreaCode('foo') => false;
 * isUkPostAreaCode('BS65UP') => false;
 */

export const isUkPostAreaCode = (val?: string) => typeof val === 'string' && postcode.test(val);
export default isUkPostAreaCode;