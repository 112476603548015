import React from 'react';
import styled from 'styled-components';
import {
	faFacebookF,
	faInstagram,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, FacebookShareButton } from 'react-share';
import { links } from '../../config';
import { LanguageContext } from '../../contexts/LanguageContext';

export type SocialMediaLinksComponentProps = {
	className?: string;
	asShare?: boolean;
	isRound?: boolean;
	quote?: string;
	size: 'sm' | 'md';
};

const SocialMediaLinksComponent: React.FC<SocialMediaLinksComponentProps> = ({
	asShare,
	className,
	isRound,
	quote,
	size,
}) => {
	const lang = React.useContext(LanguageContext);

	return (
		<Wrapper className={className} isRound={isRound} size={size}>
			{asShare ? (
				<>
					<FacebookShareButton
						quote={quote}
						url={links.tteApp}
						// I'm assuming the # should be in en
						hashtag="TrustTheExperts"
						aria-label={`${lang.shareThis} - Facebook`}
					>
						<FontAwesomeIcon icon={faFacebookF} />
					</FacebookShareButton>

					<TwitterShareButton
						title={quote}
						// I'm assuming the # should be in en
						hashtags={['TrustTheExperts']}
						url={links.tteApp}
						aria-label={`${lang.shareThis} - Twitter`}
					>
						<FontAwesomeIcon icon={faTwitter} />
					</TwitterShareButton>

					{/* There is no sharing on Instagram */}
				</>
			) : (
				<>
					<a
						href={links.facebook}
						target="_blank"
						rel="noopener nofollow noreferrer"
						aria-label="Facebook"
					>
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a
						href={links.twitter}
						target="_blank"
						rel="noopener nofollow noreferrer"
						aria-label="Twitter"
					>
						<FontAwesomeIcon icon={faTwitter} />
					</a>
					<a
						href={links.instagram}
						target="_blank"
						rel="noopener nofollow noreferrer"
						aria-label="Instagram"
					>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
				</>
			)}
		</Wrapper>
	);
};

type WrapperProps = Pick<SocialMediaLinksComponentProps, 'isRound' | 'size'>;
const Wrapper = styled.div<WrapperProps>`
	--spacing: 6px;
	display: flex;
	align-items: center;
	/* Offset the spacing of the inner components */
	margin: calc(var(--spacing) * -1);

	a,
	button {
		${({ theme, isRound }) => {
			if (isRound) {
				return `
					display: grid;
					place-content: center;
					width: 45px;
					height: 45px;
					border-radius: 50%;
					background: ${theme.colors.background.light}!important;
				`;
			}

			return `
				padding: ${theme.spacing[1]} ${theme.spacing[2]}!important;
			`;
		}}

		margin: var(--spacing);
		color: ${({ theme }) => theme.colors.text.dark}!important;
		font: unset !important;
		font-size: ${({ theme, size }) => {
			if (size === 'sm') return theme.fontSize.lg;
			return theme.fontSize.xxl;
		}}!important;
	}
`;

export default SocialMediaLinksComponent;
