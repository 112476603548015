import {
	sentimentHappy,
	sentimentAngry,
	sentimentSad,
	sentimentUnhappy,
	sentimentUnsure,
	sentimentNeutral,
} from '../../assets';
import { SentimentId } from '../../types';

// TODO: When sentiments are known, alter the expression returns;
export const getSentimentIcon = (sentiment: SentimentId) => {
  if (sentiment === 'happy') return sentimentHappy;
  if (sentiment === 'sad') return sentimentSad;
  if (sentiment === 'unsure') return sentimentUnsure;
  if (sentiment === 'angry') return sentimentAngry;
  if (sentiment === 'unhappy') return sentimentUnhappy;
  if (sentiment === 'neutral') return sentimentNeutral;
  //default
  console.error('No sentiment value passed to social media tile');
  return sentimentNeutral;
};

export const getReElectionIcon = (percent: number) => {
  if(percent > 55) return sentimentHappy;
  if(percent > 40) return sentimentNeutral;
  return sentimentUnhappy;
}