import React from 'react';

// components
import SelectInputLayout from '../SelectInputLayout';
import Input from '../../components/InputComponent';
import Select from '../../components/SelectComponent';
// contexts
import { LanguageContext } from '../../contexts/LanguageContext';
// utils
import { stripCommas } from '../../utils/stripCommas';
// data
import { countries } from '../../data/countries';
import { testIds } from '../../utils/testing';

type LocationLayoutValues = {
	postcode?: string;
	country?: string;
};

export type LocationLayoutProps = {
	onChange: (values: LocationLayoutValues) => void;
	value: LocationLayoutValues;
};

const LocationLayout: React.FC<LocationLayoutProps> = ({ onChange, value }) => {
	const copy = React.useContext(LanguageContext);
	const [isUkSelected, setIsUkSelected] = React.useState(false);
	const [optionIsPlaceholder, setOptionIsPlaceholder] = React.useState(true);
	const [postcode, setPostcode] = React.useState<string>(value?.postcode || '');
	const [country, setCountry] = React.useState<string | undefined>(value?.country);

	const handleInputChange = (val: string) => {
		setPostcode(val);
		onChange({
			postcode: val,
			country,
		});
	};

	const handleSelectInput = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (optionIsPlaceholder) {
			setOptionIsPlaceholder(false);
		}
		setCountry(event.target.value);
		setIsUkSelected(event.target.value === 'United Kingdom');
		onChange({
			postcode,
			country: event.target.value,
		});
	};

	return (
		<SelectInputLayout>
			<Select
				data-testid={testIds.countrySelect}
				id="location-select"
				onChange={handleSelectInput}
				value={country}
				placeholder="Select country"
			>
				<option>United Kingdom</option>
				<option disabled>──────────</option>
				{countries.map((item) => (
					<option key={item} value={item}>{item}</option>
				))}
			</Select>
			{(isUkSelected || !!postcode) && (
				<Input
					data-testid={testIds.postcodeInput}
					placeholder={copy.inputPostcodePlaceholder}
					onChange={handleInputChange}
					value={postcode}
					parser={stripCommas}
				/>
			)}
		</SelectInputLayout>
	);
};

export default LocationLayout;
