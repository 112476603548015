import React from 'react';
import styled from 'styled-components';
import { faVolume, faVolumeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VolumeControlCopy } from '../Typography';
import { VolumeContext } from '../../contexts/VolumeContext/VolumeContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { mediaQueries } from '../../styles/mediaQueries';

export type VolumeControlComponentProps = {
	isOnLightBg: boolean;
};

const VolumeControlComponent: React.FC<VolumeControlComponentProps> = ({
	isOnLightBg,
	...props
}) => {
	const [volumeIsActive, toggleVolume] = React.useContext(VolumeContext);
	const lang = React.useContext(LanguageContext);

	return (
		<Wrapper
			aria-pressed={volumeIsActive ? 'false' : 'true'}
			onClick={() => toggleVolume(!volumeIsActive)}
			aria-label={lang.muteAudio}
			isOnLightBg={isOnLightBg}
			{...props}
		>
			<div>
				<VolumeControlCopy>{lang.volumeText}</VolumeControlCopy>
				<FontAwesomeIcon
					icon={volumeIsActive ? faVolume : faVolumeSlash}
					fixedWidth
				/>
			</div>
		</Wrapper>
	);
};

type WrapperProps = Pick<VolumeControlComponentProps, 'isOnLightBg'>;
const Wrapper = styled.button<WrapperProps>`
	--padding: ${({ theme }) => theme.spacing[3]};
	border: 0;
	padding: 0;
	background: none;
	color: ${({ theme, isOnLightBg }) => isOnLightBg ? theme.colors.text.dark : theme.colors.text.light};

	>div {
		display: flex;
		align-items: center;
		padding: var(--padding);
		margin-right: calc(var(--padding) * -1);
	}

	${VolumeControlCopy} {
		margin-right: ${({ theme }) => theme.spacing[2]};
		color: inherit;

		@media ${mediaQueries.isMobileDash} {
			display: none;
		}
	}
`;

export default VolumeControlComponent;
