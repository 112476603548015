import { useSelector } from '@xstate/react';
import * as React from 'react';
import { useHistory } from 'react-router';
import { GameContext } from '../../contexts/GameContext';
import { StatsContext } from '../../contexts/StatsContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const useRestartGame = () => {
	const stats = React.useContext(StatsContext);
	const { restartGame: resetAnalytics } = React.useContext(AnalyticsContext);
	const { gameService, onPlayAgain } = React.useContext(GameContext);
	const isPlaying = useSelector(gameService, (state) =>
		state.matches('playing')
	);
	const history = useHistory();

	React.useEffect(() => {
		if (!isPlaying) return;
		history.push('/play');
	}, [history, isPlaying]);

	const restartGame = () => {
		stats.resetStats();
		resetAnalytics();
		onPlayAgain();
	};

	return {
		restartGame,
	} as const;
};

export default useRestartGame;
