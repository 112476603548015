import React from 'react';
import {
	NameType,
	ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import styled from 'styled-components';
import { Paragraph } from '../Typography';

export type ChartTooltipComponentProps = {
	payload?: Array<{
		name?: NameType;
		color?: string;
		value?: ValueType;
	}>;
	label: string;
	gradientColor: string;
	valueFormatter?: (val: ValueType | undefined) => string;
};

const ChartTooltipComponent: React.FC<ChartTooltipComponentProps> = ({
	payload,
	valueFormatter,
}) => {
	return (
		<Wrapper>
			{payload?.map(({ value }, i) => {
				const val = valueFormatter ? valueFormatter(value) : value;
				return <Paragraph key={`val-${i}`}>{val}</Paragraph>;
			})}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 0.5rem 0.75rem;
	border-radius: ${({ theme }) => theme.radii.sm};
	background: #fff;

	${Paragraph} {
		font-size: ${({ theme }) => theme.fontSize.sm};
	}
`;

export default ChartTooltipComponent;
