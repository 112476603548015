import React from 'react';
// components
import ToolTip from '../TooltipComponent';
import _Indicator from '../IndicatorComponent';
import { TileTitleHeading as _TileTitleHeading } from '../Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Row, CenterView } from '../Containers';
// types
import {
	StatisticChangeId,
	StatisticChangeValenceId,
	ChartPercentageLabelId,
} from '../../types';
// style
import styled from 'styled-components';

export type TileTitleComponentProps = {
	title: string;
	tooltipCopy?: string;
	arrowIndicator?: StatisticChangeId;
	icon?: IconDefinition;
	valence?: StatisticChangeValenceId;
	lightText?: boolean;
	percentage?: number;
	percentageLabel?: ChartPercentageLabelId;
};

const TileTitleComponent: React.FC<TileTitleComponentProps> = ({
	title,
	tooltipCopy,
	arrowIndicator,
	icon,
	valence,
	percentage,
	percentageLabel,
	lightText = false,
}) => {
	const hasPercentageIndicator = percentageLabel || arrowIndicator;

	return (
		<RowOuter>
			<RowInner>
				<TileTitleHeading lightText={lightText}>{title}</TileTitleHeading>
				{icon && <Icon icon={icon} />}
				{tooltipCopy && (
					<ToolTipWrapper>
						<ToolTip copy={tooltipCopy} />
					</ToolTipWrapper>
				)}
			</RowInner>
			{hasPercentageIndicator && (
				<PercentageWrapper>
					{percentageLabel && (
						<TileTitleHeading
							lightText={lightText}
							as="p"
						>{`${percentage}% ${percentageLabel}`}</TileTitleHeading>
					)}
					{arrowIndicator && valence && (
						<Indicator change={arrowIndicator} valence={valence} />
					)}
				</PercentageWrapper>
			)}
		</RowOuter>
	);
};

const stackMediaQuery = '(max-width: 549px)';

const Indicator = styled(_Indicator)`
	@media ${stackMediaQuery} {
		position: absolute;
		top: var(--tile-padding-vert, 1rem);
		right: var(--tile-padding-horiz, 1rem);
	}
`;

const Icon = styled(FontAwesomeIcon)`
	height: 1.5625rem;
	margin-right: ${({ theme }) => theme.spacing.md};
	color: ${({ theme }) => theme.colors.primary_light};

	&& {
		width: auto;
	}

	@media ${stackMediaQuery} {
		height: 1.125rem;
	}
`;

const RowOuter = styled(Row)`
	flex: initial;
	justify-content: space-between;
	margin-bottom: ${({ theme }) => theme.spacing[2]};
	z-index: ${({ theme }) => theme.zIndexes.tileTitle};

	@media ${stackMediaQuery} {
		flex-wrap: wrap;
	}
`;

const RowInner = styled(Row)`
	justify-content: flex-start;

	@media ${stackMediaQuery} {
		max-width: 266px;
	}
`;

const ToolTipWrapper = styled(CenterView)`
	flex: 0 1;
`;

const PercentageWrapper = styled(Row)`
	margin-left: ${({ theme }) => theme.spacing.md};
	flex: initial;

	@media ${stackMediaQuery} {
		width: 100%;
		justify-content: flex-start;
		margin: 0.25rem 0 0;
	}
`;

const TileTitleHeading = styled(_TileTitleHeading)<{
	lightText: boolean;
}>`
	margin-right: ${({ theme }) => theme.spacing.md};
	color: ${({ theme, lightText }) => lightText ? theme.colors.text.light : theme.colors.text.dark};
	line-height: 1;
`;

export default TileTitleComponent;
