import React from 'react';
import styled from 'styled-components';

import TypingComponent, { Delay } from '../../../components/TypingComponent';
import { Paragraph } from '../../../components/Typography';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { mediaQueries } from '../../../styles/mediaQueries';
import { useA11yFocus } from '../../../hooks/useA11yFocus';
import { testIds } from '../../../utils/testing';

type SetupBriefProps = {
	onDone: () => void;
};

export const SetupBrief: React.FC<SetupBriefProps> = ({ onDone }) => {
	const lang = React.useContext(LanguageContext);
	const [part, setPart] = React.useState(0);
	const a11yfocus = useA11yFocus([part]);

	const onNext = (index: number) => {
		setPart(part + 1);
	};

	const parts = React.useMemo(
		() => [
			<SetupText ref={a11yfocus} tabIndex={-1}>
				{lang.briefOne + ' '}
				{lang.briefOneDelayed && <Delay ms={500} />}
				{lang.briefOneDelayed}
			</SetupText>,
			<SetupText ref={a11yfocus} tabIndex={-1}>
				{lang.briefTwo}
			</SetupText>,
			<SetupText ref={a11yfocus} tabIndex={-1}>
				{lang.briefThree}
			</SetupText>,
			<SetupText ref={a11yfocus} tabIndex={-1}>
				{lang.briefFour}
			</SetupText>,
		],
		[lang, a11yfocus]
	);

	return (
		<TypingComponent
			data-testid={testIds.setupBrief}
			finishButtonText={lang.letsGetStarted}
			nextButtonText={lang.okayText}
			parts={parts}
			onDone={onDone}
			onNext={onNext}
		/>
	);
};

export const SetupText = styled(Paragraph)`
	font-size: ${({ theme }) => theme.fontSize.xxxl};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};

	@media ${mediaQueries.isMobileScene} {
		font-size: ${({ theme }) => theme.fontSize.xl};
	}
`;
