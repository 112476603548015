import {
	ChartData,
	ChartDatum,
	SentimentId,
	StatisticChangeId,
	StatisticChangeValenceId,
	StatisticIncreaseValenceMappingId,
} from '../../types';
import { first as firstOf, last as lastOf } from 'lodash';

/** Maps statistic change direction to valence. Statistic decrease is assumed to be the inverse.
 * (e.g., if: increase => positive, then: decrease => negative)
 * */

export const getValence = (
	data: ChartData,
	mapping: StatisticIncreaseValenceMappingId
): StatisticChangeValenceId => {
	const change = getStatisticChange(data);

	if (change === 'increase') {
		return mapping === 'increaseIsPositive' ? 'positive' : 'negative';
	}
	if (change === 'decrease') {
		return mapping === 'increaseIsPositive' ? 'negative' : 'positive';
	}
	if (change === 'noChange') return 'neutral';
	// default
	return 'neutral';
};

/** Determine whether statistic has increased, decreased, or stayed the same.
 * */

export const getStatisticChange = (data: ChartData): StatisticChangeId => {
	if (data.length <= 1) return 'noChange';
	const last = lastOf(data) as ChartDatum;
	const temp = [...data];
	temp.pop();
	const penultimate = lastOf(temp) as ChartDatum;

	if (last.percent > penultimate.percent) return 'increase';
	if (last.percent < penultimate.percent) return 'decrease';
	return 'noChange';
};

export const getPercentageChange = (data: number[]) => {
	const last = lastOf(data) as number;
	const first = firstOf(data) as number;

	if(last < first) {
		return Math.ceil((first - last) / first * 100);
	} else {
		return Math.ceil((last - first) / first * 100);
	}
}

/** Get the difference, negative or positive, between start and end statistic.
 * */

export const getFinalStatisticChange = (data: number[]) => {
	// console.log('ecoooo', )
	const last = lastOf(data) as number;
	const first = firstOf(data) as number;
	return last - first;
};

export const getFinalEconomyValence = (data: number[], mapping: StatisticIncreaseValenceMappingId) => {
	const last = lastOf(data) as number;
	const first = firstOf(data) as number;
	if(last < first) {
		return mapping === 'increaseIsNegative' ? 'positive' : 'negative';
	} else if (last > first) {
		return mapping === 'increaseIsNegative' ? 'negative' : 'positive';
	} else {
		return 'neutral';
	}
}

/** Determine whether the final statistic is a positive, negative or neutral
 * change relative to the starting statistic.
 * */

export const getFinalValence = (
	change: number,
	mapping: StatisticIncreaseValenceMappingId
): StatisticChangeValenceId => {
	const isNeutral = Math.sign(change) === 0;
	const isPositive = Math.sign(change) === 1;
	const isNegative = Math.sign(change) === -1;
	if (isPositive) {
		return mapping === 'increaseIsPositive' ? 'positive' : 'negative';
	}
	if (isNegative) {
		return mapping === 'increaseIsPositive' ? 'negative' : 'positive';
	}
	if (isNeutral) return 'neutral';
	// default
	return 'neutral';
};

/** Passed a historical sentiment array A and a sentiment S, returns the number
 * of instances of S in A.
 * 
 * @example
 * const arr = ['happy', 'happy', 'sad];
 * quantifySentiment(arr, 'happy') // => 2
 * quantifySentiment(arr, 'sad') // => 1
 * quantifySentiment(arr, 'unsure') // => 0
 */

const quantifySentiment = (arr: SentimentId[], sentiment: SentimentId) =>
	arr.filter((item) => item === sentiment).length;

/** Passed an array of sentiments, returns the mode sentiment.
 *  Mode sentiment is the most common sentiment.
 *  If no unique mode sentiment exists, the final most common is returned.
 * 
 *  @example
 * const arr = ['happy', 'happy', 'sad];
 * getModeSentiment(arr) // => 'happy'
 * const arr2 = ['happy', 'happy', 'sad', 'sad];
 * getModeSentiment(arr) // => 'sad' 
 */

// TODO: Update these if the sentiments change

export const getModeSentiment = (arr: SentimentId[]) => {
  /** Represents number of instances of each sentiment on historical array. */
	const results: {
		[key in SentimentId]: number;
	} = {
		happy: quantifySentiment(arr, 'happy'),
		sad: quantifySentiment(arr, 'sad'),
		unhappy: quantifySentiment(arr, 'unhappy'),
		angry: quantifySentiment(arr, 'angry'),
		neutral: quantifySentiment(arr, 'neutral'),
		unsure: quantifySentiment(arr, 'unsure'),
	}; 

	const sentiments = Object.keys(results) as SentimentId[];

  /** Return the last most populous sentiment. */
	const reducer = (acc: { key: SentimentId; count: number; }, curr: SentimentId) => {
		return results[curr] > acc.count ? {
			key: curr,
			count: results[curr]
		} : acc;
	}
	
	const mode = sentiments.reduce(reducer, {
		key: sentiments[0],
		count: results[sentiments[0]]
	});

  return mode.key;
};

/**
 * Converts a given value from a given range to a proportional value using another range.
 * .e.g. getProprtionalRangeValue([0, 10], [20, 40], 5) === 30
 * @param fromRange Range that the given value comes from
 * @param toRange Target range for convering the value to a proportional value
 * @param value The given value
 * @returns number
 */
export const getProportionalRangeValue = (fromRange: number[], toRange: number[], value: number) => {
	const oldMin = fromRange[0];
	const oldMax = fromRange[fromRange.length - 1];
	const newMin = toRange[0];
	const newMax = toRange[toRange.length - 1];

	const oldRange = oldMax - oldMin;
	const newRange = newMax - newMin;
	return (((value - oldMin) * newRange) / oldRange) + newMin;
}