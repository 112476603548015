import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export type TooltipComponentProps = {
	copy: string;
};

const TooltipComponent: React.FC<TooltipComponentProps> = ({ copy }) => {
	const [visible, setVisible] = React.useState(false);

	/** Tippy doesn't register swipe events,
	 * -- the tooltip can get stuck on carousel.
	 * We need to close on touch end too.
	 */

	React.useEffect(() => {
		const handleTouch = () => {
			if (visible) {
				setVisible(false);
			}
		};
		window.addEventListener('touchstart', handleTouch);
		return () => {
			window.removeEventListener('touchstart', handleTouch);
		};
	});

	return (
		<Tooltip
			content={copy}
			visible={visible}
			onHide={() => {
				setVisible(false);
			}}
			onClickOutside={() => {
				setVisible(false);
			}}
		>
			<button
				css={`
					& {
						background-color: transparent;
					}
					border: unset;
				`}
				onClick={() => setVisible(!visible)}
			>
				<Icon icon={faInfoCircle} size="lg" />
			</button>
		</Tooltip>
	);
};

const Tooltip = styled(Tippy)`
	font-family: ${({ theme }) => theme.font.primary};
`;

const Icon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.colors.text.light};
	font-size: 1rem;
`;

export default TooltipComponent;
