import React, { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

// Components
import ArrowButtonComponent from '../../components/ArrowButtonComponent';
import ButtonComponent from '../../components/ButtonComponent';
import SelectComponent from '../../components/SelectComponent';
import SquiggleTitleComponent from '../../components/SquiggleTitleComponent';
import { SetupText } from '../../components/Typography';
import { DualButtons, SetupBrief, SetupIntro } from './components';
import SliderComponent from '../../components/SliderComponent';
// Layouts
import DeskLayout from '../../layouts/DeskLayout';
import LocationLayout from '../../layouts/LocationLayout';
import GenderLayout from '../../layouts/GenderLayout';
import SelectInputLayout from '../../layouts/SelectInputLayout';
// Context
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
// Hooks
import { useA11yFocus } from '../../hooks/useA11yFocus';
// Machines
import { SetupContext, SetupMachine } from '../../machines/setup';
// Styles
import { mediaQueries } from '../../styles/mediaQueries';
// Utils
import isUkPostAreaCode from '../../utils/isUkPostAreaCode';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import useFadeOut from '../../hooks/useFadeOut';
import { testIds } from '../../utils/testing';

export type SetupScreenProps = {};

const SetupScreen: React.FC<SetupScreenProps> = (props) => {
	useSetDocumentTitle('Setup - Trust the Experts Game');
	const [state, send] = useMachine(SetupMachine);
	const history = useHistory();
	const lang = React.useContext(LanguageContext);
	const { userDetails } = React.useContext(AnalyticsContext);
	const { onStartGame } = React.useContext(GameContext);

	const { age, gender, country, postcode, subject } = state.context;

	const handleStart = () => {
		onStartGame();
		history.push('/play');
	};

	const { fadeOut: fadeOutAndStartGame, styles } = useFadeOut(handleStart);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		send({ type: 'NEXT' });
	};

	const handleChangeValue = (key: keyof SetupContext, value: any) => {
		send({ type: 'UPDATE_VALUE', key, value });
	};

	const isIntro = state.matches('intro');
	const isCapturing = state.matches('settingUp');
	const isCapturingIntro = state.matches('settingUp.intro');
	const isCapturingAge = state.matches('settingUp.age');
	const isCapturingGender = state.matches('settingUp.gender');
	const isCapturingLocation = state.matches('settingUp.location');
	const isUploadingData = state.matches('settingUp.uploading');
	const isCapturingSubjects =
		state.matches('settingUp.subjects') || isUploadingData;
	const isBriefing = state.matches('briefing');

	// Fire Analytics Event :: userDetails
	if (isUploadingData) {
		if (age && gender && country && subject)
			// Convert Welsh into english

			userDetails({ age, gender, country, postCode: postcode, subject });
	}

	const isNextDisabled = (() => {
		if (isCapturingGender && !gender) return true;
		if (
			isCapturingLocation &&
			(!country ||
				(country === 'United Kingdom' && !isUkPostAreaCode(postcode)))
		)
			return true;
		if (isCapturingSubjects && !subject) return true;
		if (isUploadingData) return true;
		return false;
	})();

	const a11yfocus = useA11yFocus([
		isIntro,
		isCapturing,
		isCapturingAge,
		isCapturingIntro,
		isCapturingLocation,
		isCapturingSubjects,
	]);

	return (
		<DeskLayout style={styles}>
			<SquiggleTitleComponent size="lg">{lang.welcome}</SquiggleTitleComponent>

			{isIntro && (
				<SetupIntro
					onAccept={() => send('START_DATA_CAPTURE')}
					onDecline={() => send('SKIP_DATA_CAPTURE')}
				/>
			)}

			{isCapturing && (
				<DataCapture>
					{isCapturingIntro ? (
						<>
							<SetupText
								tabIndex={-1}
								ref={a11yfocus}
							>{`${lang.helloPrimeMinister} ...`}</SetupText>
							<StartSetup onClick={() => send('NEXT')}>
								{lang.letsGetStarted}
							</StartSetup>
						</>
					) : (
						<form onSubmit={handleSubmit}>
							{isCapturingAge && (
								<>
									<SetupText
										tabIndex={-1}
										ref={a11yfocus}
										data-testid={testIds.ageTitle}
									>
										{`${lang.thisIsAwkwardPrimeMinister}?`}
									</SetupText>
									<AgeInput
										min={10}
										max={100}
										initial={age}
										onChange={(val) => handleChangeValue('age', val)}
										a11yLabel="Your age"
										a11yValueLabel="Age: "
									/>
								</>
							)}

							{isCapturingGender && (
								<>
									<SetupText tabIndex={-1} ref={a11yfocus}>
										{lang.whatGender}
									</SetupText>
									<GenderLayout
										value={gender}
										onChange={(val) => {
											handleChangeValue('gender', val);
										}}
									/>
								</>
							)}

							{isCapturingLocation && (
								<>
									<SetupText tabIndex={-1} ref={a11yfocus}>
										{lang.andYourLocation}
									</SetupText>
									<LocationLayout
										value={{
											postcode,
											country,
										}}
										onChange={(val) => {
											handleChangeValue('country', val.country);
											handleChangeValue('postcode', val.postcode);
										}}
									/>
								</>
							)}

							{isCapturingSubjects && (
								<>
									<SetupText tabIndex={-1} ref={a11yfocus}>
										{lang.whichSubjects}
									</SetupText>
									<SelectInputLayout>
										<SelectComponent
											data-testid={testIds.subjectSelect}
											placeholder="Select Subject"
											value={subject}
											onChange={(e) =>
												handleChangeValue('subject', e.target.value)
											}
										>
											<option value="Accounting and Finance">
												{lang.accountAndFinance}
											</option>
											<option value="Business and Management">
												{lang.businessAndManagement}
											</option>
											<option value="Education">{lang.education}</option>
											<option value="Historical Subjects">
												{lang.historicalSubjects}
											</option>
											<option value="Law and Criminology">
												{lang.lawAndCrimonology}
											</option>
											<option value="Literature and Languages">
												{lang.literatureAndLanguages}
											</option>
											<option value="Politics and International Relations">
												{lang.politicsAndInternationalRelations}
											</option>
											<option value="Philosophy and Religion">
												{lang.philosophyAndReligion}
											</option>
										</SelectComponent>
									</SelectInputLayout>
								</>
							)}

							{!isCapturingIntro && (
								<DualButtons>
									{!isCapturingAge && (
										<ButtonComponent
											isHollow
											type="button"
											data-testid={testIds.setupBack}
											copy={lang.backText}
											handlePress={() => {
												send('BACK');
											}}
										/>
									)}
									<ButtonComponent
										data-testid={testIds.setupNext}
										disabled={isNextDisabled}
										copy={isCapturingSubjects ? lang.complete : lang.nextText}
									/>
								</DualButtons>
							)}
						</form>
					)}
				</DataCapture>
			)}

			{isBriefing && <SetupBrief onDone={fadeOutAndStartGame} />}
		</DeskLayout>
	);
};

const AgeInput = styled(SliderComponent)`
	max-width: 550px;
	margin-top: 0.625rem;
`;

const DataCapture = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 12rem;

	form {
		all: inherit;

		${DualButtons} {
			margin-top: auto;
		}
	}

	${DualButtons} {
		margin-top: auto;
	}

	@media ${mediaQueries.isStackedMobileSetup} {
		form {
			min-height: 14.125rem;
		}
	}
`;

const StartSetup = styled(ArrowButtonComponent)`
	margin-top: auto;
`;

export default SetupScreen;
