import styled from 'styled-components';
import { Paragraph } from '../../../components/Typography';
import { mediaQueries } from '../../../styles/mediaQueries';

export const Text = styled(Paragraph)<{ isBold?: boolean }>`
	max-width: 624px;
	margin-bottom: ${({ theme }) => theme.spacing[2]};
	font-size: ${({ theme }) => theme.fontSize.xxl};
	font-weight: ${({ theme, isBold }) => theme.fontWeight[isBold ? 'bold' : 'regular']};
	text-align: center;
	
	span:last-of-type {
		display: block;
	}

	@media not screen and ${mediaQueries.isDesktopScene} {
		font-size: ${({ theme }) => theme.fontSize.lg};
	}
`;