import { useSelector } from '@xstate/react';
import React from 'react';

import { GameContext } from '../../contexts/GameContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import EmailContentLayout from '../../layouts/EmailContentLayout';
import { currentRoundSelector } from '../../machines/game/selectors';

export type TextAdviceViewProps = {
	isOpen: boolean;
	onClose: () => void;
};

const TextAdviceView: React.FC<TextAdviceViewProps> = ({ isOpen, onClose }) => {
	const { gameService, onHearAdvice } = React.useContext(GameContext);
	const { openExpertAdvice, closeExpertAdvice } = React.useContext(
		AnalyticsContext
	);
	const emails = useSelector(gameService, (state) => state.context.emails);
	const hasNewEmail = useSelector(
		gameService,
		(state) => state.context.hasNewEmail
	);
	const currentRound = useSelector(gameService, currentRoundSelector);

	const email = emails[(currentRound ?? 1) - 1];

	React.useEffect(() => {
		if (isOpen && email) openExpertAdvice();
		if (!isOpen && email) closeExpertAdvice(email.title);
	}, [isOpen]);

	return (
		<EmailContentLayout
			isOpen={isOpen}
			onClose={() => {
				onClose();

				if (hasNewEmail) {
					onHearAdvice();
				}
			}}
			title={email?.emailSubject || ''}
			fromEmail={email?.expertsEmail || ''}
			fromName={email?.expertsName || ''}
			fromDescription={email?.expertsDescription || ''}
			emailBody={email?.copy || ''}
		/>
	);
};

export default TextAdviceView;
