export const testIds = {
	back: 'setup-back',
	carouselNext: 'carousel-next',
	carouselPrev: 'carousel-prev',
	next: 'setup-next',
	typerNext: 'typer-next',
	setupBrief: 'setup-brief',
	gameScreen: 'game-screen',
	dataCaptureAccept: 'setup-data-capture-accept',
	dataCaptureDecline: 'setup-data-capture-decline',
	ageTitle: 'age-title',
	genderSelect: 'gender-select',
	emailTile: 'email-tile',
	notification: 'notification',
	countrySelect: 'country-select',
	postcodeInput: 'postcode-input',
	subjectSelect: 'subject-select',
	tasksTile: 'tasks-tile',
	phoneTile: 'phone-tile',
	rangeInput: 'range-input',
	scenarioQuestion: 'scenario-question',
	scenarioModalComplete: 'scenario-modal-complete',
	homeScreenBrief: 'home-screen-brief',
	homeScreenBriefNext: 'home-screen-brief-next',
	homeScreenStart: 'home-screen-start',
	resultsDebrief: 'results-debrief',
	setupBack: 'setup-back',
	setupNext: 'setup-next',
	video: 'video',
	modalTitle: 'modal-title',
	modalOkay: 'modal-okay',
	modalPrev: 'modal-prev',
	modalNext: 'modal-next',
	emailFromEmail: 'email-from-email',
	emailFromName: 'email-from-name',
	emailBody: 'email-body',
	emailOkay: 'email-okay',
	incomingCall: 'incoming-call',
	answerCall: 'answer-call',
	incomingCallName: 'call-name',
	incomingCallDesc: 'call-desc',
	answerModalQuestion: 'answer-modal-question',
	answerModalSubmit: 'answer-modal-submit',
	answerModalForm: 'answer-modal-form',
	debriefModalTitle: 'debrief-modal-title',
	debriefModalClose: 'debrief-modal-close',
	homeScreenPageOneText: 'home-screen-pg-one-text',
	homeScreenPageTwoText: 'home-screen-pg-two-text',
	dataCaptureNext: 'data-capture-next',
}