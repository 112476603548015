import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { InputBackground } from '../Containers';

export type SelectComponentProps =
	React.SelectHTMLAttributes<HTMLSelectElement> & {};

const SelectComponent: React.FC<SelectComponentProps> = ({
	children,
	className,
	onChange,
	value,
	placeholder,
	...props
}) => {
	const theme = useTheme();
	const [isSelected, setIsSelected] = React.useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (!isSelected) {
			setIsSelected(true);
		}
		if (onChange) {
			onChange(e);
		}
	};

	return (
		<Wrapper className={className}>
			<select
				onChange={handleChange}
				value={value}
				data-value={value}
				placeholder={placeholder}
				{...props}
			>
				{!!placeholder && (
					<OptionPlaceholder
						css={{
							color: theme.colors.text.placeholder,
						}}
						value=""
						selected
						disabled
					>
						{placeholder}
					</OptionPlaceholder>
				)}
				{children}
			</select>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
				<path
					fill="currentColor"
					d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
				/>
			</svg>
		</Wrapper>
	);
};

const OptionPlaceholder = styled.option`
	&& {
		color: ${({ theme }) => theme.colors.text.placeholder};
	}
`;

const Wrapper = styled.div`
	--pad-sides: ${({ theme }) => theme.spacing[5]};
	--arrow-right: calc(var(--pad-sides) * 0.7);
	--arrow-width: 0.8rem;
	--arrow-offset: calc(var(--arrow-right) + var(--arrow-width) * 1.3);
	position: relative;
	select {
		${InputBackground}
		width: 100%;
		padding-right: var(--arrow-offset);
		border-radius: 10px;
		line-height: 1.25;
		option {
			color: ${({ theme }) => theme.colors.text.dark};
		}
	}

	svg {
		position: absolute;
		top: 50%;
		right: var(--arrow-right);
		transform: translateY(-50%);
		width: var(--arrow-width);
		height: auto;
		color: ${({ theme }) => theme.colors.primary_light};
		pointer-events: none;
	}
`;

export default SelectComponent;
