import React from 'react';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';

import ChartComponent from '../../components/ChartComponent';
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { StatsContext } from '../../contexts/StatsContext';
import { adaptStats } from '../../layouts/DeviceLayout/utils';
import { getStatisticChange, getValence } from '../../utils/statistics';
import { useSelector } from '@xstate/react';

export type PublicTrustTileViewProps = {}

const PublicTrustTileView: React.FC<PublicTrustTileViewProps> = () => {
	const lang = React.useContext(LanguageContext);
	const stats = React.useContext(StatsContext);
	const { gameService } = React.useContext(GameContext);
	const isPublicTrustChartLocked = useSelector(gameService, state => state.context.isPublicTrustLocked);

	const data = adaptStats(stats.historicalPublicTrust);

	return (
		<ChartComponent
			title={lang.publicTrustChart.title}
			toolTip={lang.publicTrustChart.tooltip}
			icon={faUsers}
			type="bar"
			data={data}
			indicator={getStatisticChange(data)}
			valence={getValence(data, 'increaseIsPositive')}
			isLocked={isPublicTrustChartLocked}
		/>
	);
};


export default PublicTrustTileView;
