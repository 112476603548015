import * as React from 'react';
import './wdyr';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CypressHistorySupport } from 'cypress-react-router';
// routing
import ReactDOM from 'react-dom';
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom';

// Hooks
import usePreferReducedMotion from './hooks/usePreferReduceMotion';
import useTabDetection from './hooks/useTabDetection';
import useNotification from './hooks/useNotification';
// contexts
import { StatsProvider } from './contexts/StatsContext';
import { GameProvider } from './contexts/GameContext';
import { ThemeProvider } from 'styled-components';
import { AnalyticsContextProvider } from './contexts/AnalyticsContext';
//screens
import GameScreen from './screens/GameScreen';
import HomeScreen from './screens/HomeScreen';
import ResultsScreen from './screens/ResultsScreen';
// styles
import theme from './theme';
import GlobalStyles from './styles/global';
import { LanguageProvider } from './contexts/LanguageContext';
import { VolumeProvider } from './contexts/VolumeContext/VolumeContext';
import SetupScreen from './screens/SetupScreen';
import { DeviceProvider } from './contexts/DeviceContext';

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn:
			'https://e74db6a2b51644d29c119847013b0f5c@o135041.ingest.sentry.io/5842597',
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

const client = new ApolloClient({
	uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}?access_token=${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
	cache: new InMemoryCache(),
});

/* Accessability audit */
if (process.env.NODE_ENV !== 'production') {
	const axe = require('@axe-core/react');
	axe(React, ReactDOM, 1000);
}

const GlobalHooks: React.FC = ({ children }) => {
	usePreferReducedMotion();
	useTabDetection();
	/* Some browsers require interaction to play sounds
  so we do this at the start to ensure first notification
  sound etc plays without the user having to click */
	useNotification().initializeSound();

	return <>{children}</>;
};

export const App = () => {
	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<AnalyticsContextProvider>
					<LanguageProvider>
						<DeviceProvider>
							<VolumeProvider>
								<StatsProvider>
									<GameProvider>
										<Router>
											<CypressHistorySupport />
											<GlobalHooks>
												<Switch>
													<Route exact path="/" component={HomeScreen} />
													<Route exact path="/setup" component={SetupScreen} />
													<Route exact path="/play" component={GameScreen} />
													<Route
														exact
														path="/results"
														component={ResultsScreen}
													/>
												</Switch>
											</GlobalHooks>
										</Router>
									</GameProvider>
								</StatsProvider>
							</VolumeProvider>
						</DeviceProvider>
					</LanguageProvider>
				</AnalyticsContextProvider>
			</ThemeProvider>
		</ApolloProvider>
	);
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
