import { useSelector } from '@xstate/react';
import React from 'react';
import useSound from 'use-sound';
import { GameContext } from '../../contexts/GameContext';
import { VolumeContext } from '../../contexts/VolumeContext/VolumeContext';
import { hasNotificationSelector } from '../../machines/game/selectors';
import usePrevious from '../usePrevious';

const notifySfx = require('../../assets/audio/notification.wav').default;
const ringSfx = require('../../assets/audio/ring.wav').default;

const defaultTitle = 'Play Game - Trust the Experts';
const defaultFavicon =
	document.getElementById('favicon')?.getAttribute('href') || '';

const useNotification = (type: 'notify' | 'ring' = 'notify') => {
	const ringer = React.useRef<any>(null);
	const { gameService } = React.useContext(GameContext);
	const hasNotification = useSelector(gameService, hasNotificationSelector);
	const [volumeIsActive] = React.useContext(VolumeContext);
	const [playNotification] = useSound(notifySfx);
	const [initializeSound] = useSound(notifySfx, { volume: 0 });
	const [playRing, { stop: stopRing }] = useSound(ringSfx);

	const hadNotification = usePrevious(hasNotification);

	/**
	 * Updates the document's title
	 * @param notifications Number of notifications
	 */
	const updateTitle = (notifications?: number) => {
		const title =
			notifications && notifications > 1
				? `(${notifications} notifications)`
				: `(${notifications} notification)`;
		document.title = notifications ? `${defaultTitle} ${title}` : defaultTitle;
	};

	/**
	 * Updates the document's favicon
	 * @param favicon
	 */
	const updateFavicon = (favicon?: string) => {
		const $favicon = document.getElementById('favicon');
		$favicon?.setAttribute('href', favicon || defaultFavicon);
	};

	/**
	 * Update the title and favicon.
	 * Play audio if volume is enabled.
	 */
	const triggerNotification = React.useCallback(() => {
		updateTitle(1);
		// TODO: Right favicon
		const faviconPath = window.location.origin + '/favicon-notification.ico';
		updateFavicon(faviconPath);

		if (volumeIsActive) {
			if (type === 'notify') {
				playNotification();
			} else {
				playRing();
				ringer.current = setInterval(() => playRing(), 3000);
				setTimeout(() => clearInterval(ringer.current), 7000);
			}
		}
	}, [ringer, playNotification, playRing, volumeIsActive, type]);

	/**
	 * Restore the default title and favicon
	 */
	const clearNotifications = React.useCallback(() => {
		updateTitle(0);
		updateFavicon();
	}, []);

	/**
	 * Stops any ongoing sound
	 * Clears the ringing interval if applicable
	 */
	const stopSound = () => {
		stopRing();

		if (type === 'ring') {
			clearInterval(ringer.current);
		}
	};

	/**
	 * Restore title/favicon defaults once notifications have been addressed
	 */
	React.useEffect(() => {
		if (!hasNotification && hadNotification) {
			clearNotifications();
		}
	}, [hasNotification, hadNotification, clearNotifications]);

	return {
		initializeSound,
		triggerNotification,
		clearNotifications,
		stopSound,
	} as const;
};

export default useNotification;
