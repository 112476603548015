import React from 'react';
import {
	Dot as _Dot,
	DotGroup as _DotGroup,
	DotGroupProps,
} from 'pure-react-carousel';
import styled from 'styled-components';
import { LanguageContext } from '../../contexts/LanguageContext';

type DotsProps = DotGroupProps & {
	handleClick?: (i: number) => void;
	isHollow?: boolean;
	slides: number;
};

export const Dots: React.FC<DotsProps> = ({
	slides,
	isHollow,
	handleClick,
}) => {
	const lang = React.useContext(LanguageContext);

	return(
		<DotGroup
			renderDots={({ currentSlide }) =>
				Array(slides)
					.fill(null)
					.map((_, i) => (
						<Dot
							aria-label={`${lang.slide} ${i + 1}`}
							aria-pressed={i === currentSlide}
							slide={i}
							$isHollow={!!isHollow}
							$isActive={i === currentSlide}
							key={`dot-${i}`}
							onClick={() => handleClick && handleClick(i)}
						/>
					))
			}
		/>
	);
}

const DotGroup = styled(_DotGroup)`
	display: flex;
	justify-content: center;
	padding-top: ${({ theme }) => theme.spacing.md};
`;

type DotProps = {
	$isActive: boolean;
	$isHollow?: boolean;
};
export const Dot = styled(_Dot)<DotProps>`
	background-color: ${({ $isActive, theme }) =>
		$isActive ? theme.colors.primary : theme.colors.whiteish};
	width: 10px;
	height: 10px;
	border-radius: ${({ theme }) => theme.radii.round};
	border: 1px solid
		${({ $isActive, $isHollow, theme }) => {
			if (!$isHollow && !$isActive) {
				return theme.colors.whiteish;
			}

			return theme.colors.primary;
		}};
	padding: 0;
	transition: background-color 0.25s;
	transition-timing-function: ease-in;

	+ * {
		margin-left: ${({ theme }) => theme.spacing[3]};
	}
`;
