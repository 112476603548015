import React from 'react';
import _Slider from 'react-slider';
import { Circle } from '../../components/Containers';
import { SliderLabel } from '../../components/Typography';
import styled, { css } from 'styled-components';
import { testIds } from '../../utils/testing';

export type SliderComponentProps = {
	a11yLabel: string;
	a11yValueLabel: string;
	className?: string;
	initial?: number;
	min?: number;
	max?: number;
	onChange?: (current: number) => void;
};

/** Slider component.
 * @prop initial (number), initial value of the slider
 * @prop onChange (val: number) => void, called after slider is dropped with current value
 */

const SliderComponent: React.FC<SliderComponentProps> = ({
	a11yLabel,
	a11yValueLabel,
	className,
	onChange,
	initial = 16,
	min = 0,
	max = 100,
}) => {
	// track Thumb active status
	const [isActive, setIsActive] = React.useState(false);

	const renderThumb = (
		props: any, 
		state: {
			index: number;
			value: number | readonly number[];
			valueNow: number;
		}
	) => (
		<>
			<Thumb
				{...props}
				isActive={isActive}
				onMouseDown={() => setIsActive(true)}
				data-testid={testIds.rangeInput}
			/>
			<Label
				{...props}
				key={`thumb-label-${props.index}`}
			>
				{state.valueNow}
			</Label>
		</>
	);

	const renderTrack = (props: unknown, state: { index: number }) => (
		<Track {...props} index={state.index} />
	);

	const handleChange = (current: number | readonly number[]) => {
		setIsActive(false);
		// satisfy the ever thirsty ts compiler
		const val = Array.isArray(current) ? current[0] : current;
		if (onChange) {
			onChange(val);
		}
	};

	return(
		<Slider
			ariaLabel={a11yLabel}
			ariaValuetext={(state: { valueNow: number }) =>
				`${a11yValueLabel} ${state.valueNow}`
			}
			className={className}
			defaultValue={[initial]}
			min={min}
			max={max}
			renderThumb={renderThumb}
			renderTrack={renderTrack}
			onAfterChange={handleChange}
		/>
	);
};

const Slider = styled(_Slider)`
	width: 100%;
	height: 0.625rem;
	border-radius: ${({ theme }) => theme.radii.md};
`;

const TrackLHS = css`
	background: -moz-linear-gradient(
		90deg,
		rgba(147, 201, 223, 1) 0%,
		rgba(202, 212, 0, 1) 100%
	);
	background: -webkit-linear-gradient(
		90deg,
		rgba(147, 201, 223, 1) 0%,
		rgba(202, 212, 0, 1) 100%
	);
	background: linear-gradient(
		90deg,
		rgba(147, 201, 223, 1) 0%,
		rgba(202, 212, 0, 1) 100%
	);
`;

const TrackRHS = css`
	background: #eff4f8;
	box-shadow: inset 0 1px 4px 0 rgba(1, 14, 60, 0.1);
`;

const Track = styled.div<{ index: number }>`
	height: 0.625rem;
	border-radius: ${({ theme }) => theme.radii.md};
	${(props) => (props.index === 0 ? `${TrackLHS}` : `${TrackRHS}`)};
`;

const Thumb = styled(Circle)<{ isActive: boolean }>`
	width: 1.125rem;
	top: -0.225rem;
	border: unset;
	font-size: ${({ theme }) => theme.fontSize.xxs};
	background-color: ${({ theme }) => theme.colors.primary_light};
	cursor: grab;
	box-shadow: ${({ theme }) => theme.shadows.sliderThumb};
	transform: ${({ isActive }) => (isActive ? `scale(1.1)` : `scale(1)`)};
`;

const Label = styled(SliderLabel)`
	top: 1rem;
	color: ${({ theme }) => theme.colors.primary};
	// render at least two 1.75 characters size
	// helps the label remain centered
	min-width: 1.75ch;
	text-align: center;
`;

export default SliderComponent;
