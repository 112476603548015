import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
// components
import _Tile from '../TileComponent';
import {
	CalendarHeading as _CalendarHeading,
} from '../Typography';
// assets
import { 
	springPng,
	springWebp,
	springAvif,
	summerPng,
	summerWebp,
	summerAvif,
	autumnPng,
	autumnWebp,
	autumnAvif,
	winterPng,
	winterWebp,
	winterAvif,
} from '../../assets';
// types
import { SeasonId, DeviceTypeId } from '../../types';

const images = {
	spring: {
		avif: springAvif,
		webp: springWebp,
		png: springPng,
	},
	summer: {
		avif: springAvif,
		webp: springWebp,
		png: springPng,
	},
	autumn: {
		avif: springAvif,
		webp: springWebp,
		png: springPng,
	},
	winter: {
		avif: springAvif,
		webp: springWebp,
		png: springPng,
	},
}

export type CalendarComponentProps = {
	date: string | undefined;
};

const CalendarComponent: React.FC<CalendarComponentProps> = ({
	date,
}) => {
	// months are 0 indexed (eww!) so we + 1
	// to give months their familiar numbering
	// so they are easier to reason about
	const month: number = dayjs(date).month() + 1;

	const season: SeasonId = (() => {
		if ([12, 1, 2].includes(month)) return 'winter';
		if ([3, 4, 5].includes(month)) return 'spring';
		if ([6, 7, 8].includes(month)) return 'summer';
		if ([9, 10, 11].includes(month)) return 'autumn';
		// default
		console.error('Unable to determine season at calendar component.');
		return 'spring';
	})();

	const imgSources = (() => {
		if (season === 'autumn') return images.autumn;
		if (season === 'winter') return images.winter;
		if (season === 'spring') return images.spring;
		if (season === 'summer') return images.summer;
		// default
		console.error('No season passed to CalendarComponent.');
		return images.summer;
	})();

	const copy = {
		day: dayjs(date).format('DD'),
		month: dayjs(date).format('MMMM'),
	};

	return (
		<Tile>
			<picture>
				<source srcSet={imgSources.avif} type="image/avif" />
				<source srcSet={imgSources.webp} type="image/webp" />
				<Image 
					srcSet={imgSources.png}
					src={imgSources.png} 
					alt="" 
				/>
			</picture>
			{ date && (
				<CalendarHeading as="p">
					<span>{copy.day}</span>
					{copy.month}
				</CalendarHeading>
			) }
		</Tile>
	);
};

const Tile = styled(_Tile)`
	position: relative;
	padding: 0;
	overflow: hidden;
	justify-content: flex-end;
	padding: ${({ theme }) => theme.spacing[6]};
`;

const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: inherit;
	z-index: -1;
`;

const CalendarHeading = styled(_CalendarHeading)`
	line-height: 1.25;
	letter-spacing: initial;

	span {
		display: table;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		font-size: 1.625rem;
	}
`;


export default CalendarComponent;
