import React from 'react';

// components
import SelectInputLayout from '../SelectInputLayout';
import Input from '../../components/InputComponent';
import Select from '../../components/SelectComponent';
// contexts
import { LanguageContext } from '../../contexts/LanguageContext';
// utils
import { stripCommas } from '../../utils/stripCommas';
// data
import { testIds } from '../../utils/testing';

type GenderLayoutValue =
	| 'Male'
	| 'Female'
	| 'Prefer not to say'
	| 'Prefer to self describe';

export type GenderLayoutProps = {
	onChange: (value?: string) => void;
	value?: string;
};

const GenderLayout: React.FC<GenderLayoutProps> = ({ onChange, value }) => {
	const copy = React.useContext(LanguageContext);
	const [gender, setGender] = React.useState<string | undefined>(
		value || undefined
	);

	const determineIsSelfDescribed = React.useCallback(
		(gender?: string) => !!(gender && gender === 'Prefer to self describe'),
		[copy]
	);

	const [isSelfDescribed, setIsSelfDescribed] = React.useState(
		determineIsSelfDescribed(gender)
	);

	React.useEffect(() => {
		const result = determineIsSelfDescribed(gender);
		if (isSelfDescribed !== result) {
			setIsSelfDescribed(result);
		}
	}, [gender, isSelfDescribed, determineIsSelfDescribed]);

	const [selfDescribedGender, setSelfDescribedGender] = React.useState<
		string | undefined
	>(isSelfDescribed ? value : undefined);

	const handleSelectGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
		let val = e.target.value as GenderLayoutValue;

		setGender(val);
		if (val !== 'Prefer to self describe') {
			/* Only update if not self describe */
			onChange(val);
		}
	};

	const handleSelfDescribe = (val: string) => {
		setIsSelfDescribed(true);
		setSelfDescribedGender(val);
		onChange(val);
	};

	/* Reset in case of navigate back etc */
	React.useEffect(() => {
		if (!selfDescribedGender && gender !== copy.preferToSelfDescribe) {
			setSelfDescribedGender(undefined);
		}
	}, [gender, selfDescribedGender, copy.preferToSelfDescribe]);

	return (
		<SelectInputLayout>
			<Select
				data-testid={testIds.genderSelect}
				id="gender-select"
				onChange={handleSelectGender}
				value={isSelfDescribed ? copy.preferToSelfDescribe : gender}
				placeholder={copy.selectGender}
			>
				<option value="Female">{`${copy.Female}`}</option>
				<option value="Male">{`${copy.Male}`}</option>
				<option value="Prefer not to say">{`${copy.PreferNotToSay}`}</option>
				<option value="Prefer to self describe">{`${copy.preferToSelfDescribe}`}</option>
			</Select>
			{isSelfDescribed && (
				<Input
					data-testid={testIds.genderSelect}
					placeholder={copy.inputGenderPlaceholder}
					onChange={handleSelfDescribe}
					value={selfDescribedGender ?? ''}
					parser={stripCommas}
				/>
			)}
		</SelectInputLayout>
	);
};

export default GenderLayout;
