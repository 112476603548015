import { faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from '@xstate/react';
import React from 'react';
import ChartComponent from '../../components/ChartComponent';
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { StatsContext } from '../../contexts/StatsContext';
import { adaptStats } from '../../layouts/DeviceLayout/utils';
import { getStatisticChange, getValence } from '../../utils/statistics';

export type EconomyTileViewProps = {}

const EconomyTileView: React.FC<EconomyTileViewProps> = (props) => {
	const lang = React.useContext(LanguageContext);
	const stats = React.useContext(StatsContext);
	const { gameService } = React.useContext(GameContext);
	const isEconomyChartLocked = useSelector(gameService, (state) => state.context.isEconomyLocked);

	const data = adaptStats(stats.historicalEconomy);

	return (
		<ChartComponent
			title={lang.economyChart.title}
			toolTip={lang.economyChart.tooltip}
			icon={faSackDollar}
			type="area"
			data={data}
			indicator={getStatisticChange(data)}
			valence={getValence(data, 'increaseIsPositive')}
			isLocked={isEconomyChartLocked}
		/>
	);
};


export default EconomyTileView;
