import styled, {
	FlattenInterpolation,
	ThemeProps,
	DefaultTheme,
	StyledComponent,
	css,
} from 'styled-components/macro';
import { Size, Tag, Weight, Variant, Color, Tracking } from './utils';

/** Utility for creating typography styles.
 *
 * To make a new typography style:
 *
 * (1) pass a tag (e.g., Tag.Paragraph, Tag.Heading) as the first parameter,
 *  alternatively pass any styled component.
 *
 * (2) pass any other styles declared using the styled 'css' function as the rest params
 *
 *  @example
 *  export const HugeBoldHeading = createTypography(Tag.Heading, Size.Huge, Weight.Bold);
 *  export const HeadingBoldSC = createTypography(Tag.Heading, Weight.Bold, Variant.SmallCaps);
 *  export const HeadingLight = createTypography(Tag.Heading, Color.LightText);
 */

const createTypography = (
	Tag: StyledComponent<any, DefaultTheme>,
	...styles: FlattenInterpolation<ThemeProps<DefaultTheme>>[]
) => {
	return styled(Tag)`
		${[...styles]}
	`;
}

// Generics
/* Typography that appears in multiple places are defined here */

export const Paragraph = createTypography(
	Tag.Paragraph,
	Weight.SemiLight,
	Size.Medium
);
export const ParagraphLight = createTypography(Tag.Paragraph, Color.Light);
export const ParagraphSemiBold = createTypography(
	Tag.Paragraph,
	Weight.SemiBold
);
const ParagraphLightSmall = createTypography(ParagraphLight, Size.Small);
const ParagraphLightLarge = createTypography(ParagraphLight, Size.Large);
const LargeHeading = createTypography(Tag.Heading, Size.Large);
const HugeWideHeading = createTypography(Tag.Heading, Size.Huge, Tracking.Wide);
export const ParagraphWide = createTypography(
	Tag.Paragraph,
	Size.Medium,
	Tracking.Wide,
	Weight.Bold
);
export const HugeParagraph = createTypography(
	Tag.Paragraph,
	Size.Huge,
	Weight.Bold
);
export const EnormousParagraph = createTypography(
	Tag.Paragraph,
	Size.Enormous,
	Weight.Bold
);

// Typography that are passed to components are defined here,
// either based on generics above or custom declaration

// Headings
export const CalendarHeading = HugeWideHeading;
export const CalendarHeadingBold = createTypography(
	HugeWideHeading,
	Weight.Bold,
	Tracking.Regular
);
export const SimpleTileHeading = createTypography(
	HugeWideHeading,
	Weight.Regular,
	Tracking.Regular
);
export const TileTitleHeading = createTypography(
	Tag.Heading,
	Weight.Bold,
	Tracking.Wide,
	Size.Small,
	Variant.UpperCase
);
export const CapsTitle = createTypography(
	Tag.Heading,
	Weight.ExtraBold,
	Tracking.Wide,
	Size.Medium,
	Variant.UpperCase
);

// Paragraphs
export const ListItemCopy = Paragraph;
export const HashTagCopyDesktop = ParagraphLightLarge;
export const HashTagCopyMobile = ParagraphLightSmall;
export const ToolTipCopy = ParagraphLight;
export const VolumeControlCopy = ParagraphLight;
export const ButtonCopy = Paragraph;
export const Heading = Paragraph;
export const ScenarioSlideButtonCopy = createTypography(
	Tag.Paragraph,
	Size.ExtraSmall,
	Tracking.Wide,
	Weight.ExtraBold,
	Variant.UpperCase
);
export const ScenarioCarouselHeading = createTypography(
	Tag.Paragraph,
	Size.Small,
	Tracking.Wide,
	Weight.ExtraBold,
	Variant.UpperCase
);
export const ScenarioSlideCopy = Paragraph;
export const ErrorCopy = LargeHeading;
export const ScenarioSlideHeaderCopy = createTypography(
	Tag.Heading,
	Weight.Bold,
	Tracking.Wide,
	Size.Large
);
export const AdviceHeading = createTypography(
	Tag.Paragraph,
	Size.Large,
	Tracking.Wide,
	Weight.ExtraBold
);
export const SliderLabel = createTypography(
	Tag.Label,
	Size.Medium,
	Weight.SemiLight
);
export const SetupText = createTypography(
	Tag.Paragraph,
	Size.Huge,
	Weight.SemiBold,
	css`
		margin-bottom: 16px;
	`
);
// Markdown mappings
export const MarkdownH1 = createTypography(
	Tag.Title,
	Size.Huge,
	Weight.ExtraBold
);
export const MarkdownH2 = createTypography(
	Tag.Heading,
	Size.ExtraLarge,
	Weight.Bold
);
export const MarkdownH3 = createTypography(
	Tag.Subheading,
	Size.Large,
	Weight.Bold
);
export const MarkdownH4 = createTypography(
	Tag.Subheading,
	Size.Large,
	Weight.SemiBold
);
export const MarkdownP = createTypography(Tag.Paragraph, Size.Medium);









// import styled, {
// 	FlattenInterpolation,
// 	ThemeProps,
// 	DefaultTheme,
// 	StyledComponent,
// 	css,
// } from 'styled-components/macro';
// import { Size, Tag, Weight, Variant, Color, Tracking } from './utils';

// /** Utility for creating typography styles.
//  *
//  * To make a new typography style:
//  *
//  * (1) pass a tag (e.g., Tag.Paragraph, Tag.Heading) as the first parameter,
//  *  alternatively pass any styled component.
//  *
//  * (2) pass any other styles declared using the styled 'css' function as the rest params
//  *
//  *  @example
//  *  export const HugeBoldHeading = styled.p``;(Tag.Heading, Size.Huge, Weight.Bold);
//  *  export const HeadingBoldSC = styled.p``;(Tag.Heading, Weight.Bold, Variant.SmallCaps);
//  *  export const HeadingLight = styled.p``;(Tag.Heading, Color.LightText);
//  */

// // const styled.p``; = (
// // 	Tag: StyledComponent<any, DefaultTheme>,
// // 	...styles: FlattenInterpolation<ThemeProps<DefaultTheme>>[]
// // ) =>
// // 	styled(Tag)`
// // 		${[...styles]}
// // 	`;

// // Generics
// /* Typography that appears in multiple places are defined here */

// export const Paragraph = styled.p``;
// export const ParagraphLight = styled.p``;
// export const ParagraphSemiBold = styled.p``;
// const ParagraphLightSmall = styled.p``;
// const ParagraphLightLarge = styled.p``;
// const LargeHeading = styled.p``;
// const HugeWideHeading = styled.p``;
// export const ParagraphWide = styled.p``;
// export const HugeParagraph = styled.p``;
// export const EnormousParagraph = styled.p``;

// // Typography that are passed to components are defined here,
// // either based on generics above or custom declaration

// // Headings
// export const CalendarHeading = HugeWideHeading;
// export const CalendarHeadingBold = styled.p``;
// export const SimpleTileHeading = styled.p``;
// export const TileTitleHeading = styled.p``;
// export const CapsTitle = styled.p``;

// // Paragraphs
// export const ListItemCopy = Paragraph;
// export const HashTagCopyDesktop = ParagraphLightLarge;
// export const HashTagCopyMobile = ParagraphLightSmall;
// export const ToolTipCopy = ParagraphLight;
// export const VolumeControlCopy = ParagraphLight;
// export const ButtonCopy = Paragraph;
// export const Heading = Paragraph;
// export const ScenarioSlideButtonCopy = styled.p``;
// export const ScenarioCarouselHeading = styled.p``;
// export const ScenarioSlideCopy = Paragraph;
// export const ErrorCopy = LargeHeading;
// export const ScenarioSlideHeaderCopy = styled.p``;
// export const AdviceHeading = styled.p``;
// export const SliderLabel = styled.p``;
// export const SetupText = styled.p``;
// // Markdown mappings
// export const MarkdownH1 = styled.p``;
// export const MarkdownH2 = styled.p``;
// export const MarkdownH3 = styled.p``;
// export const MarkdownH4 = styled.p``;
// export const MarkdownP = styled.p``;