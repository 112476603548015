import React from 'react';
import styled from 'styled-components';

import { Container } from '../../components/Containers';
import DeviceLayout from '../../layouts/DeviceLayout';
import GameHeaderLayout from '../../layouts/GameHeaderLayout';
import { wavyShape } from '../../assets';
import SentimentView from '../../views/SentimentView';
import { DeviceContext } from '../../contexts/DeviceContext';
import { DeviceTypeId } from '../../types';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { fadeIn } from '../../styles/common';
import { testIds } from '../../utils/testing';

export type GameScreenProps = {};

const GameScreen: React.FC<GameScreenProps> = (props) => {
	useSetDocumentTitle('Play Game - Trust the Experts Game');
	const { device } = React.useContext(DeviceContext);

	return (
		<Wrapper 
			data-testid={testIds.gameScreen}
			device={device}
		>
			<GameHeaderLayout 
				withoutColor={device === 'mobile'} 
				withoutLogo={device === 'mobile'} 
			/>
			<Main 
				as="main"
				device={device}
			>
				<DeviceLayout />
			</Main>

			<SentimentView />
		</Wrapper>
	);
};

type MainProps = {
	device: DeviceTypeId;
};
const Main = styled(Container)<MainProps>`
	display: flex;
	${({ device }) => {
		if (device === 'mobile')
			return `
			padding: 0;
		`;
	}};
`;

type WrapperProps = {
	device: DeviceTypeId;
};
const Wrapper = styled.div<WrapperProps>`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 3.4375rem minmax(580px, 1fr);
	grid-gap: ${({ theme, device }) =>
		device === 'mobile' ? 0 : theme.spacing[6]};
	height: 100vh;
	min-height: 48rem;
	padding-bottom: ${({ theme }) => theme.spacing[12]};
	background-image: url(${wavyShape});
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: auto 79%;
	${fadeIn}
`;

export default GameScreen;
