import { useSelector } from '@xstate/react';
import React from 'react';

import SimpleTile from '../../components/SimpleTileComponent';
import { DeviceContext } from '../../contexts/DeviceContext';
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import useNotification from '../../hooks/useNotification/useNotification';
import { testIds } from '../../utils/testing';
import TextAdviceView from '../TextAdviceView';

export type EmailTileViewProps = {};

const EmailTileView: React.FC<EmailTileViewProps> = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const lang = React.useContext(LanguageContext);
	const { gameService } = React.useContext(GameContext);
	const hasNewEmail = useSelector(gameService, state => state.context.hasNewEmail);
	const { device } = React.useContext(DeviceContext);
	const { triggerNotification } = useNotification();
	const [triggered, setTriggered] = React.useState(false);

	React.useEffect(() => {
		setTriggered(hasNewEmail);
	}, [hasNewEmail]);

	React.useEffect(() => {
		if (!hasNewEmail || triggered) return;
		triggerNotification();
		setTriggered(true);
	}, [hasNewEmail, triggerNotification, triggered]);

	return (
		<>
			<SimpleTile
				title={lang.tileTitle.email}
				icon="Email"
				data-testid={testIds.emailTile}
				handleClick={() => setIsOpen(true)}
				hasIndicator={hasNewEmail}
				device={device}
			/>

			<TextAdviceView
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};

export default EmailTileView;
