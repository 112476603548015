import { State } from "xstate";
import { GameContext, GameEvent, GameState } from "./types";

type MachineState = State<GameContext, GameEvent, any, GameState>

export const hasNotificationSelector = (state: MachineState) => {
	const {
		hasNewEmail,
		hasNewQuestion,
		hasNewTask,
		hasNewVideo,
		hasNewVoicemail
	} = state.context;

	return hasNewEmail || hasNewQuestion || hasNewTask || hasNewVideo || hasNewVoicemail;
}

export const currentRoundSelector = (state: MachineState) => state.context.roundIndex + 1;