import React from 'react';
import styled from "styled-components";

import ButtonComponent from '../../components/ButtonComponent';
import ModalComponent, { ModalButtons, ModalComponentProps, ModalContent } from '../../components/ModalComponent';
import RadioSelectComponent from '../../components/RadioSelectComponent';
import { Paragraph } from '../../components/Typography';
import { LanguageContext } from '../../contexts/LanguageContext';
import { Answer } from '../../types';
import { testIds } from '../../utils/testing';
import CentralTextLayout from '../CentralTextLayout';

export type AnswerLayoutProps = Pick<ModalComponentProps, 'isOpen' | 'onClose'> & {
	answers?: Answer[];
	onSubmit: (answer: Answer) => void;
	question?: string;
	title: string;
}

const AnswerLayout: React.FC<AnswerLayoutProps> = ({ isOpen, onClose, onSubmit, question, answers, title }) => {
	const [value, setValue] = React.useState<string>();
	const lang = React.useContext(LanguageContext);

	React.useEffect(() => {
		setValue(undefined);
	}, [isOpen]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		const answer = answers?.find(item => item.answer === value);

		if(!value || !answer) return;

		onSubmit(answer);
	}

	return (
		<Modal
			hasWaveBackground
			contentLabel={title}
			isOpen={isOpen} 
			onClose={onClose}
		>
			<Wrapper>
				<CentralTextLayout title={title}>
					<Question data-testid={testIds.answerModalQuestion}>{question}</Question>
					<Paragraph>{lang.preAnswer}</Paragraph>
				</CentralTextLayout>

				<form 
					onSubmit={handleSubmit}
					data-testid={testIds.answerModalForm}
				>
					<RadioSelectComponent 
						value={value}
						onChange={v => setValue(v)}
						name={'scenario1'}
						options={answers?.map(item => item.answer)}
					/>

					<ModalButtons>
						<ButtonComponent data-testid={testIds.answerModalSubmit} disabled={!value} copy={lang.submit} />
					</ModalButtons>
				</form>
			</Wrapper>
		</Modal>
	);
};

const Modal = styled(ModalComponent)`
	${ModalContent} {
		--wave-nudge: 0;
	}
`;

const Question = styled(Paragraph)`
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	font-size: ${({ theme }) => theme.fontSize.xxl};
`;

const Wrapper = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing[6]};

	form {
		padding-top: ${({ theme }) => theme.spacing[9]};
	}
`;

export default AnswerLayout;