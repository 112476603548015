export const greys = {
	100: '#FFFFFF',
	200: '#F1F3F4',
	300: '#CCCCCC',
	400: '#636669',
	500: '#333333',
	600: '#262626',
	700: '#191919',
	800: '#0C0C0C',
	900: '#000000',
};

export const purples = {
	100: '#E7E0EB',
	200: '#D4B8E0',
	300: '#B27EC8',
	400: '#8E49AB',
	500: '#763D8F',
	600: '#553172',
	700: '#472556',
	800: '#3B1F47',
	900: '#23122B',
};

export const oranges = {
	100: '#FDEEED',
	200: '#FFC6C2',
	300: '#FFB3AE',
	400: '#FE8D85',
	500: '#FE5F55',
	600: '#FE1C0B',
	700: '#B70D01',
	800: '#7A0901',
	900: '#3D0000',
};

export const blues = {
	200: '#DEE6ED',
	400: '#242F60',
	500: '#182044',
};

// These colours are for errors
export const reds = {
	100: '#FFE7E6',
	400: '#FE5F55',
	600: '#EB4132',
};

// These colours are for info
export const yellows = {
	100: '#FEF6E7',
	400: '#F6AE2D',
};

// These colours are for success
export const greens = {
	100: '#F6FFED',
	400: '#B7EB8F',
};
