import * as React from 'react';

type FocusElement = {
	focus: () => void;
};

/** Passed an array of dependencies, returns a ref that will
 * trigger a focus event on the referent only if the passed
 *  dependencies change. Helps to manage focus for screen reader users.
 *  @example
 *  // any element referred to by 'a11yfocus'
 *  // will update when 'page' or 'content' updates.
 *  const a11yfocus = useA11yFocus([page, content]);
 */

export const useA11yFocus = function <T extends FocusElement = HTMLElement>(
	deps: unknown[] = []
): React.RefObject<T> {
	const a11yfocus = React.useRef<T>(null);
	/* Focus on important element, so screen 
  readers don't get lost when content changes. */
	React.useEffect(() => {
		if (a11yfocus && a11yfocus.current) {
			a11yfocus.current.focus();
		}
	}, deps);
	return a11yfocus;
};

export default useA11yFocus;
