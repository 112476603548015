import styled from 'styled-components';

import { sceneClouds, whiteClouds } from '../../assets/index';

type CloudsProps = {
	isWhite?: boolean;
}
const Clouds = styled.div<CloudsProps>`
	--clouds-width: 5760px;
	--clouds-height: 818px;
	--clouds-duration: 85s;
	width: var(--clouds-width);
	height: var(--clouds-height);
	z-index: 3;
	background-image: url(${({ isWhite }) => isWhite ? whiteClouds : sceneClouds});
	background-size: 100%;
	background-position: left top;
	background-repeat: no-repeat;
	animation-name: loopbg;
	animation-iteration-count: infinite;
	animation-duration: var(--clouds-duration);
	animation-timing-function: linear;

	@keyframes loopbg {
		from {
			background-position: left top;
		}
		to {
			background-position: calc((var(--clouds-width) / 2) * -1) top;
		}
	}

	@media (prefers-reduced-motion) {
		animation: none;
	}
`;

export default Clouds;