import { DefaultTheme } from 'styled-components';
export type { LanguageType } from '../contexts/LanguageContext';
// Global types (types not confined to one component) go here

export type StatisticChangeId = 'increase' | 'decrease' | 'noChange';

export type StatisticChangeValenceId = 'positive' | 'negative' | 'neutral';

export type SeasonId = 'summer' | 'winter' | 'autumn' | 'spring';

export type StatisticIncreaseValenceMappingId =
	| 'increaseIsPositive'
	| 'increaseIsNegative';

export type StyledTheme = { theme: DefaultTheme };
// TODO: These are placeholders, replace with actual
// sentiments when known.
export type SentimentId =
	| 'happy'
	| 'unhappy'
	| 'sad'
	| 'angry'
	| 'unsure'
	| 'neutral';

export type ChartDatum = {
	percent: number;
	name: string;
};

export type ChartData = ChartDatum[];
export type LanguageId = 'en' | 'cy';

export type ChartPercentageLabelId = 'FULL' | "SATISFIED";

export type DeviceTypeId = 'desktop' | 'mobile';

export enum ExpertAdviceType {
	Audio = 'Audio',
	Video = 'Video',
	Text = 'Text',
}

// TODO: Should this be in global types?
export type GetQuestionsQuery = {
	allQuestions: {
		items: Array<{
			title: string;
			order: number;
			senario: string;
			senarioDate: string;
			answers: {
				items: Array<{
					title: string;
					answer: string;
					sentiment: SentimentId;
					socialHashtags: string[];
					economyMinimum: number;
					economyMaximum: number;
					healthMinimum: number;
					healthMaximum: number;
					wellbeingMinimum: number;
					wellbeingMaximum: number;
					publicTrustMinimum: number;
					publicTrustMaximum: number;
				}>;
			};
			advice: {
				items: Array<{
					title: string;
					type: ExpertAdviceType;
					expertsName: string;
					expertsEmail?: string;
					expertsDescription: string;
					link?: string;
					copy?: string;
					emailSubject?: string;
				}>;
			};
		}>;
	};
};

export type GetQuestionsQueryVariables = {
	locale: string;
};

export type Question = GetQuestionsQuery['allQuestions']['items'][0];
export type ExpertAdvice = GetQuestionsQuery['allQuestions']['items'][0]['advice']['items'][0];
export type Answer = Question['answers']['items'][0];

export type Gender = 'male' | 'female' | 'nonbinary' | 'prefernottosay';

/** Final results displayed after playthrough.*/

export type Results = {
	economy: number;
	healthcare: number;
	publicOpinion: number;
	sentiment: SentimentId;
	wellbeing: number;
	chanceOfReelection: number;
};

export type Valences = {
	economy: StatisticChangeValenceId;
	healthcare: StatisticChangeValenceId;
	publicOpinion: StatisticChangeValenceId;
	wellbeing: StatisticChangeValenceId;
	chanceOfReelection: StatisticChangeValenceId;
};
