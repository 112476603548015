import { useSelector } from '@xstate/react';
import React from 'react';

import { GameContext } from '../../contexts/GameContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import AnswerLayout from '../../layouts/AnswerLayout';
import { currentRoundSelector } from '../../machines/game/selectors';

export type AnswerViewProps = {
	isOpen: boolean;
	onClose: () => void;
};

const AnswerView: React.FC<AnswerViewProps> = ({ isOpen, onClose }) => {
	const { onAnswerChosen, gameService } = React.useContext(GameContext);
	const { answerQuestion } = React.useContext(AnalyticsContext);
	const currentQuestion = useSelector(
		gameService,
		(state) => state.context.currentQuestion
	);
	const currentRound = useSelector(gameService, currentRoundSelector);

	return (
		<AnswerLayout
			isOpen={isOpen}
			onSubmit={(answer) => {
				onAnswerChosen(answer);
				answerQuestion(answer.title);
				onClose();
			}}
			title={`Scenario ${currentRound}`}
			question={currentQuestion?.title}
			answers={currentQuestion?.answers.items}
			onClose={onClose}
		/>
	);
};

export default AnswerView;
