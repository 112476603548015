import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatisticChangeId, StatisticChangeValenceId } from '../../types';
import {
	faArrowUp,
	faArrowDown,
	faHorizontalRule,
} from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from 'styled-components';

export type IndicatorComponentProps = {
	change: StatisticChangeId;
	className?: string;
	valence: StatisticChangeValenceId;
};

const IndicatorComponent: React.FC<IndicatorComponentProps> = ({
	change,
	className,
	valence,
}) => {
	const theme = useTheme();

	const color = (() => {
		if (valence === 'negative') return theme.colors.danger;
		if (valence === 'positive') return theme.colors.success;
		if (valence === 'neutral') return theme.colors.text.light;
		// default
		return theme.colors.text.light;
	})();

	const icon = (() => {
		if (change === 'increase') return faArrowUp;
		if (change === 'decrease') return faArrowDown;
		if (change === 'noChange') return faHorizontalRule;
		// default
		return faHorizontalRule;
	})();

	return icon && <FontAwesomeIcon className={className} color={color} icon={icon} />;
};

export default IndicatorComponent;
