import React from 'react';
import styled from "styled-components";
import { LanguageContext } from '../../contexts/LanguageContext';

import { Paragraph } from '../Typography';


export type EmptyInboxComponentProps = {
	type: 'email' | 'phone';
}

const EmptyInboxComponent: React.FC<EmptyInboxComponentProps> = ({ type }) => {
	const lang = React.useContext(LanguageContext);

	return (
		<Wrapper>
			{ type === 'email' && (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196 165"><defs><linearGradient x1="15.964%" y1="14.896%" x2="89.089%" y2="81.681%" id="prefix__a"><stop stopColor="#C9BAE6" offset="0%" /><stop stopColor="#93C9DF" offset="100%" /></linearGradient></defs><g transform="translate(2)" fill="none" fillRule="evenodd"><circle fill="url(#prefix__a)" opacity={0.75} cx={91.5} cy={82.5} r={82.5} /><path d="M132 26.744L142.008 19l10.69 7.305L162.024 19l10.546 7.43L182.642 19 192 27.308m-60 16.436L142.008 36l10.69 7.305L162.024 36l10.546 7.43L182.642 36 192 44.308" stroke="#969BB2" strokeWidth={5} /><path d="M0 154.149s13.273 5.225 14.311-10.22c1.038-15.444 7.48-11.366 14.883-10.628 7.403.739 16.031-1.397 16.14-12.301" stroke="#969BB2" strokeWidth={6} /><path d="M91 94c3.172.055 7.738-3.992 10.035-5.66 14.151-10.27 18.105-13.228 21.144-15.59l.233-.18.23-.179.227-.178.34-.266.228-.179.114-.09.23-.18.117-.091.235-.185.609-.478A3.272 3.272 0 00126 68.16v-2.598A6.564 6.564 0 00119.437 59H62.562A6.564 6.564 0 0056 65.562v2.598c0 1.012.465 1.955 1.258 2.584l.96.751.23.18.683.534.228.177.345.269.234.181.238.184c3.003 2.323 7.182 5.445 20.789 15.32C83.262 90.008 87.828 94.055 91 94zm28.437 17.5a6.564 6.564 0 006.563-6.563V76.978c0-.67-.793-1.066-1.326-.642-3.063 2.379-7.11 5.414-21.055 15.545-2.775 2.023-7.738 6.549-12.62 6.508-4.853.027-9.72-4.403-12.605-6.508C65.69 82.65 61.19 79.32 58.173 77.004l-.218-.168-.214-.165-.21-.162-.205-.16a.818.818 0 00-1.326.643v27.945a6.564 6.564 0 006.562 6.563h56.875z" fill="#FFF" fillRule="nonzero" /></g></svg>
			) }
			{ type === 'phone' && (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196 165"><defs><linearGradient x1="15.964%" y1="14.896%" x2="89.089%" y2="81.681%" id="prefix__a"><stop stopColor="#C9BAE6" offset="0%" /><stop stopColor="#93C9DF" offset="100%" /></linearGradient></defs><g transform="translate(2)" fill="none" fillRule="evenodd"><circle fill="url(#prefix__a)" opacity={0.75} cx={91.5} cy={82.5} r={82.5} /><path d="M132 26.744L142.008 19l10.69 7.305L162.024 19l10.546 7.43L182.642 19 192 27.308m-60 16.436L142.008 36l10.69 7.305L162.024 36l10.546 7.43L182.642 36 192 44.308" stroke="#969BB2" strokeWidth={5} /><path d="M0 154.149s13.273 5.225 14.311-10.22c1.038-15.444 7.48-11.366 14.883-10.628 7.403.739 16.031-1.397 16.14-12.301" stroke="#969BB2" strokeWidth={6} /><path d="M124 58.096C124 90.658 97.605 117 65.096 117a3.048 3.048 0 01-2.973-2.359l-3.046-13.203a3.092 3.092 0 011.777-3.507l14.218-6.093a3.042 3.042 0 013.554.878l6.3 7.691c9.94-4.66 17.927-12.758 22.497-22.496l-7.696-6.295a3.039 3.039 0 01-.874-3.554l6.094-14.219a3.07 3.07 0 013.491-1.766l13.203 3.047A3.046 3.046 0 01124 58.096" fill="#F2F4F6" /></g></svg>
			) }

			<Title>
				{ type === 'phone' && lang.noMissedCalls }
				{ type === 'email' && lang.noEmails }
			</Title>
			<Paragraph>{lang.checkBackLater}</Paragraph>
		</Wrapper>
	);
};

const Title = styled(Paragraph)`
	margin-bottom: ${({ theme }) => theme.spacing[3]};
	font-size: ${({ theme }) => theme.fontSize.lg};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[3]}`};
	padding: ${({ theme }) => `${theme.spacing[19]} ${theme.spacing[8]}`};
	border-radius: 5px;
	background: ${({ theme }) => theme.colors.whiteish};

	svg {
		height: 165px;
		width: auto;
		margin-bottom: ${({ theme }) => theme.spacing[10]};
		text-align: center;
	}
`;

export default EmptyInboxComponent;
