import React from 'react';
import styled from 'styled-components';
import { ButtonCopy } from '../Typography';

export type ButtonComponentProps =
	React.ButtonHTMLAttributes<HTMLButtonElement> & {
		className?: string;
		copy: string;
		isHollow?: boolean;
		handlePress?: () => void;
	};

const ButtonComponent: React.FC<ButtonComponentProps> = ({
	copy,
	disabled,
	isHollow,
	handlePress,
	className,
	type,
	...props
}) => {
	return (
		<Container
			as="button"
			onClick={handlePress}
			$isHollow={!!isHollow}
			className={className}
			disabled={!!disabled}
			type={type}
			{...props}
		>
			<ButtonText as="span">{copy}</ButtonText>
		</Container>
	);
};

const ButtonText = styled(ButtonCopy)`
	color: inherit;
	margin-top: 2px;
`;

type ContainerProps = {
	disabled: boolean;
	$isHollow: boolean;
};
const Container = styled.button<ContainerProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme, $isHollow }) => {
		if ($isHollow) return 'transparent';
		return theme.colors.primary;
	}};
	border: ${({ theme, $isHollow }) =>
		`2px solid ${
			$isHollow ? theme.colors.primary_light : theme.colors.primary
		}`};
	border-radius: ${({ theme }) => theme.radii.xl};
	color: ${({ theme, $isHollow }) => {
		if ($isHollow) return theme.colors.primary;
		return theme.colors.text.light;
	}};
	min-height: 2.8125rem;
	padding-right: ${({ theme }) => theme.spacing.xl};
	padding-left: ${({ theme }) => theme.spacing.xl};
	padding-top: ${({ theme }) => theme.spacing.sm};
	padding-bottom: ${({ theme }) => theme.spacing.sm};
	opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;

export default ButtonComponent;
