import { faHeadSideMedical } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useTheme } from 'styled-components';
import { last as lastOf } from 'lodash';
import { useSelector } from '@xstate/react';

import ChartComponent from '../../components/ChartComponent';
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { StatsContext } from '../../contexts/StatsContext';
import { adaptStats } from '../../layouts/DeviceLayout/utils';
import { getStatisticChange, getValence } from '../../utils/statistics';
import { ChartPercentageLabelId } from '../../types';

export type WellbeingTileViewProps = {};

const WellbeingTileView: React.FC<WellbeingTileViewProps> = () => {
	const lang = React.useContext(LanguageContext);
	const stats = React.useContext(StatsContext);
	const { gameService } = React.useContext(GameContext);
	const isWellbeingChartLocked = useSelector(gameService, state => state.context.isWellbeingLocked);
	const theme = useTheme();

	const data = adaptStats(stats.historicalWellBeing);

	return (
		<ChartComponent
			title={lang.wellbeingChart.title}
			toolTip={lang.wellbeingChart.tooltip}
			type="progress"
			data={data}
			icon={faHeadSideMedical}
			indicator={getStatisticChange(data)}
			barColors={{
				bar: theme.colors.gradient.progress_bar.wellbeing,
				background: theme.colors.background.progress_bar,
			}}
			valence={getValence(data, 'increaseIsPositive')}
			isLocked={isWellbeingChartLocked}
			percentage={lastOf(data)?.percent}
			percentageLabel={lang.satisfied as ChartPercentageLabelId}
		/>
	);
};

export default WellbeingTileView;
