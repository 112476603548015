import React from 'react';
import styled from 'styled-components/macro';

// components
import LogoComponent from '../../components/LogoComponent';
import SocialShareLinks from '../../components/SocialMediaLinksComponent';
import { Col, Row } from '../../components/Containers';
import { ParagraphLight } from '../../components/Typography';
// layouts
import ResultsLayout from '../../layouts/ResultLayout';

// contexts
import { LanguageContext } from '../../contexts/LanguageContext';
import { StatsContext } from '../../contexts/StatsContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';

// utils
import {
	getFinalStatisticChange,
	getFinalValence,
	getModeSentiment,
} from '../../utils/statistics';
import { generateResultsQuote, calculateChanceOfReelection } from './utils';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';

// types
import { StyledTheme, Results, Valences } from '../../types';
import DeskLayout from '../../layouts/DeskLayout';
import { ResultsDebrief } from './components';
import { mediaQueries } from '../../styles/mediaQueries';
import { swanseaUniLogoWhite } from '../../assets';

export type ResultsScreenProps = {};

const ResultsScreen: React.FC<ResultsScreenProps> = (props) => {
	useSetDocumentTitle('Results - Trust the Experts Game');
	const copy = React.useContext(LanguageContext);
	const stats = React.useContext(StatsContext);
	const [firedAnalytics, setFiredAnalytics] = React.useState<boolean>(false);
	const { completeGame } = React.useContext(AnalyticsContext);

	const [isDebriefing, setIsDebriefing] = React.useState(true);

	// Stats are stored in state so that we don't get a flash of the restored defaults when we restart the game
	/** The difference between start and end statistics. */
	const [results, setResults] = React.useState<Results>({
		economy: getFinalStatisticChange(stats.historicalEconomy),
		healthcare: getFinalStatisticChange(stats.historicalHealth),
		publicOpinion: getFinalStatisticChange(stats.historicalPublicTrust),
		wellbeing: getFinalStatisticChange(stats.historicalWellBeing),
		sentiment: getModeSentiment(stats.historicalSentiment),
		chanceOfReelection: calculateChanceOfReelection(stats.historicalSentiment),
	});

	React.useEffect(() => {
		const finalStats = {
			economy: getFinalStatisticChange(stats.historicalEconomy),
			healthcare: getFinalStatisticChange(stats.historicalHealth),
			publicOpinion: getFinalStatisticChange(stats.historicalPublicTrust),
			wellbeing: getFinalStatisticChange(stats.historicalWellBeing),
			sentiment: getModeSentiment(stats.historicalSentiment),
			chanceOfReelection: calculateChanceOfReelection(
				stats.historicalSentiment
			),
		};

		setResults(finalStats);

		// Fire Analytics event :: completed the game and send the final results
		if (!firedAnalytics) {
			completeGame(finalStats);
			setFiredAnalytics(true);
		}
	}, [stats]);

	/** Whether overall change is negative / positive */
	const [valences, setValences] = React.useState<Valences>({
		economy: getFinalValence(results.economy, 'increaseIsPositive'),
		healthcare: getFinalValence(results.healthcare, 'increaseIsNegative'),
		publicOpinion: getFinalValence(results.publicOpinion, 'increaseIsPositive'),
		wellbeing: getFinalValence(results.wellbeing, 'increaseIsPositive'),
		chanceOfReelection:
			results.chanceOfReelection < 50 ? 'negative' : 'positive',
	});

	React.useEffect(() => {
		setValences({
			economy: getFinalValence(results.economy, 'increaseIsPositive'),
			healthcare: getFinalValence(results.healthcare, 'increaseIsNegative'),
			publicOpinion: getFinalValence(
				results.publicOpinion,
				'increaseIsPositive'
			),
			wellbeing: getFinalValence(results.wellbeing, 'increaseIsPositive'),
			chanceOfReelection:
				results.chanceOfReelection < 50 ? 'negative' : 'positive',
		});
	}, [results]);

	const renderHeader = () => (
		<HeaderWrapper>
			<Logo onDark />
			<SwanseaUniLogo src={swanseaUniLogoWhite} alt={copy.poweredBy} />
		</HeaderWrapper>
	);

	const renderIcons = () => (
		<Row
			as="ul"
			css={`
				justify-content: center;
				margin: 0.75rem 0;
			`}
		>
			<SocialShareLinks
				size="md"
				asShare
				isRound
				quote={generateResultsQuote(results, valences, copy)}
			/>
		</Row>
	);

	const renderFooter = () => (
		<FooterWrapper>
			<ShareResultsText>{copy.shareResults}</ShareResultsText>
			{renderIcons()}
			<FindOutMore
				as="a"
				href={
					copy.language === 'en'
						? 'https://www.swansea.ac.uk/'
						: 'https://www.swansea.ac.uk/cy/'
				}
				target="_blank"
				rel="noopener nofollow noreferrer"
			>
				{copy.findOutMore}
			</FindOutMore>
		</FooterWrapper>
	);

	return (
		<DeskLayout>
			{isDebriefing ? (
				<ResultsDebrief onDone={() => setIsDebriefing(false)} />
			) : (
				<ResultsLayout
					isOpen
					results={results}
					valences={valences}
					header={renderHeader()}
					footer={renderFooter()}
				/>
			)}
		</DeskLayout>
	);
};

const sectionSpacing = 'clamp(1rem, 3vh, 4rem)';

const SwanseaUniLogo = styled.img`
	height: 5.5rem;
	margin-left: 1.75rem;

	@media ${mediaQueries.isMobileDash} {
		margin-left: 1rem;
		height: 4.5rem;
	}
`;

const Logo = styled(LogoComponent)`
	height: 4.06rem;
	width: auto;

	@media ${mediaQueries.isMobileDash} {
		height: 3.25rem;
	}
`;

const Text = styled(ParagraphLight)`
	@media ${mediaQueries.isMobileDash} {
		font-size: ${({ theme }) => theme.fontSize.xs};
	}
`;

const HeaderWrapper = styled(Col)`
	flex-direction: row;
	padding-top: ${sectionSpacing};
	margin: calc(var(--logo-spacing) * -1);
	align-items: center;
`;

const ShareResultsText = styled(Text)`
	margin-bottom: 0.25rem;
`;

const FindOutMore = styled(ParagraphLight)`
	margin: clamp(0.75rem, 2vh, 2rem) auto 0;
	color: ${({ theme }: StyledTheme) => theme.colors.accents.lime};
	cursor: pointer;
	text-decoration: none;
`;

const FooterWrapper = styled(Col)`
	align-items: center;
	padding-bottom: ${sectionSpacing};
	margin-top: calc((var(--modal-margin-vert) * -1) + ${sectionSpacing});
`;

export default ResultsScreen;
