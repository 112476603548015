import React from 'react';
import { SentimentId } from '../../types';
import { getSentimentIcon } from '../../utils/getSentimentIcon';
import styled from 'styled-components';

export type SentimentComponentProps = {
	sentiment: SentimentId;
	className?: string;
};

/** Passed a sentiment S, returns an emoji img displaying S.
 * 
  The img has 100% width only. 
  
  An optional className prop is passed down for ad hoc styling.
*/

const SentimentComponent: React.FC<SentimentComponentProps> = ({
	sentiment,
	className,
}) => <Icon src={getSentimentIcon(sentiment)} className={className} />;

const Icon = styled.img`
	width: 100%;
`;

export default SentimentComponent;
