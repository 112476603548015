export const sizes = {
	lt: {
		isMobileDash: 718,
		isMobileHome: 649,
	},
	gte: {
		isDesktopDash: 719,
	}
}

export const mediaQueries = {
	isMobileDash: `(max-width: ${sizes.lt.isMobileDash}px)`,
	isMobileHome: `(max-width: ${sizes.lt.isMobileHome}px)`,
	isDesktopDash: `(min-width: ${sizes.gte.isDesktopDash}px)`,
	isDesktopScene: `(min-aspect-ratio: 1/1)`,
	isMobileScene: `not screen and (min-aspect-ratio: 1/1)`,
	isStackedMobileSetup: `(max-width: 609px)`
}