import { css } from 'styled-components/macro';

export const focus = css`
	& {
		&:focus, .carousel__slide-focus-ring {
			[data-tabbing='false'] & {
				outline: none;
			}
			[data-tabbing='true'] & {
				outline: 4px solid ${({ theme }) => theme.colors.focus};
			}
		}
	}
`;

/**
 * Be careful using this. Margin will affect surrounding elements
 */
export const nudgeOutOfContainer = (
	dir: 'start' | 'end' = 'end',
	asMargin?: boolean
) => css`
	@media (min-width: 1360px) {
		${({ theme }) => {
			let property = dir === 'start' ? 'left' : 'right';

			if (asMargin) {
				property = `margin-${property}`;
			}

			return `
				${!asMargin ? 'position: relative;' : ''}
				${property}: -${theme.spacing[10]};
			`;
		}}
	}
`;

export const edgeOfScreen = (
	dir: 'start' | 'end' = 'end',
	absolute?: boolean
) => css`
	--edge-spacing: ${({ theme }) => theme.spacing[8]};
	${({ theme }) => {
		if (absolute) {
			return `
				position: absolute;
				${`${dir === 'start' ? 'left' : 'right'}: var(--edge-spacing)`};
			`;
		}

		return `
			margin-${dir === 'start' ? 'right' : 'left'}: auto;
			margin-${dir === 'start' ? 'left' : 'right'}: var(--edge-spacing);
		`;
	}}
`;

export const fadeIn = `
	animation-name: fadeIn;
	animation-duration: 1s; 

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;
