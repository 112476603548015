import React from 'react';
import ReactPlayer from 'react-player/youtube';
import styled from 'styled-components';
import { testIds } from '../../utils/testing';

export type VideoComponentProps = {
	link: string;
};

const VideoComponent: React.FC<VideoComponentProps> = ({ link }) => {
	return (
		<Wrapper data-testid={testIds.video} data-src={link}>
			<ReactPlayer controls url={link} width="100%" height="100%" />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	padding-top: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
	}
`;

export default VideoComponent;
