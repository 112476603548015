import * as React from 'react';
import dayjs from 'dayjs';

import { LanguageId } from '../../types';

require('dayjs/locale/cy');

export type LanguageType = {
	accountAndFinance: string;
	andYourLocation: string;
	answer: string;
	backText: string;
	briefFour: string;
	briefOne: string;
	briefOneDelayed: string;
	briefThree: string;
	briefTwo: string;
	businessAndManagement: string;
	checkBackLater: string;
	closeModal: string;
	complete: string;
	debriefOne: string;
	debriefTwo: string;
	debriefThree: string;
	decreasedBy: string;
	economyChart: {
		title: string;
		tooltip: string;
	};
	education: string;
	errorContentLabel: string;
	errorDefault: string;
	errorRefreshButtonCopy: string;
	errorSuggestRefresh: string;
	Female: string;
	findOutMore: string;
	finishedGameParagraphOne: string;
	finishedGameParagraphTwo: string;
	firstSectionOfPostcode: string;
	goToMyOffice: string;
	greatLetsGo: string;
	grewBy: string;
	healthChart: {
		title: string;
		tooltip: string;
	};
	helloPrimeMinister: string;
	historicalSubjects: string;
	homeBriefPageOne: string;
	homeBriefPageOneStrong?: string;
	homeBriefPageTwo: string;
	homeBriefPageTwoStrong?: string;
	hospitalCapacity: string;
	howTimeFlies: string;
	incomingCall: string;
	incomingCallLong: string;
	increasedBy: string;
	inputPostcodePlaceholder: string;
	inputGenderPlaceholder: string;
	lawAndCrimonology: string;
	letsStopThisVirus: string;
	letsGetStarted: string;
	letsSee: string;
	letsTakeALook: string;
	literatureAndLanguages: string;
	Male: string;
	mediaAndMarketing: string;
	muteAudio: string;
	newEmailA11yText: string;
	newSentimentText: string;
	newVoicemail: string;
	newVoicemailLong: string;
	nextText: string;
	noEmails: string;
	noMissedCalls: string;
	NonBinary: string;
	okayText: string;
	philosophyAndReligion: string;
	playAgainText: string;
	politicsAndInternationalRelations: string;
	poweredBy: string;
	preAnswer: string;
	preferNot: string;
	PreferNotToSay: string;
	preferToSelfDescribe: string;
	previousText: string;
	prevText: string;
	publicOpinion: string;
	publicTrustChart: {
		title: string;
		tooltip: string;
	};
	resultsLayout: {
		contentLabel: string;
		economy: {
			resultBot: string;
			resultMid: {
				negative: string;
				positive: string;
			};
			resultTop: string;
			title: string;
		};
		healthcare: {
			resultBot: string;
			resultTop: string;
			title: string;
		};
		overall: {
			resultBot: string;
			resultTop: string;
			title: string;
		};
		publicOpinion: {
			resultBot: string;
			resultTop: string;
			title: string;
		};
		sentiment: {
			result: string;
			title: string;
		};
		wellBeing: {
			resultBot: string;
			resultMid: {
				negative: string;
				positive: string;
			};
			resultTop: string;
			title: string;
		};
	};
	scenario: string;
	scenarioLayout: {
		button: {
			next: string;
			prev: string;
		};
		contentLabel: string;
		firstSlide: {
			bottom: string;
			top: string;
		};
		horizontalLabel: string;
		thirdSlide: {
			bottom: string;
			button: string;
			middle: string;
			top: string;
		};
		title: string;
		vertical: string;
	};
	selectCountry: string;
	selectSubject: string;
	selectGender: string;
	shareResults: string;
	shareThis: string;
	shrankBy: string;
	slide: string;
	socialMediaTile: {
		title: string;
	};
	startGame: string;
	startingPoint: string;
	submit: string;
	sureThing: string;
	tasks: string;
	thisIsAwkwardPrimeMinister: string;
	tileTitle: {
		email: string;
		phone: string;
		tasks: string;
	};
	volumeText: string;
	welcome: string;
	weWouldLikeToDoResearch: string;
	wellbeingChart: {
		title: string;
		tooltip: string;
	};
	whatGender: string;
	whichSubjects: string;
	full: string;
	satisfied: string;
	happy: string;
};

type LanguageDataType = { [K in LanguageId]: LanguageType };

const data: LanguageDataType = {
	cy: {
		accountAndFinance: 'Cyfrif a chyllideb',
		andYourLocation: `… Ac, os na fyddai ots gennych, eich lleoliad?`,
		answer: 'Ateb',
		backText: 'Yn ôl',
		briefFour:
			"Mae'r arbenigwyr yn barod i siarad gyda chi - beth am ddechrau?",
		briefOne:
			"Newyddion drwg, Prif Weinidog. Mae'r pandemig yn waeth na'r disgwyl",
		briefOneDelayed:
			'Mae ein harbenigwyr yn rhagweld y bydd yn cyrraeedd yma o fewn tair wythnos, efallai ynghynt...',
		briefThree: "Mewn argyfwng, mae'ch penderfyniadau yn dyngedfennol",
		briefTwo: "Mae'r arbenigwyr yn barod i siarad gyda chi - beth am ddechrau?",
		businessAndManagement: 'Busnes a Rheolaeth',
		checkBackLater: 'Gwiriwch eto ymhen ychydig',
		closeModal: "Cau'r modal",
		complete: 'Cyflawn',
		debriefOne: 'Blwyddyn newydd dda, Brif Weinidog',
		debriefTwo: 'Am flwyddyn llond heriau a chystudd!',
		debriefThree:
			"Mae'r canlyniadau o'r flwyddyn ddiwethaf wedi cyrraedd. Hoffech eu gweld?",
		decreasedBy: 'Wedi lleihau',
		economyChart: {
			title: 'CDG y pen',
			tooltip:
				"Mae hwn yn fesur o ffyniant economaidd. Mae'n rhannu cyfanswm gwerth y nwyddau a'r gwasanaethau mewn gwlad bob blwyddyn rhwng y nifer y bobl sy'n byw yno. Rhoddir y gwerth yn Noelri yr UD; pa uchaf y sgôr, y gorau.",
		},
		education: 'Addysg',
		errorContentLabel: 'Arddangosiad o gamgymeriad',
		errorDefault: 'Dratia! Mae rhywbeth wedi myn o le',
		errorRefreshButtonCopy: 'Dechrau eto',
		errorSuggestRefresh: 'Ailddechreuwch y gêm a cheisiwch eto',
		Female: 'Benyw',
		findOutMore: 'Dewch o hyd i fwy o wybodaeth am Brifysgol Abertawe yma',
		firstSectionOfPostcode: 'Rhan gyntaf eich côd post',
		goToMyOffice: 'Ewch i fy swyddfa',
		greatLetsGo: "I'r dim! Bant â ni!",
		grewBy: 'Wedi tyfu',
		healthChart: {
			title: 'Gallu gofal iechyd cenedlaethol',
			tooltip:
				"Mae hyn yn mesur pa mor llawn yw'ch ysbytai. Bydd newidiadau i hyn yn eich helpu i ddeall a yw'ch poblogaeth yn dod yn fwy neu'n llai iach. Rhoddir y sgôr fel canran - mae 100% yn golygu bod eich ysbytai yn llawn ac mae unrhyw beth uwch na hynny yn golygu nad yw pobl sâl yn cael y driniaeth sydd ei hangen arnynt. Felly, yr isaf yw'r sgôr yma, y gorau.",
		},
		helloPrimeMinister: `Helo, Prif Weinidog. Gadewch i ni ddechrau...`,
		historicalSubjects: 'Testunau hanesyddol',
		homeBriefPageOne:
			"Chi yw arweinydd newydd ei ethol Aberdemig, gwlad fach wedi'i hamgylchynu gan fynyddoedd gyda phoblogaeth o 40 miliwn o bobl. Yn ystod eich wythnos gyntaf yn y swydd, fe'ch gwneir yn ymwybodol o firws marwol sy'n ymledu trwy'r cyfandir cyfagos ac y mae arbenigwyr yn rhagweld y bydd yn cyrraedd Aberdemig o fewn y tair wythnos nesaf.",
		// homeBriefPageOneStrong:
		// 	'Enim qui enim mollit ea ad anim anim ut ipsum. Excepteur voluptate dolore incididunt cillum et nulla commodo id.',
		homeBriefPageTwo:
			`Bydd rhaid i chi wneud rhai penderfyniadau anodd dros y misoedd nesaf. Er mwyn eich helpu i ddelio â'r argyfwng, mae tîm o arbenigwyr wrth law i gynnig cyngor. Fodd bynnag, bydd gan bob un ohonynt bersbectif gwahanol ar yr hyn y dylech ei wneud - ac ni fyddant o reidrwydd yn cytuno â'i gilydd. Bydd pob un o'r arbenigwyr yn cynnig eu barn bersonol, nid barn y Brifysgol y maen nhw'n gweithio iddi. Mae rhanddeiliaid eraill, megis gwleidyddion a phapurau newydd, yn sicr o fod eisiau dweud eu dweud hefyd.`,
		homeBriefPageTwoStrong:
			`Mae pob pandemig yn wahanol, felly bydd yr arbenigwyr yn amrywioamrywio a ni fydd y polisïau a argymhellir bob amser yn cael yr un effaith. A fyddwch chi'n ymddiried yn eich arbenigwyr?`,
		hospitalCapacity: 'capasiti gwelyau ysbyty',
		howTimeFlies: 'Mae amser yn hedfan!',
		incomingCall: 'Galwad ffôn',
		incomingCallLong: 'Galwad fideo oddi wrth',
		increasedBy: 'Wedi cynyddu',
		inputPostcodePlaceholder: 'Rhan gyntaf eich côd post',
		inputGenderPlaceholder: 'Hunan ddisgrifiwch',

		finishedGameParagraphOne:
			"Llongyfarchiadau, Brif Weinidog, mae eich blwyddyn gyntaf wedi mynd a dod! Rydych wedi gwrando ar bob un o'r arbenigwyr, wedi ateb pob un o'r cwestiynau, ac wedi cymryd rai penderfyniadau anodd ar hyd y ffordd.",
		finishedGameParagraphTwo:
			'Beth am ddarganfod sut rydych chi wedi gwneud yn eich blwyddyn gyntaf?',
		lawAndCrimonology: 'Y Gyfraith a Throseddeg',
		letsGetStarted: 'Gadewch i ni ddechrau',
		letsStopThisVirus: `Rhaid trechu'r feirws 'ma!`,
		letsSee: 'Gadewch i ni weld!',
		letsTakeALook: 'Gadewch i ni weld',
		literatureAndLanguages: 'Llenyddiaeth a Ieithoedd',
		Male: 'Gwryw',
		mediaAndMarketing: 'Y Cyfryngau a marchnata',
		muteAudio: 'Dim awdio',
		newEmailA11yText: 'Ebost newydd',
		newSentimentText: 'Mae barn y cyhoedd amdanoch wedi newid. Barn: ',
		newVoicemail: 'Galwad ffôn newydd',
		newVoicemailLong: 'Neges ffôn oddi wrth',
		nextText: 'Nesaf',
		noEmails: 'Nid oes ebyst',
		noMissedCalls: 'Nid oes galwadau heb eu hateb',
		NonBinary: 'di-ddeuaidd',
		okayText: 'Derbyn',
		philosophyAndReligion: 'Athroniaeth a Chrefydd',
		playAgainText: 'Chwarae eto?',
		politicsAndInternationalRelations:
			'Gwleidyddiaeth a Chysylltiadau Rhyngwladol',
		poweredBy: "Wedi'i yrru gan Brifysgol Abertawe",
		preAnswer:
			"Rydych chi wedi ymgynghori â'ch cynghorwyr, nawr mae'n amser gwneud penderfyniad.",
		preferNot: 'Dim diolch',
		PreferNotToSay: 'Gwell peidio a dweud',
		preferToSelfDescribe: 'Gwell hunan ddisgrifio',
		previousText: 'Yn ôl',
		prevText: 'Yn ôl',
		publicOpinion: 'Barn gyhoeddus',
		publicTrustChart: {
			title: 'Hyder y cyhoedd',
			tooltip:
				"Mae hyn yn mesur i ba raddau mae'r cyhoedd yn ymddiried ynoch chi. Mae ffactorau fel effeithlonrwydd eich cyfathrebiadau cyhoeddus, a pha mor briodol, angenrheidiol a chyfreithiol yr ystyrir eich polisïau yn galla effeithio ar hyn.  Fe'i mesurir fel canran - yr uchaf yw'r sgôr, y gorau.",
		},
		resultsLayout: {
			contentLabel: "Arddangoisiad o ganlyniadau'r gêm",
			economy: {
				resultBot: `ers i chi gael eich ethol `,
				resultMid: {
					negative: 'Yn uwch',
					positive: 'Yn is',
				},
				resultTop: 'cynnyrch domestig gros Aberdemig yw',
				title: 'effaith economaidd',
			},
			healthcare: {
				resultBot: 'Rhif {STAT} ers i chi eich ethol',
				resultTop: 'Y gofyn am welyau ysbty yw',
				title: 'Gofal Iechyd',
			},
			overall: {
				resultBot: 'y gobaith o gael eich ail ethol yn yr etholiad nesaf',
				resultTop:
					"Yn seiliedig ar eich penderfyniadau dros y flwyddyn ddiwethaf, mae'r cyhoedd yn",
				title: 'Trosolwg - Arolwg barn',
			},
			publicOpinion: {
				resultBot: 'ers i chi gael eich ethol ',
				resultTop: 'Mae hyder y cyhoedd wedi newid',
				title: 'Barn gyhoeddus',
			},
			sentiment: {
				result: 'Yr yametb mwyaf cyffredin oddi wrth y cyhoedd oedd: ',
				title: 'Barn',
			},
			wellBeing: {
				resultBot: 'ers i chi gael eich ethol ',
				resultMid: {
					negative: 'yn llai hapus',
					positive: 'yn fwy hapus',
				},
				resultTop: 'Poblogaeth Aberdemig yw',
				title: 'Lles',
			},
		},
		scenario: 'Senario',
		scenarioLayout: {
			button: {
				next: 'Nesaf',
				prev: 'Yn ôl',
			},
			contentLabel: 'Gwybodaeth am y senario gyfredol',
			firstSlide: {
				bottom:
					'Cadwch lygad ar eich graffiau, byddant yn eich hysbysu o ganlyniadau eich penderfyniadau.',
				top: "Eich tasg fel Prif Weinidog yw penderfynu ar gamau gweithredu ar gyfer pob un o'r senarios y mae'r pandemig newydd yn eu cyflwyno - wedi'u llywio gan y wybodaeth y mae eich arbenigwyr yn ei rhoi ichi.",
			},
			horizontalLabel: 'Tasgau',
			thirdSlide: {
				bottom: 'Pob lwc!',
				button: 'Dangosfwrdd',
				middle: 'Yna gofynnir i chi ddewis un o dri dull gweithredu.',
				top: "Byddwch yn derbyn tri darn o wybodaeth gan eich arbenigwyr i lywio'ch penderfyniad.",
			},
			title: 'Y senario',
			vertical: 'Tasgau',
		},
		selectCountry: 'Dewiswch wlad',
		selectSubject: 'Dewiswch bwnc',
		selectGender: 'Dewish eich rhyw',
		shareResults: 'Rhannwch eich canlyniadau',
		shareThis: 'Rhannwch hwn',
		shrankBy: 'Wedi crebachu',
		slide: 'Sleid',
		socialMediaTile: {
			title: 'Y Cyfryngau Cymdeithasol',
		},
		startGame: 'Dechreuwch y gêm',
		startingPoint: 'Man cychwyn',
		submit: 'Cyflwyno',
		sureThing: 'Wrth gwrs!',
		tasks: 'Tasgau',
		thisIsAwkwardPrimeMinister:
			'Mae hyn braidd yn lletchwith, Prif Weinidog, ond beth yw eich oedran?',
		tileTitle: {
			email: 'ebost',
			phone: 'ffôn',
			tasks: 'tasgau',
		},
		volumeText: 'Sain',
		welcome: 'Croeso',
		weWouldLikeToDoResearch: `Hoffem wneud rhywfaint o ymchwil i sut mae pobl yn ymateb wrth chwarae ein gêm. Nid ydym yn storio unrhyw wybodaeth y gellir ei defnyddio i'ch adnabod chi'n unigol. Hoffem wybod eich oedran, rhyw, amcan o'ch lleoliad, a'r pynciau academaidd sydd o ddiddordeb mwyaf i chi. Byddwn yn defnyddio unrhyw ddata rydych chi'n cytuno i rannu i archwilio'r dewisiadau y mae gwahanol grwpiau o bobl yn eu gwneud yn ystod y gêm.`,
		wellbeingChart: {
			title: 'Lles Cenedlaethol',
			tooltip:
				"Mae hyn yn mesur hapusrwydd eich gwlad. Mae'n cael ei effeithio gan bob math o bethau, fel iechyd meddwl a phryder, i ba raddau mae pobl yn teimlo'n fodlon â bywyd a bod yr hyn maen nhw'n ei wneud yn werth chweil, mynediad i addysg, cyfleoedd cymdeithasol a diwylliannol, cydbwysedd rhwng bywyd a gwaith, ac ati. Mae'n cael ei fesur fel canran - yr uchaf yw'r sgôr, y gorau.",
		},
		whatGender: "… A pha ryw ydych chi'n uniaethu ag ef?",
		whichSubjects: "Pa un o'r pynciau canlynol sydd o ddiddordeb i chi fwyaf?",
		full: 'llawn',
		satisfied: 'wedi bodloni',
		happy: 'yn hapus',
	},
	en: {
		accountAndFinance: 'Accounting and Finance',
		andYourLocation: `and, if you wouldn't mind, your location`,
		answer: 'Answer',
		backText: 'Back',
		briefFour: 'The experts are ready to talk with you, shall we get started?',
		briefOne: 'Bad news, I’m afraid Prime Minister.',
		briefOneDelayed: 'The pandemic is worse than we first feared…',
		briefThree:
			'In times of crisis, the decisions you make matter more than ever before.',
		briefTwo:
			'Our experts predict that it will reach our shores within 3 weeks, perhaps sooner…',
		businessAndManagement: 'Business and Management',
		checkBackLater: 'Check back later',
		closeModal: 'Close modal',
		complete: 'Complete',
		debriefOne: 'Happy New Year, Prime Minister..',
		debriefTwo:
			"What a 12 months it's been, full of trials and tribulations, that's for sure.",
		debriefThree:
			'Well, the results from the past 12 months are here for you to see. Shall we review them together?',
		decreasedBy: 'decreased by',
		economyChart: {
			title: 'gdp per capita',
			tooltip:
				'This is a measure of economic prosperity. It divides the total value of the goods and services in a country each year by the number of people who live there. The value is given in US Dollars; the higher the score, the better. ',
		},
		education: 'Education',
		errorContentLabel: 'A modal displaying an error.',
		errorDefault: `Oh no! It looks like something went wrong.`,
		errorRefreshButtonCopy: 'Start again',
		errorSuggestRefresh: 'Restart the game and try again.',
		Female: 'Female',
		findOutMore: 'Find out more about Swansea University here',
		firstSectionOfPostcode: 'First section of postcode',
		finishedGameParagraphOne:
			'Congratulations, Prime Minister, your first year has come and gone! You’ve listened to all of the experts, answered all of the questions, and made some tough decisions along the way.',
		finishedGameParagraphTwo:
			'Shall we find out how you’ve done in your first year?',
		goToMyOffice: 'Go to my office',
		greatLetsGo: "Great! Let's go!",
		grewBy: 'grew by',
		healthChart: {
			title: 'National Healthcare Capacity',
			tooltip:
				'This measures how full your hospitals are. Changes to this will help you understand whether your population is becoming more or less healthy. The score is given as a percentage – 100% means that your hospitals are full and anything above that means ill people are not getting the treatment that they need. So, the lower the score here, the better.',
		},
		helloPrimeMinister: `Hello Prime Minister. Let's get you set up`,
		historicalSubjects: 'Historical Subjects',
		homeBriefPageOne:
			'You are the newly elected leader of Aberdemic, a small landlocked country surrounded by mountains with a population of 40 million people. In your first week in office, you are made aware of a deadly virus that is spreading through the neighbouring continent and that experts predict will reach Aberdemic within the next three weeks.',
		homeBriefPageTwo:
			'You are going to have to make some difficult decisions over the next few months. To help you deal with the crisis, a team of experts is on hand to offer advice. Be aware, though, that they will all have a different perspective on what you should do – and they will not necessarily agree with one another. All of the experts will be offering their personal opinion, and not the views of the University they work for. Other key stakeholders, like politicians and newspapers, are sure to want to have their say too.',
		homeBriefPageTwoStrong:
			'Every pandemic is different, so the experts you hear from will vary and recommended policies will not always have the same effect. Will you trust your experts?',
		hospitalCapacity: 'hospital bed capacity',
		howTimeFlies: 'How time flies!',
		incomingCall: 'Incoming call',
		incomingCallLong: 'You have a video call incoming from',
		increasedBy: 'increased by',
		inputPostcodePlaceholder: 'First section of postcode',
		inputGenderPlaceholder: 'Please self-describe',
		lawAndCrimonology: 'Law and Criminology',
		letsGetStarted: "Let's get started",
		letsStopThisVirus: `Let's stop this virus!`,
		letsSee: "Let's see!",
		letsTakeALook: "Let's take a look",
		literatureAndLanguages: 'Literature and Languages',
		Male: 'Male',
		mediaAndMarketing: 'Media and Marketing',
		muteAudio: 'Mute audio',
		newEmailA11yText: 'You have a new email',
		newSentimentText:
			"The general public's sentiment towards you has changed. Sentiment:",
		newVoicemail: 'You have a new voicemail',
		newVoicemailLong: 'You have a new voicemail from',
		nextText: 'Next',
		noEmails: "You don't have any emails",
		noMissedCalls: 'No missed calls',
		NonBinary: 'Non-Binary',
		okayText: 'Okay',
		philosophyAndReligion: 'Philosophy and Religion',
		playAgainText: 'play again',
		politicsAndInternationalRelations: 'Politics and International Relations',
		poweredBy: 'Powered by Swansea University',
		preAnswer:
			'You’ve consulted your advisors, now it’s time to make a decision.',
		preferNot: "I'd prefer not to",
		PreferNotToSay: 'Prefer not to say',
		preferToSelfDescribe: 'Prefer to self describe',
		previousText: 'Previous',
		prevText: 'Prev',
		publicOpinion: 'public opinion',
		publicTrustChart: {
			title: 'public trust',
			tooltip:
				'This measures how far the public trusts you. It is affected by things like how well you communicate with people, and how far people think your policies are appropriate, necessary, and legal. It is measured as a percentage – the higher the score, the better.',
		},
		resultsLayout: {
			contentLabel: 'Display the game results',
			economy: {
				resultBot: `since you've taken office.`,
				resultMid: {
					negative: 'lower',
					positive: 'higher',
				},
				resultTop: 'The GDP for Aberdemic is',
				title: 'economic impact',
			},
			healthcare: {
				resultBot: "That's {STAT} since you took office",
				resultTop: 'The current demand for hospital beds is',
				title: 'healthcare',
			},
			overall: {
				resultBot: 'chance of being re-elected in the next election.',
				resultTop: 'Based on your decisions over the past year, you have a',
				title: 'overall — at the polls',
			},
			publicOpinion: {
				resultBot: 'since you took office.',
				resultTop: 'Public trust has changed by',
				title: 'public opinion',
			},
			sentiment: {
				result: 'Your most common response from the public was:',
				title: 'sentiment',
			},
			wellBeing: {
				resultBot: 'since you took office.',
				resultMid: {
					negative: 'unhappier',
					positive: 'happier',
				},
				resultTop: 'The Aberdemic population is',
				title: 'wellbeing',
			},
		},
		scenario: 'Scenario',
		scenarioLayout: {
			button: {
				next: 'next',
				prev: 'prev',
			},
			contentLabel: 'Information about the current scenario',
			firstSlide: {
				bottom: `Keep an eye on your graphs, they'll inform you of the consequences of your decisions.`,
				top: 'Your task as Prime Minister is to decide on a course of action for each of the scenarios the new pandemic presents — informed by the information your experts supply you with.',
			},
			horizontalLabel: 'tasks',
			thirdSlide: {
				bottom: 'Good luck!',
				button: 'Go to my dashboard',
				middle: `You'll then be asked to choose one of three courses of action.`,
				top: `You'll receive three pieces of information from your experts to inform your decision.`,
			},
			title: 'the scenario',
			vertical: 'tasks',
		},
		selectCountry: 'Select Country',
		selectSubject: 'Select Subject',
		selectGender: 'Select Gender',
		shareResults: 'Share your results:',
		shareThis: 'Share this',
		shrankBy: 'shrank by',
		slide: 'Slide',
		startingPoint: 'Starting Point',
		socialMediaTile: {
			title: 'Trending on Social',
		},
		startGame: 'Start game',
		submit: 'Submit',
		sureThing: 'Sure thing!',
		tasks: 'tasks',
		thisIsAwkwardPrimeMinister:
			'This is awkard Prime Minister, but how old are you',
		tileTitle: {
			email: 'email',
			phone: 'phone',
			tasks: 'tasks',
		},
		volumeText: 'Volume',
		welcome: 'Welcome',
		weWouldLikeToDoResearch: `We’d like to do some research into how people respond when playing our game. We won’t store any information that can be used to identify you individually. We’d just like to know your age, gender, approximate region, and the academic subjects that interest you the
    most. Any data you agree to us storing will be used by us to explore
    the choices that different groups of people make during the game.`,
		wellbeingChart: {
			title: 'National Wellbeing',
			tooltip:
				'This measures the happiness of your country. It is affected by all kinds of things, like mental health and anxiety, how far people feel satisfied with life and that what they do is worthwhile, access to education, social and cultural opportunities, work-life balance, etc. It is measured as a percentage – the higher the score, the better.',
		},
		whatGender: 'and what gender do you identify as',
		whichSubjects: 'Which of the following subjects are you most interested in',
		full: 'full',
		satisfied: 'satisfied',
		happy: 'happy',
	},
};

export type LanguageContextType = LanguageType & {
	set: (id: LanguageId) => void;
	toggle: () => void;
	language: LanguageId;
};

// set active value
const translate = (id: LanguageId): LanguageType => data[id];

export const LanguageContext = React.createContext<LanguageContextType>({
	...translate('en'),
	set: () => {},
	toggle: () => {},
	language: 'en',
});

export const LanguageProvider: React.FC = (props) => {
	const [active, setActive] = React.useState<LanguageId>('en');

	const toggle = () => setActive((prev) => (prev === 'en' ? 'cy' : 'en'));

	const set = (id: LanguageId) => setActive(id);

	React.useEffect(() => {
		document.documentElement.lang = active;
		dayjs.locale(active);
	}, [active]);

	/** Language context. Provides static copy for application in Welsh or English.
	 *
	 * Exposes convenience methods for setting active language.
	 *
	 * @method set - set the active language ('en' or 'cy')
	 * @method toggle - toggle the active language ('en' or 'cy')
	 */
	const value: LanguageContextType = {
		...translate(active),
		set,
		toggle,
		language: active,
	};

	return (
		<LanguageContext.Provider value={value}>
			{props.children}
		</LanguageContext.Provider>
	);
};
