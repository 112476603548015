import { useSelector } from '@xstate/react';
import React from 'react';

import SimpleTile from '../../components/SimpleTileComponent';
import { DeviceContext } from '../../contexts/DeviceContext';
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import useNotification from '../../hooks/useNotification';
import { testIds } from '../../utils/testing';
import AudioVideoAdviceView from '../AudioVideoAdviceView';

export type PhoneTileViewProps = {};

const PhoneTileView: React.FC<PhoneTileViewProps> = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const lang = React.useContext(LanguageContext);
	const { gameService } = React.useContext(GameContext);
	const hasNewVoicemail = useSelector(gameService, state => state.context.hasNewVoicemail);
	const { device } = React.useContext(DeviceContext);
	const { triggerNotification } = useNotification();
	const [triggered, setTriggered] = React.useState(false);

	React.useEffect(() => {
		setTriggered(hasNewVoicemail);
	}, [hasNewVoicemail]);

	React.useEffect(() => {
		if (!hasNewVoicemail || triggered) return;
		triggerNotification();
		setTriggered(true);
	}, [hasNewVoicemail, triggerNotification, triggered]);

	return (
		<>
			<SimpleTile
				title={lang.tileTitle.phone}
				icon="Phone"
				handleClick={() => setIsOpen(true)}
				hasIndicator={hasNewVoicemail}
				device={device}
				data-testid={testIds.phoneTile}
			/>

			<AudioVideoAdviceView
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};

export default PhoneTileView;
