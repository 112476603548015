import React from 'react';

import SocialMediaTileComponent from '../../components/SocialMediaTileComponent';
import { DeviceContext } from '../../contexts/DeviceContext';
import { StatsContext } from '../../contexts/StatsContext';
import { SentimentId, StatisticChangeValenceId } from '../../types';

export type SocialTileViewProps = {}

const SocialTileView: React.FC<SocialTileViewProps> = () => {
	const { sentiment, socialHashtags } = React.useContext(StatsContext);
	const { device } = React.useContext(DeviceContext);
	const valence = getValenceFromSentiment(sentiment);
	
	return (
		<SocialMediaTileComponent
			hashtags={socialHashtags}
			valence={valence}
			deviceType={device}
			sentiment={sentiment}
		/>
	);
};

const getValenceFromSentiment = (sentiment: SentimentId): StatisticChangeValenceId => {
	if (sentiment === 'happy') return 'positive';
	if(['angry', 'unhappy', 'sad', 'unsure'].includes(sentiment)) return 'negative';
	return 'neutral';
}

export default SocialTileView;