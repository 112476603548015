import { useSelector } from '@xstate/react';
import React from 'react';
import { GameContext } from '../../contexts/GameContext';
import { StatsContext } from '../../contexts/StatsContext';
import SentimentLayout from '../../layouts/SentimentLayout';

export type SentimentViewProps = {}

const SentimentView: React.FC<SentimentViewProps> = (props) => {
	const { sentiment } = React.useContext(StatsContext);
	const { gameService } = React.useContext(GameContext);
	const isReceivingNewSentiment = useSelector(gameService, state => state.matches('playing.receivingFeedback.updatingSentiment'));

	if(!isReceivingNewSentiment) return null;

	return (
		<SentimentLayout sentiment={sentiment} />
	);
};

export default SentimentView;