import { useSelector } from '@xstate/react';
import React from 'react';

import { GameContext } from '../../contexts/GameContext';
import usePrevious from '../../hooks/usePrevious';
import AudioContentLayout from '../../layouts/AudioVideoContentLayout';
import IncomingCallLayout from '../../layouts/IncomingCallLayout';
import { currentRoundSelector } from '../../machines/game/selectors';
import { ExpertAdvice } from '../../types';

export type AudioVideoAdviceViewProps = {
	isOpen: boolean;
	onClose: () => void;
}

const AudioVideoAdviceView: React.FC<AudioVideoAdviceViewProps> = ({ isOpen, onClose }) => {
	const [logs, setLogs] = React.useState<ExpertAdvice[]>([]);
	const [hasAnswered, setHasAnswered] = React.useState(false);
	const { gameService, onHearAdvice } = React.useContext(GameContext);
	const currentRound = useSelector(gameService, currentRoundSelector);
	const videos = useSelector(gameService, state => state.context.videos);
	const voicemails = useSelector(gameService, state => state.context.voicemails);
	const hasNewVideo = useSelector(gameService, state => state.context.hasNewVideo);
	const hasNewVoicemail = useSelector(gameService, state => state.context.hasNewVoicemail);
	
	const prevRound = usePrevious(currentRound);

	// Reset the call logs each round
	React.useEffect(() => {
		if(prevRound === currentRound) return;
		setLogs([]);
	}, [prevRound, currentRound]);

	const voicemail = currentRound ? voicemails[currentRound - 1] : undefined;
	const video = currentRound ? videos[currentRound - 1] : undefined;

	// If there's a new voicemail, add to the logs
	React.useEffect(() => {
		if(!voicemail) return;
		setLogs(prev => [...prev, voicemail]);
	}, [voicemail]);

	// If there's a new video, add to the logs
	React.useEffect(() => {
		if(!video) return;
		setLogs(prev => [...prev, video]);
	}, [video]);

	const handleClose = () => {
		onClose();
		setHasAnswered(false);

		if(hasNewVideo || hasNewVoicemail) {
			onHearAdvice();
		}
	}

	if(hasNewVideo && !hasAnswered) {
		return(
			<IncomingCallLayout 
				type="call"
				callerName={video?.expertsName || ''}
				callerDescription={video?.expertsDescription || ''}
				onNext={() => setHasAnswered(true)}
			/>
		);
	}

	return (
		<AudioContentLayout 
			isOpen={isOpen || hasNewVideo}
			logs={logs}
			onClose={handleClose}
		/>
	);
};


export default AudioVideoAdviceView;
