import React from 'react'

import usePrevious from '../usePrevious';

const useFadeOut = (cb?: () => void, duration: number = 1000) => {
	const [isFading, setIsFading] = React.useState(false);
	const wasFading = usePrevious(isFading);

	const fadeOut = () => {
		setIsFading(true);
	}

	React.useEffect(() => {
		if(isFading && !wasFading && cb) {
			setTimeout(cb, duration || 1000);
		}
	}, [duration, isFading, wasFading, cb]);

	return {
		fadeOut,
		styles: {
			opacity: isFading ? 0 : 1,
			transition: `${duration}ms opacity`
		}
	}
}

export default useFadeOut;