import React from 'react';
import styled, { useTheme } from 'styled-components';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';

import ButtonComponent from '../../components/ButtonComponent';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import ModalComponent, {
	ModalButtons,
	ModalChildren,
	ModalComponentProps,
} from '../../components/ModalComponent';
import { LanguageContext } from '../../contexts/LanguageContext';
import VideoComponent from '../../components/VideoComponent';
import EmptyInboxComponent from '../../components/EmptyInboxComponent';
import { ExpertAdvice } from '../../types';
import { testIds } from '../../utils/testing';

export type AudioVideoContentLayoutProps = Pick<
	ModalComponentProps,
	'isOpen' | 'onClose'
> & {
	logs: Array<ExpertAdvice | undefined>;
};

const AudioVideoContentLayout: React.FC<AudioVideoContentLayoutProps> = ({
	isOpen,
	logs,
	onClose,
}) => {
	const [logIndex, setLogIndex] = React.useState(0);
	const lang = React.useContext(LanguageContext);
	const { openExpertAdvice, closeExpertAdvice } = React.useContext(
		AnalyticsContext
	);
	const theme = useTheme();

	const logLength = logs.length;

	const media = logs[logIndex];

	/**
	 * If log length has changed, a new bit of advice has come in.
	 * Update the index so that the newest advice is visible.
	 */
	React.useEffect(() => {
		if (logs.length === 0) return;
		setLogIndex(logs.length - 1);
	}, [logLength, logs]);

	React.useEffect(() => {
		if (!media) return;

		if (isOpen) {
			openExpertAdvice();
		} else closeExpertAdvice(media?.title ?? '');
	}, [isOpen]);

	const onPreviousClick = () => {
		closeExpertAdvice(media?.title ?? '');
		openExpertAdvice();
		setLogIndex((prev) => prev - 1);
	};
	const onNextClick = () => {
		closeExpertAdvice(media?.title ?? '');
		openExpertAdvice();
		setLogIndex((prev) => prev + 1);
	};

	return (
		<Modal
			isColor
			isTitleUpperCase={!!media}
			contentLabel={lang.incomingCall}
			isOpen={isOpen}
			onClose={onClose}
			title={media?.expertsName || 'Phone'}
			titleIcon={faPhone}
			titleIconColor={
				media?.expertsName
					? theme.colors.accents.yellow_dark
					: theme.colors.text.dark
			}
		>
			{!media ? (
				<EmptyInboxComponent type="phone" />
			) : (
				<>
					<VideoComponent link={media.link || ''} />

					<ButtonWrapper>
						<ButtonComponent
							handlePress={onPreviousClick}
							copy={lang.prevText}
							disabled={logIndex === 0}
							data-testid={testIds.modalPrev}
						/>
						<ButtonComponent
							handlePress={onClose}
							copy={lang.okayText}
							data-testid={testIds.modalOkay}
						/>
						<ButtonComponent
							handlePress={onNextClick}
							copy={lang.nextText}
							disabled={!logs[logIndex + 1]}
							data-testid={testIds.modalNext}
						/>
					</ButtonWrapper>
				</>
			)}
		</Modal>
	);
};

const ButtonWrapper = styled(ModalButtons)`
	> * {
		flex: 1;
	}
`;

const Modal = styled(ModalComponent)`
	${ModalChildren} {
		--modal-top-padding: ${({ theme }) => theme.spacing[3]};
		--modal-bottom-padding: ${({ theme }) => theme.spacing[3]};
	}
`;

export default AudioVideoContentLayout;
