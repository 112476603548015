import React from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components/macro';

// components
import _Modal, { ModalChildren } from '../../components/ModalComponent';
import Carousel from '../../components/CarouselComponent';
import {
	Paragraph as _Paragraph,
	EnormousParagraph,
} from '../../components/Typography';
import Sentiment from '../../components/SentimentComponent';
import { CenterView, Col as _Col } from '../../components/Containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSackDollar,
	faHospitalAlt,
	faHeadSideMedical,
	faPollH,
} from '@fortawesome/pro-solid-svg-icons';

// contexts
import { LanguageContext } from '../../contexts/LanguageContext';
import { StatsContext } from '../../contexts/StatsContext';

// types
import {
	StatisticChangeValenceId,
	StyledTheme,
	Results,
	Valences,
} from '../../types';
// hooks
import useRestartGame from '../../hooks/useRestartGame';
import { cssVariables as SquiggleTitleCssVariables } from '../../components/SquiggleTitleComponent/SquiggleTitleComponent';
import CentralTextLayout from '../CentralTextLayout';
import { getReElectionIcon } from '../../utils/getSentimentIcon';
import { DeviceContext } from '../../contexts/DeviceContext';
import ButtonComponent from '../../components/ButtonComponent';
import { useMediaQuery } from 'react-responsive';

export type ResultLayoutProps = {
	isOpen: boolean;
	header?: React.ReactNode;
	footer?: React.ReactNode;
	results: Results;
	valences: Valences;
};

const ResultLayout: React.FC<ResultLayoutProps> = ({
	isOpen,
	header,
	footer,
	valences,
	results,
}) => {
	const copy = React.useContext(LanguageContext);
	const stats = React.useContext(StatsContext);
	const { device } = React.useContext(DeviceContext);
	const theme = useTheme();
	const { restartGame } = useRestartGame();
	// Keep first slide title in one line until it overflows
	const canTitleWrap = useMediaQuery({ query: 'max-width: 410px' });

	const reElectionIcon = getReElectionIcon(results.chanceOfReelection);

	const renderEconomySlide = () => (
		<CentralTextLayout
			canTitleWrap={canTitleWrap}
			title={copy.resultsLayout.economy.title}
			padBottom="md"
		>
			<IconWrapper>
				<FontAwesomeIcon
					icon={faSackDollar}
					size="2x"
					color={theme.colors.primary_dark}
				/>
			</IconWrapper>
			<Paragraph>{copy.resultsLayout.economy.resultTop}</Paragraph>
			<EnormousParagraphWithValence valence={valences.economy}>
				{`${results.economy}%`}{' '}
				{Math.sign(results.economy)
					? copy.resultsLayout.economy.resultMid.positive
					: copy.resultsLayout.economy.resultMid.negative}
			</EnormousParagraphWithValence>
			<Paragraph>{copy.resultsLayout.economy.resultBot}</Paragraph>
		</CentralTextLayout>
	);

	const renderSentimentSlide = () => (
		<CentralTextLayout
			title={copy.resultsLayout.sentiment.title}
			padBottom="md"
		>
			<Paragraph>{copy.resultsLayout.sentiment.result}</Paragraph>
			<CenterView
				css={`
					width: clamp(7.25rem, 7.25rem, 5rem);
					padding-top: ${({ theme }: StyledTheme) => theme.spacing[6]};
					padding-bottom: ${({ theme }: StyledTheme) => theme.spacing[14]};
				`}
			>
				<Sentiment sentiment={results.sentiment} />
			</CenterView>
		</CentralTextLayout>
	);

	const renderHealthcareSlide = () => (
		<CentralTextLayout
			title={copy.resultsLayout.healthcare.title}
			padBottom="md"
		>
			<IconWrapper>
				<FontAwesomeIcon
					icon={faHospitalAlt}
					size="2x"
					color={theme.colors.primary_dark}
				/>
			</IconWrapper>
			<Paragraph>{copy.resultsLayout.healthcare.resultTop}</Paragraph>
			<EnormousParagraphWithValence valence={valences.healthcare}>
				{stats.health + '%'}
			</EnormousParagraphWithValence>
			<Paragraph
				style={
					{
						'--strong-color':
							valences.healthcare === 'negative'
								? theme.colors.accents.orange
								: valences.healthcare === 'positive'
								? theme.colors.success_dark
								: theme.colors.text.dark,
					} as CSSProperties
				}
				dangerouslySetInnerHTML={{
					__html: copy.resultsLayout.healthcare.resultBot.replace(
						'{STAT}',
						`<strong>${valences.healthcare === 'positive' ? '-' : '+'}${
							results.healthcare
						}%</strong>`
					),
				}}
			/>
		</CentralTextLayout>
	);

	const renderWellbeingSlide = () => (
		<CentralTextLayout
			title={copy.resultsLayout.wellBeing.title}
			padBottom="md"
		>
			<IconWrapper>
				<FontAwesomeIcon
					icon={faHeadSideMedical}
					size="2x"
					color={theme.colors.primary_dark}
				/>
			</IconWrapper>
			<Paragraph>{copy.resultsLayout.wellBeing.resultTop}</Paragraph>
			<EnormousParagraphWithValence valence={valences.wellbeing}>
				{`${Math.abs(results.wellbeing)}% ${
					valences.wellbeing === 'negative'
						? copy.resultsLayout.wellBeing.resultMid.negative
						: copy.resultsLayout.wellBeing.resultMid.positive
				}`}
			</EnormousParagraphWithValence>
			<Paragraph>{copy.resultsLayout.wellBeing.resultBot}</Paragraph>
		</CentralTextLayout>
	);

	const renderPublicOpinionSlide = () => (
		<CentralTextLayout
			title={copy.resultsLayout.publicOpinion.title}
			padBottom="md"
		>
			<IconWrapper>
				<FontAwesomeIcon
					icon={faPollH}
					size="2x"
					color={theme.colors.primary_dark}
				/>
			</IconWrapper>
			<Paragraph>{copy.resultsLayout.publicOpinion.resultTop}</Paragraph>
			<EnormousParagraphWithValence valence={valences.publicOpinion}>
				{`${valences.publicOpinion === 'negative' ? '−' : '+'}${Math.abs(
					// Math.abs b/c we want to handle the sign ourselves
					results.publicOpinion
				)}%`}
			</EnormousParagraphWithValence>
			<Paragraph>{copy.resultsLayout.publicOpinion.resultBot}</Paragraph>
		</CentralTextLayout>
	);

	const renderOverallSlide = () => (
		<OverallSlide title={copy.resultsLayout.overall.title} padBottom="md">
			<IconWrapper>
				<ReElectionIcon src={reElectionIcon} alt="" />
			</IconWrapper>
			<Paragraph>{copy.resultsLayout.overall.resultTop}</Paragraph>
			<EnormousParagraphWithValence
				valence={valences.chanceOfReelection}
			>{`${results.chanceOfReelection}%`}</EnormousParagraphWithValence>
			<Paragraph>{copy.resultsLayout.overall.resultBot}</Paragraph>

			{device === 'mobile' && (
				<PlayAgain
					style={{ position: 'absolute' }}
					handlePress={restartGame}
					copy="Play again"
				/>
			)}
		</OverallSlide>
	);

	const slides = [
		renderEconomySlide(),
		renderSentimentSlide(),
		renderHealthcareSlide(),
		renderWellbeingSlide(),
		renderPublicOpinionSlide(),
		renderOverallSlide(),
	];

	const carouselCopy = {
		button: {
			prev: copy.prevText,
			next: copy.nextText,
			final: copy.playAgainText,
		},
	};

	return (
		<Modal
			contentLabel={copy.resultsLayout.contentLabel}
			hasWaveBackground
			isOpen={isOpen}
			header={header}
			footer={footer}
		>
			<Carousel
				showButtonOnLastSlide
				slides={slides}
				copy={carouselCopy}
				onClickFinal={restartGame}
			/>
		</Modal>
	);
};

const OverallSlide = styled(CentralTextLayout)`
	position: relative;
`;

const ReElectionIcon = styled.img`
	height: 2.75rem;
`;

const IconWrapper = styled(CenterView)`
	margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const EnormousParagraphWithValence = styled(EnormousParagraph)<{
	valence: StatisticChangeValenceId;
}>`
	line-height: 1;
	text-align: center;
	color: ${({ theme, valence }) => {
		if (valence === 'positive') return theme.colors.success_dark;
		if (valence === 'negative') return theme.colors.accents.orange;
		return theme.colors.text.dark;
	}};

	&& {
		margin-bottom: ${({ theme }) => theme.spacing[6]};
	}
`;

const Paragraph = styled(_Paragraph)`
	text-align: center;

	strong {
		color: ${({ theme }) => `var(--strong-color, ${theme.colors.text.dark})`};
		font-size: ${({ theme }) => theme.fontSize.xl};
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
`;

/**
 * Make it absolute so it doesn't add to slider height.
 * It can slot in where there's padding in CentralTextLayout
 */
const PlayAgain = styled(ButtonComponent)`
	position: absolute;
	left: 50%;
	bottom: 1rem;
	transform: translateX(-50%);
`;

const Modal = styled(_Modal)`
	${SquiggleTitleCssVariables.width}: 6.75rem;

	${ModalChildren} {
		--modal-top-padding: ${({ theme }) => theme.spacing[8]};
	}
`;

export default ResultLayout;
