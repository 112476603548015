import React from 'react';
import {
	faArrowLeft,
	faArrowRight,
	faUndo,
} from '@fortawesome/pro-solid-svg-icons';
import styled, { css, DefaultTheme } from 'styled-components/macro';
// carousel
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
	ScenarioSlideButtonCopy,
} from '../../components/Typography';
// components
import { Dots } from '../../components/Slider';
// layouts
import CentralTextLayout from '../../layouts/CentralTextLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mobileMediaQuery } from '../ModalComponent';
import { testIds } from '../../utils/testing';

export type CarouselComponentProps = {
	slides: React.ReactElement[];
	onClickFinal?: () => void;
	showButtonOnLastSlide?: boolean;
	copy: {
		horizontalLabel?: string;
		title?: string | string[]; // if the title is slide dependent, pass an array; otherwise single value
		button: {
			prev: string;
			next: string;
			final?: string;
		};
	};
};

type StyledTheme = { theme: DefaultTheme };

const CarouselComponent: React.FC<CarouselComponentProps> = ({
	slides,
	copy,
	onClickFinal,
	showButtonOnLastSlide,
}) => {
	// track active slide
	const [active, setActive] = React.useState(0);
	// meta
	const isFirstSlide = active === 0;
	const isLastSlide = active === slides.length - 1;
	const showFinalButton = Boolean(copy.button.final) && isLastSlide;

	// If a single title is passed, use that.
	// Otherwise get active title by accessing index of active title
	const title = (() => {
		if(Array.isArray(copy.title)) {
			return copy.title[active];
		} else if (copy.title) {
			return copy.title;
		}

		return undefined;
	})();

	return (
		<Carousel
			naturalSlideWidth={300}
			naturalSlideHeight={400}
			totalSlides={slides.length}
			dragEnabled={false}
			isIntrinsicHeight
		>
			{/* @ts-expect-error */}
			<BackButton
				data-testid={testIds.carouselPrev}
				$isFirstSlide={isFirstSlide}
				onClick={() => {
					if (!isFirstSlide) setActive(active - 1);
				}}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
				<ScenarioSlideButtonCopy
					css={`
						margin-top: ${({ theme }: StyledTheme) => theme.spacing.sm};
					`}
				>
					{copy.button.prev}
				</ScenarioSlideButtonCopy>
			</BackButton>

			<SliderContainer>
				{title && <CentralTextLayout title={title} />}
				<Slider
					css={`
						flex: initial;
					`}
				>
					{slides.map((content, i) => (
						// internally padding is used to maintain
						// aspect ratio, we don't want this as we
						// want the modal to fit its container
						// so we remove the bottom padding
						<Slide
							css={`
								padding-bottom: unset;
							`}
							index={i}
							key={`slide-${i}`}
						>
							{content}
						</Slide>
					))}
				</Slider>

				<Dots slides={slides.length} handleClick={(i) => setActive(i)} />
			</SliderContainer>

			{ !!(!isLastSlide || (isLastSlide && showButtonOnLastSlide)) && (
				<>
					{/* @ts-expect-error */}
					<NextButton
						data-testid={testIds.carouselNext}
						disabled={false}
						onClick={() => {
							if (!isLastSlide) setActive(active + 1);
							else if (onClickFinal) onClickFinal();
						}}
					>
						<FontAwesomeIcon icon={showFinalButton ? faUndo : faArrowRight} />
						<ScenarioSlideButtonCopy
							css={`
								margin-top: ${({ theme }: StyledTheme) => theme.spacing.sm};
								&& {
									line-height: ${showFinalButton ? 1 : 1.5};
								}
							`}
						>
							{showFinalButton ? copy.button.final : copy.button.next}
						</ScenarioSlideButtonCopy>
					</NextButton>
				</>
			) }
		</Carousel>
	);
};

const SliderButton = css`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: var(--button-width);
	padding: 0;
	border: none;
	background-color: transparent;
	color: ${({ theme }) => theme.colors.primary};

	@media ${mobileMediaQuery} {
		display: none;
	}
`;

const BackButton = styled(ButtonBack)<{ $isFirstSlide: boolean }>`
	${SliderButton}
	left: 0;
	opacity: ${({ $isFirstSlide }) => ($isFirstSlide ? '0' : '1')};
	${({ $isFirstSlide }) => $isFirstSlide && 'cursor: pointer;'};
`;

const NextButton = styled(ButtonNext)`
	${SliderButton}
	right: 0;
`;

const Carousel = styled(CarouselProvider)`
	--button-width: 56px;
	position: relative;
	padding: 0 var(--button-width);

	// TODO: Handle keyboard focus stuff
	* {
		outline: 0!important;
	}

	@media ${mobileMediaQuery} {
		--button-width: 16px;
	}
`;

const SliderContainer = styled.div`
	flex: 1;
`;

export default CarouselComponent;
