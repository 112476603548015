import styled, { css } from 'styled-components';

// Globals

const TypographyBase = css`
	font-family: ${({ theme: { font } }) => font.primary};
	min-height: min-content;
	line-height: 1.5;
	margin: 0;
	padding: 0;
`;

export const Tag = {
	Display: styled.h1`
		${TypographyBase}
	`,
	Title: styled.h1`
		${TypographyBase}
	`,
	Heading: styled.h2`
		${TypographyBase}
	`,
	Subheading: styled.h3`
		${TypographyBase}
	`,
	Paragraph: styled.p`
		${TypographyBase}
		line-height: 1.5;
	`,
	Subparagraph: styled.p`
		${TypographyBase}
		line-height: 1.5;
	`,
	Footnote: styled.p`
		${TypographyBase}
		line-height: 1.5;
	`,
	Script: styled.p`
		${TypographyBase}
		line-height: 1.5;
	`,
  Label: styled.label`
  	${TypographyBase}
		line-height: 1.5;
  `,
} as const;

export const Size = {
  Enormous: css`
  	font-size: ${({ theme: { fontSize } }) => fontSize.xxxxl};
  `,
	Huge: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.xxxl};
	`,
	ExtraLarge: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.xxl};
	`,
	Larger: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.xl};
	`,
	Large: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.lg};
	`,
	Medium: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.md};
	`,
	Small: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.sm};
	`,
	ExtraSmall: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.xs};
	`,
	Tiny: css`
		font-size: ${({ theme: { fontSize } }) => fontSize.xxs};
	`,
} as const;

export const Weight = {
	Light: css`
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
	`,
	SemiLight: css`
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiLight};
	`,
	Regular: css`
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
	`,
	SemiBold: css`
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
	`,
	Bold: css`
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
	`,
	ExtraBold: css`
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.extraBold};
	`,
} as const;

export const Color = {
	Light: css`
		color: ${({ theme: { colors } }) => colors.text.light};
	`,
  Success: css`
  		color: ${({ theme: { colors } }) => colors.success_dark};
  `,
  Danger: css`
    color: ${({ theme: { colors } }) => colors.accents.orange}; 
  `,
} as const;

export const Variant = {
	SmallCaps: css`
		font-variant-caps: all-small-caps;
		text-transform: uppercase;
	`,
	UpperCase: css`
		text-transform: uppercase;
	`,
} as const;

export const Tracking = {
	Wide: css`
		letter-spacing: 0.1rem;
	`,
	Regular: css`
		letter-spacing: initial;
	`,
} as const;
