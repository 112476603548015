import { useSelector } from '@xstate/react';
import React from 'react';
import { useHistory } from 'react-router';

// Contexts
import { DeviceContext } from '../../contexts/DeviceContext';
import { GameContext } from '../../contexts/GameContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';

// Hooks
import useNotification from '../../hooks/useNotification';

// Visuals
import DebriefLayout from '../../layouts/DebriefLayout';
import ScenarioLayout from '../../layouts/ScenarioLayout';
import { currentRoundSelector } from '../../machines/game/selectors';
import AnswerView from '../AnswerView';
import SimpleTile from '../../components/SimpleTileComponent';

import { testIds } from '../../utils/testing';

export type TasksTileViewProps = {};

const TasksTileView: React.FC<TasksTileViewProps> = () => {
	const [isQuestionOpen, setIsQuestionOpen] = React.useState(false);
	const [isDebriefOpen, setIsDebriefOpen] = React.useState(false);
	const [isTaskOpen, setIsTaskOpen] = React.useState(false);
	const lang = React.useContext(LanguageContext);
	const { startQuestion } = React.useContext(AnalyticsContext);
	const { gameService, onHeardScenario, onHearDebrief } = React.useContext(
		GameContext
	);
	const { device } = React.useContext(DeviceContext);
	const { triggerNotification } = useNotification('notify');
	const [triggered, setTriggered] = React.useState(false);
	const history = useHistory();

	const currentRound = useSelector(gameService, currentRoundSelector);
	const currentQuestion = useSelector(
		gameService,
		(state) => state.context.currentQuestion
	);
	const hasReceivedScenario = useSelector(gameService, (state) =>
		state.matches('playing.receivedScenario')
	);
	const hasReceivedQuestion = useSelector(gameService, (state) =>
		state.matches('playing.receivedQuestion')
	);
	const hasReceivedDebrief = useSelector(gameService, (state) =>
		state.matches('finished.receivedDebrief')
	);
	const hasTask =
		hasReceivedScenario || hasReceivedQuestion || hasReceivedDebrief;
	const isReceivingFeedback = useSelector(gameService, (state) =>
		state.matches('playing.receivingFeedback')
	);

	React.useEffect(() => {
		setTriggered(hasTask);
	}, [hasTask]);

	// If there is a scenario this is the start of the next question and needs to trigger the event
	// Fire Analytics Event :: startQuestion
	React.useEffect(() => {
		if (hasReceivedScenario) startQuestion(`${currentRound}`);
	}, [hasReceivedScenario]);

	React.useEffect(() => {
		if (!hasTask || triggered) return;
		triggerNotification();
		setTriggered(true);
	}, [hasTask, triggerNotification, triggered]);

	return (
		<>
			<SimpleTile
				title={lang.tileTitle.tasks}
				handleClick={() => {
					if (hasReceivedQuestion) {
						setIsQuestionOpen(true);
					} else if (hasReceivedDebrief) {
						setIsDebriefOpen(true);
					} else if (!isReceivingFeedback) {
						setIsTaskOpen(true);
					}
				}}
				hasIndicator={hasTask}
				icon="Tasks"
				data-testid={testIds.tasksTile}
				device={device}
			/>

			<ScenarioLayout
				isOpen={isTaskOpen}
				onClose={() => {
					onHeardScenario();
					setIsTaskOpen(false);
				}}
				currentRound={currentRound ?? 1}
				question={currentQuestion?.title}
				scenario={currentQuestion?.senario}
			/>

			<DebriefLayout
				isOpen={isDebriefOpen}
				onClose={() => {
					setIsDebriefOpen(false);
					onHearDebrief();
					history.push('/results');
				}}
			/>

			<AnswerView
				isOpen={isQuestionOpen}
				onClose={() => setIsQuestionOpen(false)}
			/>
		</>
	);
};

export default TasksTileView;
