import * as React from 'react';
//components
import { Dots } from '../../components/Slider';
import {
	CarouselProvider as _CarouselProvider,
	Slider as _Slider,
	Slide as _Slide,
	WithStore,
	CarouselInjectedProps,
} from 'pure-react-carousel';
// utils
import { fluidRange } from 'polished';
// types
import styled from 'styled-components';
import theme from '../../theme';
import SeasonTileView from '../../views/SeasonTileView';
import EmailTileView from '../../views/EmailTileView';
import PhoneTileView from '../../views/PhoneTileView';
import TasksTileView from '../../views/TasksTileView';
import HealthTileView from '../../views/HealthTileView';
import WellbeingTileView from '../../views/WellbeingTileView';
import EconomyTileView from '../../views/EconomyTileView';
import SocialTileView from '../../views/SocialTileView';
import PublicTrustTileView from '../../views/PublicTrustTileView';
import { DeviceContext } from '../../contexts/DeviceContext';
import { GameContext } from '../../contexts/GameContext';
import LogoComponent from '../../components/LogoComponent';
import { useSelector } from '@xstate/react';
import { hasNotificationSelector } from '../../machines/game/selectors';

type DeviceLayoutProps = {};

const DeviceLayout: React.FC<DeviceLayoutProps> = () => {
	const { device } = React.useContext(DeviceContext);

	return device === 'desktop' ? <DesktopLayout /> : <MobileLayout />;
};

/** Passed a component C and a grid template area A, returns C
 * wrapped in a flex container that will occupy the grid area
 *  denoted by A.
 *
 * @param component (ReactElement) the component to be wrapped
 * @param area (string) the grid template area for the component
 */
const asGridItem = (component: React.ReactElement, area: ChartGridArea) => () =>
	<GridItem area={area}>{component}</GridItem>;

const TileSeason = asGridItem(<SeasonTileView />, 'season');
const TileEmail = asGridItem(<EmailTileView />, 'email');
const TilePhone = asGridItem(<PhoneTileView />, 'phone');
const TileTasks = asGridItem(<TasksTileView />, 'tasks');
const ChartHealth = asGridItem(<HealthTileView />, 'healthcare');
const ChartWellbeing = asGridItem(<WellbeingTileView />, 'wellbeing');
const ChartEconomy = asGridItem(<EconomyTileView />, 'economy');
const TileSocial = asGridItem(<SocialTileView />, 'social');
const ChartPublicTrust = asGridItem(<PublicTrustTileView />, 'publicTrust');

const DesktopLayout = () => (
	<DesktopLayoutStyles>
		<TileSeason />
		<TilePhone />
		<TileEmail />
		<TileTasks />
		<ChartHealth />
		<ChartWellbeing />
		<ChartEconomy />
		<TileSocial />
		<ChartPublicTrust />
	</DesktopLayoutStyles>
);

const MobileLayoutLeft = () => (
	<MobileLayoutLeftStyles>
		<TileSeason />
		<TilePhone />
		<TileEmail />
		<TileTasks />
		<TileSocial />
	</MobileLayoutLeftStyles>
);

const MobileLayoutRight = () => (
	<MobileLayoutRightStyles>
		<ChartHealth />
		<ChartWellbeing />
		<ChartEconomy />
		<ChartPublicTrust />
		<Logo />
	</MobileLayoutRightStyles>
);

const slides = [<MobileLayoutLeft />, <MobileLayoutRight />];

interface MobileLayoutControllerProps extends CarouselInjectedProps {
	readonly isReceivingFeedback: boolean;
	readonly hasNotification: boolean;
}
class _MobileLayoutController extends React.Component<MobileLayoutControllerProps> {
	componentDidUpdate(prevProps: MobileLayoutControllerProps) {
		// Go to the 2nd slide when feedback is being received
		if (!prevProps.isReceivingFeedback && this.props.isReceivingFeedback) {
			this.props.carouselStore.setStoreState({ currentSlide: 1 });
		}

		// Go to the 1st slide when the user gets a notification
		if (!prevProps.hasNotification && this.props.hasNotification) {
			this.props.carouselStore.setStoreState({ currentSlide: 0 });
		}
	}

	render() {
		return (
			<>
				<SliderContainer>
					<Slider>
						{slides.map((slide, i) => (
							<Slide
								style={{ paddingBottom: 'unset' }}
								index={i}
								key={`mob-slide-${i}`}
							>
								{slide}
							</Slide>
						))}
					</Slider>
				</SliderContainer>
				<Dots isHollow slides={slides.length} />
			</>
		);
	}
}

const MobileLayoutController = WithStore(_MobileLayoutController);

const MobileLayout = () => {
	const { gameService } = React.useContext(GameContext);
	const isReceivingFeedback = useSelector(gameService, state => state.matches('playing.receivingFeedback'));
	const hasNotification = useSelector(gameService, hasNotificationSelector);

	return (
		<CarouselProvider
			// we have to pass the 'natural' props
			// because they are required
			// even tough isIntrinsicHeight disables them 🤷🏻‍♂️
			naturalSlideHeight={0}
			naturalSlideWidth={0}
			totalSlides={2}
			dragEnabled
			touchEnabled
			isIntrinsicHeight
		>
			<MobileLayoutController
				hasNotification={hasNotification}
				isReceivingFeedback={isReceivingFeedback}
			/>
		</CarouselProvider>
	);
};

const Grid = styled.div`
	display: grid;
	height: 100%;
`;

type ChartGridArea =
	| 'season'
	| 'phone'
	| 'email'
	| 'tasks'
	| 'healthcare'
	| 'economy'
	| 'wellbeing'
	| 'social'
	| 'publicTrust';

const DesktopLayoutStyles = styled(Grid)`
	flex: 1;
	grid-template-areas:
		'season       phone        email        tasks'
		'season       phone        email        tasks'
		'healthcare   healthcare   economy      economy'
		'wellbeing    wellbeing    economy      economy'
		'social       social       publicTrust  publicTrust'
		'social       social       publicTrust  publicTrust';
	/* outer two cols are double size + flexible, inner two cols are single size and non-flexible */
	grid-template-columns: 0.32fr 0.18fr 0.18fr 0.32fr;
	grid-template-rows: repeat(6, 1fr);
	width: 100%;
	max-height: 647px;
	${fluidRange(
		{
			prop: 'gap',
			fromSize: theme.spacing[2],
			toSize: theme.spacing[8],
		},
		theme.breakPoints.md,
		theme.breakPoints.xl
	)}
`;

const MobileLayoutStyles = styled(Grid)`
	grid-template-columns: repeat(2, 1fr);
	grid-gap: ${({ theme }) => theme.spacing[4]};
`;

const MobileLayoutLeftStyles = styled(MobileLayoutStyles)`
	grid-template-areas:
		'season season'
		'phone  email'
		'tasks  tasks'
		'social social';
	grid-template-rows: repeat(4, 1fr);
`;

const MobileLayoutRightStyles = styled(MobileLayoutStyles)`
	grid-template-areas:
		'healthcare  healthcare'
		'wellbeing   wellbeing'
		'economy     economy'
		'publicTrust publicTrust';
	grid-template-rows: repeat(2, 13%) repeat(2, 26%);
`;

const GridItem = styled.div<{ area: string }>`
	display: flex;
	grid-area: ${(props) => props.area};
`;

// Carousel for mobile layout

const CarouselProvider = styled(_CarouselProvider)`
	flex: 1;
`;

const SliderContainer = styled.div`
	flex: 1;
	height: 100%;
`;

// make inner heights fit outer wrapper
const Slider = styled(_Slider)`
	height: inherit;
	& > .carousel__slider-tray-wrapper {
		height: inherit;
		& > .carousel__slider-tray {
			height: inherit;
		}
	}
`;

const Slide = styled(_Slide)`
	flex: 1;
	margin-right: ${({ theme }) => theme.spacing.xl};
	margin-left: ${({ theme }) => theme.spacing.xl};
`;

const Logo = styled(LogoComponent)`
	grid-column: 1 / -1;
	align-self: center;
	justify-self: center;
	width: auto;
	height: 3.75rem;
	opacity: 0.4;
`;

DeviceLayout.whyDidYouRender = {
	// logOnDifferentValues: true,
	customName: 'DeviceLayout',
};

export default DeviceLayout;
