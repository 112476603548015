import React from 'react';
import styled, { useTheme } from 'styled-components';
// components
import _Tile from '../TileComponent';
import TileTitle from '../TileTitleComponent';
import { CenterView as _CenterView, Col } from '../../components/Containers';
import { HashTagCopyDesktop, HashTagCopyMobile } from '../Typography';
import _Sentiment from '../SentimentComponent';
import { squigglePurple } from '../../assets';
// contexts
import { LanguageContext } from '../../contexts/LanguageContext';
import { faDove } from '@fortawesome/pro-solid-svg-icons';
// types
import {
	StatisticChangeValenceId,
	SentimentId,
	DeviceTypeId,
} from '../../types';
import { mediaQueries } from '../../styles/mediaQueries';

export type SocialMediaTileComponentProps = {
	valence: StatisticChangeValenceId;
	hashtags: string[];
	sentiment: SentimentId;
	deviceType: DeviceTypeId;
};

const SocialMediaTileComponent: React.FC<SocialMediaTileComponentProps> = ({
	sentiment,
	hashtags,
	valence,
	deviceType,
}) => {
	const theme = useTheme();
	const copy = React.useContext(LanguageContext);

	return (
		<Tile deviceType={deviceType}>
			<TileTitle
				icon={faDove}
				lightText
				title={copy.socialMediaTile.title}
				valence={valence}
			/>
			<RotatedIcon deviceType={deviceType}>
				<SVG
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 512 512"
				>
					<path
						fill={theme.colors.primary_dark}
						d="M116.65 219.35a15.68 15.68 0 0022.65 0l96.75-99.83c28.15-29 26.5-77.1-4.91-103.88C203.75-7.7 163-3.5 137.86 22.44L128 32.58l-9.85-10.14C93.05-3.5 52.25-7.7 24.86 15.64c-31.41 26.78-33 74.85-5 103.88zm143.92 100.49h-48l-7.08-14.24a27.39 27.39 0 00-25.66-17.78h-71.71a27.39 27.39 0 00-25.66 17.78l-7 14.24h-48A27.45 27.45 0 000 347.3v137.25A27.44 27.44 0 0027.43 512h233.14A27.45 27.45 0 00288 484.55V347.3a27.45 27.45 0 00-27.43-27.46zM144 468a52 52 0 1152-52 52 52 0 01-52 52zm355.4-115.9h-60.58l22.36-50.75c2.1-6.65-3.93-13.21-12.18-13.21h-75.59c-6.3 0-11.66 3.9-12.5 9.1l-16.8 106.93c-1 6.3 4.88 11.89 12.5 11.89h62.31l-24.2 83c-1.89 6.65 4.2 12.9 12.23 12.9a13.26 13.26 0 0010.92-5.25l92.4-138.91c4.88-6.91-1.16-15.7-10.87-15.7zM478.08.33L329.51 23.17C314.87 25.42 304 38.92 304 54.83V161.6a83.25 83.25 0 00-16-1.7c-35.35 0-64 21.48-64 48s28.65 48 64 48c35.2 0 63.73-21.32 64-47.66V99.66l112-17.22v47.18a83.25 83.25 0 00-16-1.7c-35.35 0-64 21.48-64 48s28.65 48 64 48c35.2 0 63.73-21.32 64-47.66V32c0-19.48-16-34.42-33.92-31.67z"
					/>
				</SVG>
			</RotatedIcon>
			<SentimentWrapper deviceType={deviceType}>
				<Sentiment sentiment={sentiment} />
			</SentimentWrapper>
			<SquigglyWrapper deviceType={deviceType}>
				<Icon src={squigglePurple} />
			</SquigglyWrapper>
			<List deviceType={deviceType}>
				{hashtags.map((copy) => (
					<ListItem key={`social-list-${copy}`} as="li">
						<span>{`#${copy}`}</span>
					</ListItem>
				))}
			</List>
		</Tile>
	);
};

const Tile = styled(_Tile)<{ deviceType: DeviceTypeId }>`
	background-color: ${({ theme }) => theme.colors.primary};
	position: relative;
	justify-content: flex-start;
	overflow: visible;
	// touch of margin for the overflowing icon
	margin-bottom: ${({ deviceType }) =>
		deviceType === 'desktop' ? '0rem' : '0.75rem'};
`;

const List = styled.ol<{ deviceType: DeviceTypeId }>`
	padding-top: ${({ theme }) => theme.spacing.lg};
	padding-left: ${({ theme }) => theme.spacing[2]};
	z-index: 9;
	list-style: none;
	// because hashtage are single 'words'
	word-wrap: break-word;
	// force list to wrap when icon overlaps
	max-width: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '70%';
		if (deviceType === 'mobile') return '67.5%';
	}};
`;

const ListItem = styled(HashTagCopyDesktop)`
	display: flex;
	margin-top: ${({ theme }) => theme.spacing.xs};

	span {
		flex: 1;
	}

	&:before {
		width: 1.75rem;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}

	&:nth-child(1):before {
		content: '1.';
	}
	&:nth-child(2):before {
		content: '2.';
	}
	&:nth-child(3):before {
		content: '3.';
	}
	&:nth-child(4):before {
		content: '4.';
	}
	&:nth-child(5):before {
		content: '5.';
	}

	@media ${mediaQueries.isMobileDash} {
		font-size: ${({ theme }) => theme.fontSize.sm};
	}
`;

const SentimentWrapper = styled(_CenterView)<{ deviceType: DeviceTypeId }>`
	position: absolute;
	align-self: flex-end;
	width: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '6rem';
		if (deviceType === 'mobile') return '4.875rem';
	}};
	right: ${({ deviceType }) =>
		deviceType === 'desktop' ? '-1.25rem' : '0.75rem'};
	bottom: ${({ deviceType }) =>
		deviceType === 'desktop' ? '-1.25rem' : '-0.75rem'};
`;

const Sentiment = styled(_Sentiment)`
	z-index: ${({ theme }) => theme.zIndexes.socialTile.sentiment};
`;

const SquigglyWrapper = styled(_CenterView)<{ deviceType: DeviceTypeId }>`
	position: absolute;
	align-self: flex-end;
	width: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '5.125rem';
		if (deviceType === 'mobile') return '4.125rem';
	}};
	top: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '1.75rem';
		if (deviceType === 'mobile') return '0.625rem';
	}};
	right: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '1rem';
		if (deviceType === 'mobile') return '0.875rem';
	}};
	z-index: ${({ theme }) => theme.zIndexes.socialTile.wave};
`;

const Icon = styled.img`
	width: 100%;
`;

const SVG = styled.svg`
	height: 100%;
	width: 100%;
`;

const RotatedIcon = styled(_CenterView)<{ deviceType: DeviceTypeId }>`
	margin: ${({ theme }) => theme.spacing.xs};
	width: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '7rem';
		if (deviceType === 'mobile') return '4.375rem';
	}};
	height: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '7rem';
		if (deviceType === 'mobile') return '4.375rem';
	}};
	transform: rotate(-30deg);
	position: absolute;
	// using percentage for right to help icon not underlap text
	right: 10%;
	// to account for rotation
	bottom: ${({ deviceType }) => {
		if (deviceType === 'desktop') return '1rem';
		if (deviceType === 'mobile') return '1.375rem';
	}};
	z-index: ${({ theme }) => theme.zIndexes.socialTile.rotatedIcon};
`;

export default SocialMediaTileComponent;
