import React from 'react';
import styled from 'styled-components';
import ArrowButtonComponent from '../../../components/ArrowButtonComponent';

import ButtonComponent from '../../../components/ButtonComponent';
import { SetupText } from '../../../components/Typography';
import { links } from '../../../config';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { mediaQueries } from '../../../styles/mediaQueries';
import { DualButtons } from './DualButtons';
import { useA11yFocus } from '../../../hooks/useA11yFocus';
import { testIds } from '../../../utils/testing';

type SetupIntroProps = {
	onAccept: () => void;
	onDecline: () => void;
};

export const SetupIntro: React.FC<SetupIntroProps> = ({
	onAccept,
	onDecline,
}) => {
	const [page, setPage] = React.useState(1);
	const lang = React.useContext(LanguageContext);
	const a11yfocus = useA11yFocus([page]);

	return (
		<Wrapper>
			<Scroller>
				{page === 1 && (
					<Text tabIndex={-1} ref={a11yfocus}>
						{lang.weWouldLikeToDoResearch}
					</Text>
				)}
				{page === 2 && (
					<Text tabIndex={-1} ref={a11yfocus}>
						{lang.language === 'en' ? (
							<>
								You do not need to give us this information to play the game, but doing so will really help us out with our research. For more information on what we do and how to contact us, click <a href="/participant_information_sheet_en.pdf" target="_blank" rel="noreferrer">here</a>. Are you happy to share your data with us and help out?
							</>
						) : (
							<>
								Nid oes angen i chi roi'r wybodaeth hon i ni i chwarae'r gêm, ond bydd gwneud hynny wir yn ein helpu gyda'n hymchwil. I gael mwy o wybodaeth am yr hyn rydyn ni'n ei wneud a sut i gysylltu â ni, cliciwch <a href="/participant_information_sheet_cy.pdf" target="_blank" rel="noreferrer">yma</a>. Ydych chi'n hapus i rannu'ch data gyda ni a helpu?
							</>
						)}
					</Text>
				)}
			</Scroller>

			{page === 1 && (
				<ArrowButtonComponent data-testid={testIds.dataCaptureNext} onClick={() => setPage(2)}>
					{lang.nextText}
				</ArrowButtonComponent>
			)}

			{page === 2 && (
				<DualButtons>
					<ButtonComponent
						isHollow
						copy={lang.preferNot}
						handlePress={onDecline}
						data-testid={testIds.dataCaptureDecline}
					/>
					<ButtonComponent
						copy={lang.sureThing}
						handlePress={onAccept}
						data-testid={testIds.dataCaptureAccept}
					/>
				</DualButtons>
			)}
		</Wrapper>
	);
};

const Scroller = styled.div`
	/* max-height: 10.375rem; */
	overflow: auto;
`;

const Text = styled(SetupText)`
	margin-bottom: 1.25rem;
	font-size: ${({ theme }) => theme.fontSize.xl};
	/* font-weight: 400; */

	@media ${mediaQueries.isMobileScene} {
		font-size: ${({ theme }) => theme.fontSize.lg};
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 220px;
`;
