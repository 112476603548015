import React from 'react';
import { useA11yFocus } from '../../../hooks/useA11yFocus';
import TypingComponent from '../../../components/TypingComponent';
import { SetupText } from '../../SetupScreen/components';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { testIds } from '../../../utils/testing';

type ResultsDebriefProps = {
	onDone: () => void;
};

export const ResultsDebrief: React.FC<ResultsDebriefProps> = ({ onDone }) => {
	const lang = React.useContext(LanguageContext);
	const [part, setPart] = React.useState(0);
	const a11yFocus = useA11yFocus([part]);

	const onNext = () => {
		setPart(part + 1);
	};

	const parts = React.useMemo(
		() => [
			<SetupText ref={a11yFocus} tabIndex={-1}>
				{lang.debriefOne}
			</SetupText>,
			<SetupText ref={a11yFocus} tabIndex={-1}>
				{lang.debriefTwo}
			</SetupText>,
			<SetupText ref={a11yFocus} tabIndex={-1}>
				{lang.debriefThree}
			</SetupText>,
		],
		[lang, a11yFocus]
	);

	return (
		<TypingComponent
			data-testid={testIds.resultsDebrief}
			onDone={onDone}
			onNext={onNext}
			parts={parts}
			finishButtonText={lang.letsTakeALook}
			nextButtonText={lang.okayText}
		/>
	);
};
