import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { DeviceTypeId } from '../../types';
import { mediaQueries } from '../../styles/mediaQueries';

type DeviceContextType = {
  device: DeviceTypeId;
};

export const DeviceContext = React.createContext<DeviceContextType>({
  device: 'mobile'
});

export const DeviceProvider: React.FC = (props) => {
  const isDesktop = useMediaQuery({ query: mediaQueries.isDesktopDash });

  const value: DeviceContextType = {
    device: isDesktop ? 'desktop' : 'mobile'
  }

	return (
		<DeviceContext.Provider value={value}>
			{props.children}
		</DeviceContext.Provider>
	);
};