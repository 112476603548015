import { Col } from '../Containers';
import styled from 'styled-components';
import { mediaQueries } from '../../styles/mediaQueries';

/**
 * Tile Component. Instance of styled component.
 */

const Tile = styled(Col)`
	--tile-padding-vert: ${({ theme }) => theme.spacing[3]};
	--tile-padding-horiz: ${({ theme }) => theme.spacing[5]};
	padding: var(--tile-padding-vert) var(--tile-padding-horiz);
	border-radius: ${({ theme }) => theme.radii.md};
	box-shadow: ${({ theme }) => theme.shadows.tile};

	@media ${mediaQueries.isMobileDash} {
		--tile-padding-horiz: ${({ theme }) => theme.spacing[3]};
		border-radius: 0.375rem;
	}
`;

export default Tile;
