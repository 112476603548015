import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Utils
import logError from '../../utils/logError/logError';

enum BodyType {
	'USER',
	'ANSWER',
	'EXPERT',
	'FINAL_SCORES',
}

type Body = UserBody | AnswerBody | ExpertBody | FinalScoreBody;

type UserBody = {
	type: BodyType.USER;
	uuid: string;
	age: number;
	gender: string;
	country: string;
	postCode?: string;
	subject: string;
};

type AnswerBody = {
	type: BodyType.ANSWER;
	uuid: string;
	round: number;
	question: string;
	answer: string;
	duration: number;
};

type ExpertBody = {
	type: BodyType.EXPERT;
	uuid: string;
	round: number;
	question: string;
	advice: string;
	duration: number;
	seenCount: number;
};

type FinalScoreBody = {
	type: BodyType.FINAL_SCORES;
	uuid: string;
	round: number;
	economy: number;
	healthcare: number;
	publicOpinion: number;
	wellbeing: number;
	sentiment: string;
	chanceOfReelection: number;
	duration: number;
};

type Question = {
	title: string;
	startTimestamp: number;
};

type Advice = {
	title: string;
	duration: number;
	seenCount: number;
};

type User = {
	age: number;
	gender: string;
	country: string;
	postCode?: string;
	subject: string;
};

type GameStats = {
	economy: number;
	healthcare: number;
	publicOpinion: number;
	wellbeing: number;
	sentiment: string;
	chanceOfReelection: number;
};

type AnalyticsContextType = {
	userDetails: (user: User) => void;
	openExpertAdvice: () => void;
	closeExpertAdvice: (title: string) => void;
	startQuestion: (title: string) => void;
	answerQuestion: (answer: string) => void;
	completeGame: (stats: GameStats) => void;
	restartGame: () => void;
};

export const AnalyticsContext = React.createContext({} as AnalyticsContextType);

export const AnalyticsContextProvider: React.FC = (props) => {
	const [uuid, setUuid] = useState<string>(uuidv4());
	const [round, setRound] = useState<number>(1);
	const [question, setQuestion] = useState<Question | undefined>(undefined);
	const [expertAdvice, setExpertAdvice] = useState<Advice[]>([]);

	const [gameStartTimestamp, setGameStartTimestamp] = useState<number>(
		Date.now()
	);
	const [adviceStartTimestamp, setAdviceStartTimestamp] = useState<number>(
		Date.now()
	);

	const request = async (payload: Body) => {
		try {
			const apiUrl = process.env.REACT_APP_API_URL;

			if (!apiUrl) {
				throw new Error('No api url found');
			}
			await fetch(apiUrl, {
				method: 'POST',
				mode: 'no-cors',
				body: JSON.stringify(payload),
			});
		} catch (error) {
			logError(error, {
				noNotify: true,
			});
		}
	};

	const userDetails = async (user: User) => {
		request({
			type: BodyType.USER,
			uuid,
			age: user.age,
			gender: user.gender,
			country: user.country,
			postCode: user.postCode,
			subject: user.subject,
		});
	};

	const startQuestion = (title: string) => {
		setQuestion({
			title,
			startTimestamp: Date.now(),
		});
	};

	const answerQuestion = async (answer: string) => {
		// Get duration of question open in seconds
		const sessionDuration =
			(Date.now() - (question?.startTimestamp ?? 0)) / 1000;

		// Question / Answer Request
		request({
			type: BodyType.ANSWER,
			uuid,
			answer,
			round,
			question: question?.title ?? '',
			duration: sessionDuration,
		});

		// Expert Advice Requests
		// A for loop is used to avoid overloading the lambda and potentially causing data to be missed
		for (const index in expertAdvice) {
			await request({
				type: BodyType.EXPERT,
				uuid,
				round,
				question: question?.title ?? '',
				advice: expertAdvice[index].title,
				duration: expertAdvice[index].duration,
				seenCount: expertAdvice[index].seenCount,
			});
		}

		// Clear Values
		setExpertAdvice([]);
		setQuestion(undefined);
	};

	const openExpertAdvice = () => {
		setAdviceStartTimestamp(Date.now());
	};

	const closeExpertAdvice = (title: string) => {
		// Get duration of expert advice open in seconds
		const sessionDuration = (Date.now() - adviceStartTimestamp) / 1000;
		const tempExpertAdvice = [...expertAdvice];

		const index = tempExpertAdvice.findIndex(
			(advice) => advice.title === title
		);
		if (index >= 0) {
			tempExpertAdvice[index] = {
				title,
				duration: tempExpertAdvice[index].duration += sessionDuration,
				seenCount: tempExpertAdvice[index].seenCount += 1,
			};
		} else {
			tempExpertAdvice.push({
				title,
				duration: sessionDuration,
				seenCount: 1,
			});
		}

		setExpertAdvice(tempExpertAdvice);
	};

	const completeGame = (stats: GameStats) => {
		// Get total session time in seconds
		const sessionDuration = (Date.now() - gameStartTimestamp) / 1000;

		request({
			...stats,
			type: BodyType.FINAL_SCORES,
			uuid,
			round,
			duration: sessionDuration,
		});
	};

	const restartGame = () => {
		// Round Count
		setRound(round + 1);
		setGameStartTimestamp(Date.now());
	};

	return (
		<AnalyticsContext.Provider
			value={{
				userDetails,
				startQuestion,
				openExpertAdvice,
				closeExpertAdvice,
				answerQuestion,
				completeGame,
				restartGame,
			}}
		>
			{props.children}
		</AnalyticsContext.Provider>
	);
};
