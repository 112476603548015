import React from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';

import _Clouds from '../../components/CloudsComponent';
import { 
	sceneMountains,
	sceneMountainsAvif,
	sceneMountainsWebp,
	sceneHill,
} from '../../assets';
import { mediaQueries } from '../../styles/mediaQueries';
import { ReactComponent as TreesLeft } from '../../assets/trees-left.svg';
import { ReactComponent as TreesRight } from '../../assets/trees-right.svg';

export type SceneLayoutProps = {
	className?: string;
	style?: {};
}

const SceneLayout: React.FC<SceneLayoutProps> = ({ 
	children, 
	className, 
	style
}) => {
	const isDesktop = useMediaQuery({ query: mediaQueries.isDesktopScene });

	return (
		<Wrapper
			className={className}
			style={style}
		>
			<Clouds />
			<Mountains>
				<source srcSet={sceneMountainsAvif} type="image/avif" />
				<source srcSet={sceneMountainsWebp} type="image/webp" />
				<img src={sceneMountains} alt="" />
			</Mountains>
			<Hill src={sceneHill} alt="" />

			{ isDesktop ? (
				<DesktopShape className="circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 818">
					<path
						d="M721 125c397.645 0 720 322.355 720 720v98H1v-98c0-397.645 322.355-720 720-720z"
						fill="#F2F4F6"
						fillRule="evenodd"
					/>
				</DesktopShape>
			) : (
				<MobileShape width={390} height={202} viewBox="0 0 390 202" xmlns="http://www.w3.org/2000/svg"><path d="M194.5.211c128.13 0 232 103.87 232 232v31.578h-464V232.21c0-128.13 103.87-232 232-232z" fill="#F2F4F6" fillRule="evenodd" opacity={0.801} /></MobileShape>
			) }

			<TreesLeft className="trees trees-left" />
			<TreesRight className="trees trees-right" />

			<Content>
				{children}
			</Content>
		</Wrapper>
	);
};

const imgStyles = `
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const Mountains = styled.picture`
	img {
		${imgStyles}
		z-index: 2;

		@media screen and (prefers-reduced-motion: no-preference) {
			animation-name: fadeInUp;
			animation-fill-mode: forwards;
			animation-duration: var(--mountains-duration);
		}
	}
`;

const Hill = styled.img`
	${imgStyles}
	z-index: 3;

	@media screen and (prefers-reduced-motion: no-preference) {
		animation-name: moveUp;
		animation-fill-mode: forwards;
		animation-duration: var(--hill-duration);
	}
`;

const Clouds = styled(_Clouds)`
	${imgStyles}
	width: 5760px;
	height: 818px;
	z-index: 3;
`;

const Content = styled.div`
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	z-index: 5;

	@media screen and (prefers-reduced-motion: no-preference) {
		opacity: 0;
		animation-fill-mode: forwards;
		animation-name: fadeIn;
		animation-duration: var(--content-duration);
		animation-delay: var(--content-delay);
	}
`;

const circleAnimation = `
	@media screen and (prefers-reduced-motion: no-preference) {
		animation-name: moveUp;
		animation-fill-mode: forwards;
		animation-duration: var(--circle-duration);
		animation-delay: var(--circle-delay);
		transform: translateY(100%);
	}
`;

const MobileShape = styled.svg`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 202px;
	overflow: hidden;
	z-index: 4;
	${circleAnimation}

	svg {
		--offset: -2rem;
		position: absolute;
		bottom: var(--offset);
		left: var(--offset);
		width: calc(100% + calc((var(--offset) * -1) * 2));
	}
`;

const DesktopShape = styled.svg`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	${circleAnimation}
`;

const Wrapper = styled.div`
	--duration: 1800ms;
	--content-delay: calc(var(--duration) * 0.77);
	--content-duration: calc(var(--duration) * 0.22);
	--circle-delay: calc(var(--duration) * 0.33);
	--circle-duration: calc(var(--duration) * 0.33);
	--hill-duration: calc(var(--duration) * 0.33);
	--mountains-duration: calc(var(--duration) * 0.33);
	--trees-delay: calc(var(--duration) * 0.22);
	--trees-duration: calc(var(--duration) * 0.33);
	background: #FFF;

	.trees {
		--trees-x: -3vw;
		position: fixed;
		bottom: -2vw;
		width: clamp(200px, 50%, 355px);
		height: auto;
		bottom: -2vw;
		z-index: 3;

		&-left {
			left: var(--trees-x);
		}
		&-right {
			right: var(--trees-x);
		}

		@media (max-width: 472px) {
			--trees-x: -75px;
			bottom: 10px;
			width: 300px;
		}

		@media screen and (prefers-reduced-motion: no-preference) {
			transform: translateY(100%);
			animation-name: moveUp;
			animation-fill-mode: forwards;
			animation-duration: var(--trees-duration);
			animation-delay: var(--trees-delay);
		}
	}

	@keyframes fadeInUp {
		from {
			transform: translateY(100%);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@keyframes moveUp {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0);
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export default SceneLayout;