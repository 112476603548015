import React from 'react';
import Typist from 'react-typist';
import { useReduceMotion } from '../../hooks/usePreferReduceMotion';
import styled from 'styled-components';

import ArrowButtonComponent from '../ArrowButtonComponent';
import { testIds } from '../../utils/testing';

export type TypingComponentProps = React.HTMLAttributes<HTMLDivElement> & {
	className?: string;
	nextButtonText: string;
	finishButtonText: string;
	onDone: () => void;
	parts: JSX.Element[];
	onNext?: (index: number) => void;
};

export const TypingComponent: React.FC<TypingComponentProps> = ({
	className,
	finishButtonText,
	nextButtonText,
	onDone,
	onNext,
	parts,
	...props
}) => {
	// isFinshed is used to force a re-render of TypingComponent, so that it can receive new content
	const [isFinished, setIsFinished] = React.useState(false);
	const [isTyping, setIsTyping] = React.useState(true);
	const [activePartIndex, setActivePartIndex] = React.useState(0);
	const prefersReducedMotion = useReduceMotion();

	React.useEffect(() => {
		setIsTyping(true);
	}, [activePartIndex, onNext]);
	/**
	 * If there's another section to type out, increase the activePartIndex
	 * Otherwise, call onDone()
	 */
	const moveOn = React.useCallback(() => {
		const nextPartIndex = activePartIndex + 1;
		const hasNextPart = !!parts?.[nextPartIndex];

		if (hasNextPart) {
			if (onNext) {
				onNext(activePartIndex);
			}
			setActivePartIndex(nextPartIndex);
			setIsFinished(false);
		} else {
			onDone();
		}
	}, [activePartIndex, onDone, parts, onNext]);

	React.useEffect(() => {
		if (!isFinished) return;
		moveOn();
	}, [isFinished, moveOn]);

	const isLastPart = activePartIndex === parts.length - 1;

	return (
		<Brief {...props}>
			{!isFinished &&
				/* Only animate the text is user does not prefer reduced motion */
				(!prefersReducedMotion ? (
					<Wrapper avgTypingDelay={20} onTypingDone={() => setIsTyping(false)}>
						{parts[activePartIndex]}
					</Wrapper>
				) : (
					<>{parts[activePartIndex]}</>
				))}

			<NextButton
				data-testid={testIds.typerNext}
				disabled={isTyping && process.env.NODE_ENV !== 'development' && !prefersReducedMotion}
				onClick={() => {
					setIsFinished(true);
				}}
			>
				{isLastPart ? finishButtonText : nextButtonText}
			</NextButton>
		</Brief>
	);
};

export const Delay = Typist.Delay;

const Wrapper = styled(Typist)`
	.Cursor {
		display: none;
	}
`;

const NextButton = styled(ArrowButtonComponent)`
	display: inline-flex;
	margin-top: auto;
`;

const Brief = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 9.125rem;
`;

export default TypingComponent;
