import React from 'react';
// components
import _Tile from '../TileComponent';
import TileTitle from '../TileTitleComponent';
import { Col, CenterView } from '../Containers';
import { ProgressBar } from './components';
// charts
import {
	AreaChart,
	BarChart,
	Bar,
	CartesianGrid,
	Cell,
	Area,
	YAxis,
	ResponsiveContainer,
	LabelList,
	Tooltip,
} from 'recharts';
// types
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faLock } from '@fortawesome/pro-solid-svg-icons';
import {
	StatisticChangeId,
	ChartData,
	StatisticChangeValenceId,
	ChartPercentageLabelId,
} from '../../types';
// utils
import { last as lastOf } from 'lodash';
import { StrictUnion } from '../../types/utils';
// styled
import styled, { useTheme } from 'styled-components';
import ChartTooltipComponent from '../ChartTooltipComponent';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { getProportionalRangeValue } from '../../utils/statistics';
import { LanguageContext } from '../../contexts/LanguageContext';

type ChartId = 'area' | 'bar' | 'progress';

type ChartPropsBase = {
	indicator: StatisticChangeId;
	toolTip?: string;
	type: ChartId;
	icon: IconDefinition;
	title: string;
	data: ChartData;
	valence: StatisticChangeValenceId;
	isLocked: boolean;
	percentage?: number;
	percentageLabel?: ChartPercentageLabelId;
};

type AreaChartProps = ChartPropsBase;

type BarChartProps = ChartPropsBase;

type BarColors = {
	bar: string;
	background: string;
};

type ProgressChartProps = ChartPropsBase & {
	barColors: BarColors;
};

export type ChartComponentProps = StrictUnion<
	AreaChartProps | BarChartProps | ProgressChartProps
>;

const ChartComponent: React.FC<ChartComponentProps> = ({
	data,
	type,
	title,
	icon,
	barColors,
	toolTip,
	isLocked,
	valence,
	indicator,
	percentage,
	percentageLabel,
}) => {
	const theme = useTheme();
	const lang = React.useContext(LanguageContext);

	// force the charts to re-render on resize
	const [render, setRender] = React.useState(0);

	const handleResize = () => {
		let isDebouncing;
		if (!isDebouncing) {
			isDebouncing = true;
			setTimeout(() => {
				setRender((old) => old + 1);
				isDebouncing = false;
			}, 200);
		}
	};

	React.useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const chartPropsBase = {
		margin: { top: 12, right: 12, left: 0, bottom: 12 },
		data: isLocked ? [] : data,
	}

	// we render these for all the rechart charts
	// and pass them as url(#colorBar)
	const renderGradients = () => (
		<defs>
			<linearGradient
				id="colorBarPositive"
				x1="0.3"
				y1="0"
				x2="0"
				y2="1"
				gradientTransform="rotate(330)"
			>
				<stop
					offset="5%"
					stopColor={theme.colors.accents.pink}
					stopOpacity={1}
				/>
				<stop
					offset="95%"
					stopColor={theme.colors.accents.yellow}
					stopOpacity={1}
				/>
			</linearGradient>
			<linearGradient
				id="colorBarNegative"
				x1="0.3"
				y1="0"
				x2="0"
				y2="1"
				gradientTransform="rotate(330)"
			>
				<stop
					offset="5%"
					stopColor={theme.colors.primary_light}
					stopOpacity={1}
				/>
				<stop
					offset="95%"
					stopColor={theme.colors.accents.lime}
					stopOpacity={1}
				/>
			</linearGradient>
		</defs>
	);

	const labelStyles = {
		fontFamily: theme.font.primary,
		fill: theme.colors.text.light,
		stroke: theme.colors.text.light,
		fontSize: '0.625rem',
	}

	const isNegative = (x: number) => x < 0;
	const getBarGradient = (percent: number) =>
		isNegative(percent) ? 'url(#colorBarNegative)' : 'url(#colorBarPositive';

	const getChartTooltipPrefix = (index: number) => {
		return index === 0 ? `${lang.startingPoint}: ` : `${lang.scenario} ${index}: `;
	}

	const renderBarChart = () => (
		<BarChart {...chartPropsBase}>
			{renderGradients()}
			{/* see comment on renderGradients(). Fill value refers to the svg gradient
			defined there */}
			<Bar dataKey="percent" fill="url(#colorBar)" isAnimationActive={true}>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={getBarGradient(entry.percent)} />
				))}
				<LabelList
					dataKey="percent"
					position="top"
					style={labelStyles}
					// TODO
					// @ts-expect-error
					formatter={(val) => val + '%'}
				/>
			</Bar>
			{/* this only applies to publicHealth chart, so if we need two bar charts need to abstract */}
			<CartesianGrid
				strokeDasharray="0 0"
				stroke={theme.colors.text.light}
				strokeWidth={0.5}
			/>
			{/* API is a bit yucky. All the tick-related stuff refers to the
			labels for the Y axis. We just want one value, 'normal' so we use
			the formatter.
			See https://recharts.org/en-US/api/YAxis */}
			<YAxis
				tickFormatter={(tick, i) => (tick === 0 ? 'Normal' : '')}
				ticks={[0]}
				tick={labelStyles}
				axisLine={false}
				interval={0}
				minTickGap={1}
				tickLine={false}
				domain={[-100, 100]}
			/>
			<Tooltip 
				cursor={{ fill: 'rgba(255, 255, 255, 0.25)' }}
				content={({ payload, label }) => (
					<ChartTooltipComponent
						payload={payload}
						label={label}
						gradientColor="#82ca9d"
						valueFormatter={(val: ValueType | undefined) => {
							return `${getChartTooltipPrefix(label)} ${val}%`
						}}
          />
        )}
			/>
		</BarChart>
	);

	const renderAreaChart = () => (
		<AreaChart {...chartPropsBase}>
			{renderGradients()}
			<CartesianGrid
				strokeDasharray="0 0"
				stroke={theme.colors.text.light}
				strokeWidth={0.5}
			/>
			<Area
				type="linear"
				dataKey="percent"
				stroke={theme.colors.text.light}
				fill="transparent"
				strokeWidth="2"
				isAnimationActive={false}
				dot={{
					stroke: theme.colors.accents.yellow,
					fill: theme.colors.accents.yellow,
					strokeWidth: 6,
				}}
			/>
			{/* This only applies to economy, so if we need two distinct area graphs need to abstract*/}
			{/* API is a bit yucky. All the tick-related stuff refers to the
       labels for the Y axis. We take values as percentages, but
       we want to display dollar values. Hence the formatter.
       See https://recharts.org/en-US/api/YAxis */}
			<YAxis
				tickFormatter={(tick, i) => `$` + [30, 35, 40, 45, 50, 55][i]}
				ticks={[0, 20, 40, 60, 80, 100]}
				tick={labelStyles}
				axisLine={false}
				tickCount={5}
				// show all ticks
				interval={0}
				minTickGap={1}
				width={30}
				tickLine={false}
				domain={[0, 100]}
			/>

			<Tooltip 
				content={({ payload, label }) => (
          <ChartTooltipComponent
            payload={payload}
            label={label}
            gradientColor="#82ca9d"
						valueFormatter={(val: ValueType | undefined) => {
							const value = getProportionalRangeValue([0, 100], [30000, 55000], (val ?? 0) as number);
							return `${getChartTooltipPrefix(label)} ${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
						}}
          />
        )}
			/>
		</AreaChart>
	);

	const renderProgressChart = () => {
		return(
			<ProgressBar 
				value={lastOf(data)?.percent as number}
				colors={barColors}
			/>
		);
	};

	const renderChart = (() => {
		if (type === 'bar') return renderBarChart;
		if (type === 'area') return renderAreaChart;
		if (type === 'progress') return renderProgressChart;
		// default
		return renderAreaChart;
	})();

	return (
		<Tile isLocked={isLocked} key={`${render}-chart-${type}-tile`}>
			<ChartWrapper isLocked={isLocked}>
				<TileTitle
					title={title}
					tooltipCopy={toolTip}
					icon={icon}
					arrowIndicator={indicator}
					valence={valence}
					percentageLabel={percentageLabel}
					percentage={percentage}
					lightText
				/>

				<ChartInnerWrapper>
					<ResponsiveContainer
						key={`${render}-chart-${type}`}
						width="100%"
						height={type === 'progress' ? 'auto' : '100%'}
					>
						{renderChart()}
					</ResponsiveContainer>
				</ChartInnerWrapper>
			</ChartWrapper>
			{isLocked && (
				<IconWrapper>
					<FontAwesomeIcon
						icon={faLock}
						size="2x"
						color={theme.colors.primary}
					/>
				</IconWrapper>
			)}
		</Tile>
	);
};

// We're using a mix of rgba and opacity
// because only some child elements must
// be transparent (e.g., not the lock icon)

const Tile = styled(_Tile)<{ isLocked: boolean }>`
	position: relative;
	background-color: ${({ isLocked, theme }) => {
		const { red, green, blue } = theme.colors.rgb.primary;
		const alpha = isLocked ? 0.5 : 1;
		return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
	}};
	pointer-events: ${({ theme, isLocked }) => isLocked ? 'none' : 'initial'};
`;

const ChartWrapper = styled(Col)<{ isLocked: boolean }>`
	opacity: ${({ isLocked }) => (isLocked ? 0.5 : 1)};
`;

const IconWrapper = styled(CenterView)`
	position: absolute;
	align-self: center;
	justify-self: center;
`;

const ChartInnerWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	align-content: center;
`;

ChartComponent.whyDidYouRender = {
	logOnDifferentValues: true,
	customName: 'Chart'
}
export default ChartComponent;
