import React from 'react';
import styled from 'styled-components';
import 'pure-react-carousel/dist/react-carousel.es.css';
// components
import Modal from '../../components/ModalComponent';
import Button from '../../components/ButtonComponent';
import Carousel from '../../components/CarouselComponent';
import { Row } from '../../components/Containers';
import { Paragraph } from '../../components/Typography';
import CentralTextLayout from '../CentralTextLayout';
import ButtonComponent from '../../components/ButtonComponent';
// contexts
import { LanguageContext } from '../../contexts/LanguageContext';
import { DeviceContext } from '../../contexts/DeviceContext';
import { testIds } from '../../utils/testing';

export type ScenarioLayoutProps = {
	currentRound: number;
	question: string | undefined;
	scenario: string | undefined;
	isOpen: boolean;
	onClose: () => void;
};

const ScenarioLayout: React.FC<ScenarioLayoutProps> = ({ 
	currentRound, 
	question, 
	scenario, 
	isOpen, 
	onClose,
}) => {
	const { device } = React.useContext(DeviceContext);
	const lang = React.useContext(LanguageContext);
	const scenarioTitle = `Scenario ${currentRound}`;
	const padBottom = device === 'mobile' ? 'sm' : 'md';

	const slides = [
		<CentralTextLayout padBottom={padBottom}>
			<Paragraph>{lang.scenarioLayout.firstSlide.top}</Paragraph>
			<Paragraph>{lang.scenarioLayout.firstSlide.bottom}</Paragraph>
		</CentralTextLayout>,
		<CentralTextLayout padBottom={padBottom}>
			<Question data-testid={testIds.scenarioQuestion}>{question}</Question>
			<Paragraph>{scenario}</Paragraph>
		</CentralTextLayout>,
		<CentralTextLayout padBottom={padBottom}>
			<Paragraph>{lang.scenarioLayout.thirdSlide.top}</Paragraph>
			<Paragraph>{lang.scenarioLayout.thirdSlide.middle}</Paragraph>
			<Paragraph>{lang.scenarioLayout.thirdSlide.bottom}</Paragraph>
			<ButtonContainer>
				<Button
					copy={lang.scenarioLayout.thirdSlide.button}
					handlePress={onClose}
					data-testid={testIds.scenarioModalComplete}
				/>
			</ButtonContainer>
		</CentralTextLayout>,
	];

	return (
		<Modal
			hasWaveBackground
			isOpen={isOpen}
			contentLabel={scenarioTitle}
			onClose={onClose}
			verticalText={lang.scenarioLayout.horizontalLabel}
		>
			{currentRound === 1 ? (
				<Carousel 
					slides={slides} 
					copy={{ 
						...lang.scenarioLayout,
						title: scenarioTitle
					}} 
				/>
			) : (
				<CentralTextLayout 
					title={scenarioTitle} 
					padBottom="md"
				>
					<Question data-testid={testIds.scenarioQuestion}>{question}</Question>
					<Paragraph>{scenario}</Paragraph>

					<ButtonContainer>
						<ButtonComponent
							copy={lang.scenarioLayout.thirdSlide.button}
							handlePress={onClose}
							data-testid={testIds.scenarioModalComplete}
						/>
					</ButtonContainer>
				</CentralTextLayout>
			)}
		</Modal>
	);
};

const ButtonContainer = styled(Row)`
	flex: initial;
	margin-top: ${({ theme }) => theme.spacing.lg};
`;

const Question = styled(Paragraph)`
	font-size: ${({ theme }) => theme.fontSize.xxl};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default ScenarioLayout;
