import * as React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useInterpret } from '@xstate/react';
import { random } from 'lodash';

import { GameMachine } from '../../machines/game';
import {
	Answer,
	GetQuestionsQuery,
	GetQuestionsQueryVariables,
} from '../../types';
import { StatsContext } from '../StatsContext';
import { LanguageContext } from '../LanguageContext';
import logError from '../../utils/logError/logError';
import { isE2ETest } from '../../utils/testing';
import { ActorRefFrom } from 'xstate';

type GameContextType = {
	gameService: ActorRefFrom<typeof GameMachine>;
	onAnswerChosen: (answer: Answer) => void;
	onHearAdvice: () => void;
	onHearDebrief: () => void;
	onHeardScenario: () => void;
	onHearQuestion: () => void;
	onHearScenario: () => void;
	onPlayAgain: () => void;
	onStartGame: () => void;
};

export const GameContext = React.createContext({} as GameContextType);

export const GameProvider: React.FC = (props) => {
	const { updateStats } = React.useContext(StatsContext);
	const { language } = React.useContext(LanguageContext);

	const client = useApolloClient();

	const gameService = useInterpret(GameMachine, {
		services: {
			loadQuestions: async () => {
				try {
					const { data } = await client.query<
						GetQuestionsQuery,
						GetQuestionsQueryVariables
					>({
						query: GET_QUESTIONS,
						variables: {
							locale: language === 'en' ? 'en-US' : 'cy',
						},
						errorPolicy: 'all',
					});

					if (!data?.allQuestions?.items?.length) {
						throw new Error('Could not load questions');
					}

					return data.allQuestions.items;
				} catch (e) {
					logError(e);
					throw e;
				}
			},
			updateHealth: async (ctx) => {
				const health = getStatIncrease(
					ctx.currentAnswer,
					ctx.currentAnswer?.healthMinimum,
					ctx.currentAnswer?.healthMaximum
				);

				updateStats({
					health,
				});
			},
			updateWellbeing: async (ctx) => {
				const wellBeing = getStatIncrease(
					ctx.currentAnswer,
					ctx.currentAnswer?.wellbeingMinimum,
					ctx.currentAnswer?.wellbeingMaximum
				);

				updateStats({
					wellBeing,
				});
			},
			updateEconomy: async (ctx) => {
				const economy = getStatIncrease(
					ctx.currentAnswer,
					ctx.currentAnswer?.economyMinimum,
					ctx.currentAnswer?.economyMaximum
				);

				updateStats({
					economy,
				});
			},
			updatePublicTrust: async (ctx) => {
				const publicTrust = getStatIncrease(
					ctx.currentAnswer,
					ctx.currentAnswer?.publicTrustMinimum,
					ctx.currentAnswer?.publicTrustMaximum
				);

				updateStats({
					publicTrust,
				});
			},
			updateSentiment: async (ctx) => {
				updateStats({
					sentiment: ctx.currentAnswer?.sentiment,
					socialHashtags: ctx.currentAnswer?.socialHashtags,
				});
			},
		},
	});

	// Add the machine to the window, so we can send events in Cypress
	if (isE2ETest) {
		// @ts-expect-error
		window.gameService = gameService;
	}

	const onStartGame = () => {
		gameService.send({
			type: 'START',
		});
	};

	const onAnswerChosen = (answer: Answer) => {
		gameService.send({
			type: 'ANSWER_QUESTION',
			answer,
		});
	};

	const onHearScenario = () => {
		gameService.send({
			type: 'HEAR_SCENARIO',
		});
	};

	const onHeardScenario = () => {
		gameService.send({
			type: 'HEARD_SCENARIO',
		});
	};

	const onHearQuestion = () => {
		gameService.send({
			type: 'HEAR_QUESTION',
		});
	};

	const onHearDebrief = () => {
		gameService.send({
			type: 'HEAR_DEBRIEF',
		});
	};

	const onPlayAgain = () => {
		gameService.send({
			type: 'RESTART',
		});
	};

	const onHearAdvice = () => {
		gameService.send({
			type: 'HEARD_ADVICE',
		});
	};

	const value: GameContextType = {
		gameService,
		onAnswerChosen,
		onHearAdvice,
		onHearDebrief,
		onHeardScenario,
		onHearQuestion,
		onHearScenario,
		onPlayAgain,
		onStartGame,
	};

	return (
		<GameContext.Provider value={value}>{props.children}</GameContext.Provider>
	);
};

const GET_QUESTIONS = gql`
	query getQuestions($locale: String!) {
		allQuestions: questionCollection(locale: $locale) {
			items {
				title
				order
				senario
				senarioDate
				answers: answersCollection(limit: 50) {
					items {
						title
						answer
						sentiment
						socialHashtags
						economyMinimum
						economyMaximum
						healthMinimum
						healthMaximum
						wellbeingMinimum
						wellbeingMaximum
						publicTrustMinimum
						publicTrustMaximum
					}
				}
				advice: expertAdviceCollection(limit: 50) {
					items {
						title
						type
						expertsName
						expertsEmail
						expertsDescription
						emailSubject
						link
						copy
					}
				}
			}
		}
	}
`;

/**
 * Utility for generating a random value within an answer's range for a given stat
 * @param answer currentAnswer in machine context
 * @param min The minimum value of the range for the given stat
 * @param max The maximum value of the range for the given stat
 * @returns number
 */
const getStatIncrease = (
	answer: Answer | undefined,
	min: number | undefined,
	max: number | undefined
) => {
	if (!answer) {
		throw new Error('No current answer');
	}

	return random(min ?? 0, max ?? 0);
};
