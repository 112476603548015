// @ts-nocheck

import * as React from 'react';
// import { useReduceMotion } from 'react-reduce-motion';
import { Globals } from 'react-spring';

/** Disables react spring animations if user prefers reduced motion.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
const usePreferReduceMotion = () => {
	const prefersReducedMotion = useReduceMotion();
	React.useEffect(() => {
		Globals.assign({
			skipAnimation: prefersReducedMotion,
		});
	}, [prefersReducedMotion]);
	// return the preference in case we need it
	return prefersReducedMotion;
};

// override until safari bug is fixed

export function useReduceMotion() {
	const [matches, setMatch] = React.useState(
		window.matchMedia('(prefers-reduced-motion: reduce)').matches
	);
	React.useEffect(() => {
		const mq = window.matchMedia('(prefers-reduced-motion: reduce)');
		const handleChange = () => {
			setMatch(mq.matches);
		};
		handleChange();
		// If only has depreciated api use that, otherwise use current api
		// Safari 14 has both, Safari 13 < only has depreciated
		// fixes https://github.com/infiniteluke/react-reduce-motion/issues/8
		if (mq.addListener && !mq.addEventListener) {
			mq.addListener(handleChange);
		} else {
			mq.addEventListener('change', handleChange);
		}
		return () => {
			if (mq.removeListener && !mq.removeEventListener) {
				mq.removeListener(handleChange);
			} else {
				mq.removeEventListener('change', handleChange);
			}
		};
	}, []);
	return matches;
}

export default usePreferReduceMotion;
