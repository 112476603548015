import * as Sentry from '@sentry/react';

type LogErrorParams = {
	noNotify?: boolean;
	msg?: string;
	noLog?: boolean;
	extra?: { [key: string]: string };
	userEmail?: string;
};

const logError = (error: Error, params?: LogErrorParams) => {
	if (!params?.noLog) {
		Sentry.configureScope((scope) => {
			for (const key in params?.extra) {
				scope.setExtra(key, params?.extra[key]);
			}

			if (params?.userEmail) {
				scope.setUser({ email: params?.userEmail });
			}

			scope.setLevel(Sentry.Severity.Error);
			Sentry.captureException(error);
		});
	}

	if (!params?.noNotify) {
		// TODO: toast?
	}
};

export default logError;
